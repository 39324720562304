import { useCallback, useMemo, useState } from "react";
import { OptionsType } from "react-select";
import Input from "../Input";
import SingleSelect, { IOption } from "../Select/SingleSelect";
import Logo from '../../assets/image/logo-blue.svg';
import PartyIcon from '../../assets/image/party.svg';
import ThumbsUpIcon from '../../assets/image/thumbs-up.svg';
import TextArea from "../TextArea";

import './Demo.scss';
import { createDemoUser, createFreeSession, extendUserSession, selectCreatingFreeSession, selectCreatingUser, selectExtendingSession } from "../../slice/demo";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import Spinner from "../Spinner";
import { demoProvider } from "../..";

const options: IOption[] = [
  { label: 'Snowflake usage analytics', value: 'Snowflake usage analytics' },
  { label: 'Other', value: 'Other' },
];

interface IRgisterOrLoginProps {
  disableExtension?: boolean;
  onRegisterOrLogin: () => void;
  onExtend?: () => void;
}

const RegisterOrLoginForm = ({ disableExtension = false, onRegisterOrLogin, onExtend }: IRgisterOrLoginProps) => {
  const dispatch = useAppDispatch();

  const extending = useAppSelector(selectExtendingSession);
  const addingUser = useAppSelector(selectCreatingUser);
  const creatingFreeSession = useAppSelector(selectCreatingFreeSession);
  // const loadingDemo = creatingFreeSession || extending || addingUser;

  const [isExistingUser, setIsExistingUser] = useState(demoProvider.isDayOneRegisteredSessionInitialized());
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [company, setCompany] = useState('');

  const [desc, setDesc] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [businessNeed, setBusinessNeed] = useState(options[0].value);
  const onBusinessNeedChange = useCallback((option: IOption | OptionsType<IOption> | null) => {
    setBusinessNeed((option as IOption).value);
  }, []);
  const businessNeedvalue = useMemo(() => options.find((option: IOption) => (option.value === businessNeed)), [businessNeed])

  const [isRegistered, /* setIsRegistered */] = useState(false);

  const validateInput = () => {
    let nameValid = false;
    let emailValid = false;
    if (!name && !isExistingUser) {
      setNameError('Please enter your name');
    } else {
      setNameError('');
      nameValid = true;
    }

    if (!email) {
      setEmailError('Please enter your email address');
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
      emailValid = true;
    }
    return nameValid && emailValid;
  }

  const onExtendHandler = () => {
    dispatch(createFreeSession())
      .then(unwrapResult)
      .then(() => {
        onExtend && onExtend();
      });
  }

  const onSubmit = () => {
    if (validateInput()) {
      if (isExistingUser) {
        dispatch(extendUserSession(email))
          .then(unwrapResult)
          .then(() => {
            onRegisterOrLogin();
          })
      } else {
        dispatch(createDemoUser({
          email,
          companyName: company,
          userBusinessNeed: businessNeed,
          descriptionAboutNeed: desc,
          userName: name
        }))
          .then(unwrapResult)
          .then(() => {
            // setIsRegistered(true); disabling the success screen showing, uncomment this to enable
            onRegisterOrLogin();
          });
      }
    }
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  }

  return (
    <div className="text-center bg-white fs-dot875 lh-1dot25 day1RegisterModal">
      <div className="mb-4"><img src={Logo} alt="logo" /></div>
      { isRegistered ?
        <>
          <div className="text-success fs-1 font-weight-bold mb-2dot25">
            You’ve successfully registered and our team will <br /> reach to you soon.
          </div>
          <div className="d-flex justify-content-center mb-4">
            <div className="successImageWrapper">
              <img src={PartyIcon} className="partyIcon" alt="success" />
              <img src={ThumbsUpIcon} className="thumbsUpIcon" alt="" />
            </div>
          </div>
          <div className="pt-1dot25 mb-3">You’ve got 30 mins more to explore the product for today.</div>
          <div className="footerWrapper mb-3 d-flex align-items-center justify-content-center">
            <div className="mr-2 loadingWrapper">
              {creatingFreeSession ?
                <Spinner width={10} height={10} />
                : null}
            </div>
            <button
              onClick={onRegisterOrLogin}
              className={`submitBtn rounded-white-border bg-primary \
                text-white px-1dot375 py-2 \
                ${creatingFreeSession ? 'disabled' : ''}`}
            >
              Continue Exploring
          </button>
          </div>
        </> :
        <>
          <div className="mb-2">
            It seems like you’ve run out of free time to explore the product.
          </div>
          <div className="mb-3 font-weight-bold">
            Registered user can explore the product for 30 mins every day.
          </div>
          <div>
            <div className="toggleOptions mb-4">
              <div
                className={`toggleOption ${isExistingUser ? 'active font-weight-bold' : 'cursor-pointer'}`}
                onClick={() => !isExistingUser && setIsExistingUser(true)}
              >Registered User</div>
              <div
                className={`toggleOption ${isExistingUser ? 'cursor-pointer' : 'active font-weight-bold'}`}
                onClick={() => isExistingUser && setIsExistingUser(false)}
              >Register Now</div>
            </div>
          </div>
          <div className="row mb-4">
            {isExistingUser ?
              <div className="col-12 pl-0 pr-3">
                <Input
                  value={email}
                  type="text"
                  label="Your email address*"
                  error={emailError}
                  onBlur={validateInput}
                  onKeyUp={onKeyUp}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
              </div>
              : <>
                <div className="col-6 pl-0 pr-3">
                  <Input
                    value={name}
                    type="text"
                    label="Your name*"
                    error={nameError}
                    onBlur={validateInput}
                    onKeyUp={onKeyUp}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                  />
                </div>
                <div className="col-6 px-0">
                  <Input
                    value={company}
                    type="text"
                    label="Your company name*"
                    // error={nameError}
                    onBlur={validateInput}
                    // onKeyUp={onKeyUp}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompany(e.target.value)}
                  />
                </div>
                <div className="col-6 pl-0 pr-3">
                  <Input
                    value={email}
                    type="text"
                    label="Your email address*"
                    error={emailError}
                    onBlur={validateInput}
                    onKeyUp={onKeyUp}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-6 px-0">
                  <div className="inputLabel text-left mb-1 text-white lh-1dot125">Whats your business need?*</div>
                  <SingleSelect
                    value={businessNeedvalue}
                    options={options}
                    onChange={onBusinessNeedChange}
                  />
                </div>
                <div className="col-12 px-0">
                  <TextArea
                    value={desc}
                    type="text"
                    label="Describe the need in detail"
                    // onBlur={validateInput}
                    onKeyUp={onKeyUp}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDesc(e.target.value)}
                  />
                </div>
              </>}
          </div>
          <div className="footerWrapper mb-3 d-flex align-items-center justify-content-center">
            <div className="mr-2 loadingWrapper">
              {addingUser || extending || creatingFreeSession ?
                <Spinner width={10} height={10} />
                : null}
            </div>
            <button
              onClick={onSubmit}
              className={`submitBtn rounded-white-border bg-primary \
                text-white px-1dot375 py-2 \
                ${addingUser || extending ? 'disabled' : ''}`}
            >
              Submit
            </button>
          </div>
          { !disableExtension &&
            <div className={`link-custom text-decor-underline fs-dot75 ${extending ? 'no-pointer-events' : ''}`} onClick={onExtendHandler}>
              I want to show this awesomeness to others. Extend for 15 more mins now
            </div>}
        </>}
    </div>
  );
}

export default RegisterOrLoginForm;
