import React, { ChangeEventHandler } from 'react';

import './Checkbox.scss';

interface ICheckboxProps {
  id?:string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox = ({ checked, id, onChange }: ICheckboxProps) => {
  return (
    <div className="checkboxContainer">
      <input id={id} type="checkbox" checked={checked} onChange={onChange} />
      <div className="checkmark"></div>
    </div>
  );
}

export default Checkbox;
