import React, { useCallback, useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink, useLocation } from 'react-router-dom';

import { PROTECTED_ROUTES } from '../../constants/route';
import { resetCostMetrics } from '../../slice/costMetrics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { resetPerformanceMetrics } from '../../slice/performanceMetrics';
import { resetQueryMetrics } from '../../slice/queryMetrics';
import { logout, resetUser, switchAccount, reqAddAccount, changePassword } from '../../slice/user';
import logo from '../../assets/image/logo.svg';
import avatar from '../../assets/image/avatar.png';
import cross from '../../assets/image/cross.svg';

import './AppNav.scss';
import { resetWarehouseDrilldown } from '../../slice/drilldown/warehouse';
import { resetWarehouseSizeDrilldown } from '../../slice/drilldown/warehouseSize';
import { resetUserDrilldown } from '../../slice/drilldown/user';
import { resetDateDrilldown } from '../../slice/drilldown/date';
import { resetApp, selectTimezone, selectDashboardMeta } from '../../slice/app';
import LogoutIcon from '../../assets/image/logout.png';
import TimezoneIcon from '../../assets/image/globe.svg';
import ClockIcon from '../../assets/image/clock-white.svg';
import { selectTimeRemaining } from '../../slice/demo';
import { addZerosBefore } from '../../utils/appUtils';
import Input from "../Input";

const AppNav = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Demo logic
  const isDemoURL = location.pathname.startsWith('/demo');
  const timeRemaining = useAppSelector(selectTimeRemaining);

  const { value: timezone, loading: timezoneLoading } = useAppSelector(selectTimezone);
  const { snowflakeAccounts, loading: snowflakeAccountsLoading, 
    activeSnowflakeAccount 
  } = useAppSelector(selectDashboardMeta);
  const [ settingsVisibilty, setSettingsVisibility] = useState(false);
  const [ switchAccVisibilty, setSwitchAccVisibility] = useState(false);
  const [ showPopupmodel, setShowPopupmodel] = useState(false);
  const [ showPopupmodelAddAcc, setShowPopupmodelAddAcc] = useState(false);
  const [ showPopupmodelChangePassword, setShowPopupmodelChangePassword] = useState(false);
  const [ accessLinkError, setAccessLinkError ] = useState('');
  const [ accessLink, setAccessLink ] = useState('');
  const [ currentPassword, setCurrentPassword] = useState('');
  const [ currentPasswordError, setCurrentPasswordError] = useState('');
  const [ newPassword, setNewPassword] = useState('');
  const [ newPasswordError, setNewPasswordError] = useState('');
  const [ reEnterNewPassword, setReEnterNewPassword] = useState('');
  const [ reEnterNewPasswordError, setReEnterNewPasswordError] = useState('');
  const handleWindowClick = useCallback(() => {
    setSettingsVisibility(false);
    setSwitchAccVisibility(false);
  }, [ setSettingsVisibility, setSwitchAccVisibility ]); 
  const handleAvatarClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSettingsVisibility(!settingsVisibilty);
  }, [ settingsVisibilty, setSettingsVisibility ]); 
  const showSwitchAccountDropDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSwitchAccVisibility(!switchAccVisibilty);
  }, [ switchAccVisibilty, setSwitchAccVisibility ]); 
  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, [ handleWindowClick ]);
  const handleLogout = useCallback(() => {
    dispatch(logout());
    dispatch(resetCostMetrics());
    dispatch(resetQueryMetrics());
    dispatch(resetPerformanceMetrics());
    dispatch(resetWarehouseDrilldown());
    dispatch(resetWarehouseSizeDrilldown());
    dispatch(resetUserDrilldown());
    dispatch(resetDateDrilldown());
    dispatch(resetUser());
    dispatch(resetApp());
  }, [ dispatch ]);

  const resetPopupState = () => {
    setShowPopupmodel(false);
    setShowPopupmodelAddAcc(false);
    setShowPopupmodelChangePassword(false);
    setAccessLinkError('');
    setAccessLink('');
    setCurrentPassword('');
    setCurrentPasswordError('');
    setNewPassword('');
    setNewPasswordError('');
    setReEnterNewPassword('');
    setReEnterNewPasswordError('');
  }

  const ShowPopup = (title:string, footerButtonName:string) => {
    return (
      <div id="modal-background" className="modalBackground px-5 d-flex align-items-center overflow-auto justify-content-center"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => (e.target as any)?.id === 'modal-background' && resetPopupState()}>
        <div className="col-5 addAccountPopupContainer">
          <div className="addAccountPopup bg-white fs-dot875 pb-2 d-block">
            <div className="addAccountPopupHeader  d-flex align-items-center py-3 px-dot625 justify-content-between">
              <span className="no-wrap fs-dot975 font-weight-semibold">{title}</span>
              <img className="cursor-pointer" alt="" src={cross} onClick={()=> {resetPopupState()}}/>
            </div>
            {showPopupmodelAddAcc &&
              <div className="inputComponent p-3">
                <input placeholder="Type or paste the link" type="text" className="inputField py-2 px-dot75 fs-dot875 lh-1dot125"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccessLink(e.target.value)}
                onBlur={validateAccessLink}
                />
                { accessLinkError && <small className="text-danger fs-dot875">{accessLinkError}</small> }
              </div>
            }
            {showPopupmodelChangePassword &&
              <div className="row mb-4">
                <div className="col-12 inputComponent py-3 px-5">
                  <Input
                    value={currentPassword}
                    type="password"
                    label="Current Password"
                    error={currentPasswordError}
                    onBlur={validateInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPassword(e.target.value)}
                  />
                </div>
                <div className="col-12 inputComponent py-1 px-5">
                  <Input
                    value={newPassword}
                    type="password"
                    label="New Password"
                    error={newPasswordError}
                    onBlur={validateInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className="col-12 inputComponent py-3 px-5">
                  <Input
                    value={reEnterNewPassword}
                    type="password"
                    label="Re-enter New Password"
                    error={reEnterNewPasswordError}
                    onBlur={validateInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReEnterNewPassword(e.target.value)}
                  />
                </div>

              </div>
              
            }
            <div className="p-3 footer">
              <span onClick={()=> {resetPopupState()}} 
              className="cursor-pointer fs-dot975 font-weight-semibold p-3" style={{color:'#00249C'}}>Cancel</span>
              <button className="rounded-white-border bg-primary text-white px-1dot375 py-2" onClick={
                showPopupmodelChangePassword ? handleChangePassword : reqAddAccountURL}>{footerButtonName}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const validateInput = () => {
    if (!currentPassword) {
      setCurrentPasswordError('Please enter your password');
    } else {
      setCurrentPasswordError('');
    }
    if (!newPassword) {
      setNewPasswordError('Please enter new password');
    } else if (!new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,50}$').test(newPassword)) {
      setNewPasswordError('Password does not match pattern');
    } else if (newPassword === currentPassword) {
      setNewPasswordError('New password cannot be same as old');
    } else {
      setNewPasswordError('');
    }
    if (!reEnterNewPassword) {
      setReEnterNewPasswordError('Please re-enter your password');
    } else if (reEnterNewPassword !== newPassword) {
      setReEnterNewPasswordError('Passwords did not match');
    } else {
      setReEnterNewPasswordError('');
    }
    if (!newPasswordError && !reEnterNewPasswordError && !currentPasswordError) {
      return true;
    } else {
      return false;
    }
  }

  const reqAddAccountURL = () => {
    if (validateAccessLink()) {
      dispatch(reqAddAccount(accessLink));
      resetPopupState();
    }
  }

  const handleChangePassword = () => {
    if (validateInput()) {
      dispatch(changePassword({currentPassword,newPassword}));
      resetPopupState();
    }
  }

  const validateAccessLink = () => {
    if (!accessLink) {
      setAccessLinkError('Please enter your access link');
    } else if (!accessLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g)) {
      setAccessLinkError('Please enter a valid access link');
    } else {
      setAccessLinkError('');
      return true;
    }
  }

  const getSwitchAccountData = useCallback(async (event:any) => {
    event.preventDefault();
    event.stopPropagation();
    const res = await dispatch(switchAccount(event.target.firstChild.data || event.target.firstChild.innerText));
    if (res.payload && res.payload.message) {
      window.location.reload();
    }
  },[dispatch]);
  return (
    <Navbar className="navCustom" variant="dark" expand='lg'>
      <Navbar.Brand>
        <div className="navBrand">
          <img className="logo" alt="" src={logo}></img>
        </div>
      </Navbar.Brand>
      {!isDemoURL && !snowflakeAccountsLoading &&  snowflakeAccounts &&
        <div className="position-relative overflow-visible">
          <div className="d-flex align-items-center p-2 cursor-pointer addUrlComponent" onClick={showSwitchAccountDropDown}>
            <div className="pr-2">
              <i className={`${switchAccVisibilty ? 'fa fa-caret-up' :  'fa fa-caret-down'}`} />
            </div>
            <div className="sfAccountSelectedText"> 
              {activeSnowflakeAccount}
            </div>
          </div>
          <div className={`sfAccountDropdown position-absolute bg-white fs-dot875 pb-2 ${switchAccVisibilty ? 'd-block' : 'd-none'}`}>
            <div className="sfAccountHeader  d-flex align-items-center py-2 px-dot625 justify-content-between">
              <span className="no-wrap no-pointer-event sfAccountHeaderText">Snowflake Links</span>
              <div className="sfAccountHeaderAddLink cursor-pointer" onClick={(() => {setShowPopupmodel(true);setShowPopupmodelAddAcc(true)})}>
                <i className="fa fa-plus pr-2" /> 
                <span>Add Link</span>
              </div>
            </div>
            {snowflakeAccounts.map((snowflakeAccount: any, i: number) => {
              let isActiveAccount = false;
              if(snowflakeAccount === activeSnowflakeAccount) {
                isActiveAccount = !isActiveAccount;
              }
              return <div key={`${i}`} onClick={(e)=>{getSwitchAccountData(e)}} 
                className={`sfAccountOption ${isActiveAccount ? 'no-pointer-event' : 'cursor-pointer'}  d-flex align-items-center py-2 px-dot625 justify-content-between`}>
                <span style={{width:'13rem'}} className={`overflow-hidden ${isActiveAccount && 'text-success'}`}>{snowflakeAccount}</span>
                {isActiveAccount &&
                  <i className="fa fa-check text-success"/>
                }
              </div>
            })}
          </div>
        </div>
      }
      {!isDemoURL  && showPopupmodel && showPopupmodelAddAcc &&
        ShowPopup('Add snowflake link','Send Request')
      }
      {
        !isDemoURL  && showPopupmodel && showPopupmodelChangePassword && ShowPopup('Change Password', 'Reset Password')
      }
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto ml-auto navLinks">
          <NavLink className="navLink" to={PROTECTED_ROUTES.overview}>
            Overview <div className="dot" /></NavLink>
          <NavLink className="navLink" to={PROTECTED_ROUTES.performance}>
            Performance <div className="dot" />
          </NavLink>
          <NavLink className="navLink" to={PROTECTED_ROUTES.drilldown}>
            Drilldown <div className="dot" />
          </NavLink>
        </Nav>
        {!isDemoURL && !snowflakeAccountsLoading &&  
          <div className="invisible" >
            <span >dummy space-----------</span>
          </div>
        }

        {isDemoURL ?
          <div className="d-flex align-items-center">
            <img src={ClockIcon} alt="Time left" />
            <span className="font-weight-bold text-white ml-dot625">{
              addZerosBefore(Math.floor(timeRemaining / 60), 2)} : {
              addZerosBefore(timeRemaining % 60, 2)} left
            </span>
          </div> :
          <div className="position-relative overflow-visible">
            <div className="avatar cursor-pointer d-flex align-items-center justify-content-center" onClick={handleAvatarClick}>
              <img src={avatar} alt=""/>
            </div>
            <div className={`settingsDropdown position-absolute bg-white fs-dot875 pb-2 ${settingsVisibilty ? 'd-block' : 'd-none'}`}>
              { !timezoneLoading && timezone ? <div className="settingsOption no-pointer-event d-flex align-items-center py-2 px-dot625">
                <img src={TimezoneIcon} alt="" className="mr-2" />
                <span className="no-wrap">{timezone}</span>
              </div> : null}
              <div className="settingsOption text-nowrap py-2 px-dot625 cursor-pointer" onClick={(()=> {
                setShowPopupmodelChangePassword(true);
                setShowPopupmodel(true);
              })}>
              <i className="fa fa-key mr-2" aria-hidden="false"/>
                Change Password
              </div>
              <div className="settingsOption py-2 px-dot625 cursor-pointer" onClick={handleLogout}>
                <img src={LogoutIcon} alt="" className="mr-2" />
                Logout
              </div>
            </div>
          </div>
        }
      </Navbar.Collapse>
    </Navbar>
  );
}

export default AppNav;
