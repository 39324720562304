import axios from "axios";
import { ILoginData, ISignupData, IChangePasswordData } from "custom-types";

export const login = (data: ILoginData) => axios({
  method: 'post',
  data,
  url: '/login'
});

export const logout = () => axios({
  method: 'post',
  url: '/logout'
});

export const signup = (data: ISignupData) => axios({
  method: 'post',
  data,
  url: '/users/userCreationRequest'
});

export const requestNewPassword = (email: string) => axios({
  method: 'post',
  data: { email },
  url: '/users/forgot-password'
});

export const switchAccount = (accountName: string) => axios({
  method: 'post',
  data: { accountName },
  url: '/users/change-snowflake-account'
});

export const reqAddAccount = (snowflakeAccessUrl: string) => axios({
  method: 'post',
  data: { snowflakeAccessUrl },
  url: '/users/add-snowflake-url-req'
});

export const changePassword = (data: IChangePasswordData) => axios({
  method: 'post',
  data,
  url: '/users/change-password'
});
