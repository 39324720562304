import './NoDataTemplate.scss';

interface INoDateTemplateProps {
  icon?: string;
  text?: string;
}

const NoDataTemplate = ({ icon, text }: INoDateTemplateProps) => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column w-100 h-100">
      {icon ? <img className="mb-2" width={37} height={31} src={icon} alt="no-data" /> : null}
      <div className="fs-875 noDataText">{text || 'Data not available'}</div>
    </div>
  );
}

export default NoDataTemplate;
