import axios from 'axios';
import { ICommonQuery } from 'custom-types';
import { constructCommonQuery } from '../../utils/appUtils';

export const fetchQuerySizeVsTime = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/querySizeVsExecutionTime?${constructCommonQuery(queryObj)}`
});

export const fetchQueryTimeTrend = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/queryTimeTrend?${constructCommonQuery(queryObj)}`
});

export const fetchQueryTimeVsWarehouseSize = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/queryTimeByWarehouseSize?${constructCommonQuery(queryObj)}`
});

export const fetchTopQueriesByCostAndTime = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/topQueriesByCostAndExecutionTime?${constructCommonQuery(queryObj)}`
});

export const fetchComparitiveQueryInsights = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/performance-query-report?${constructCommonQuery(queryObj)}`
});
