import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonQuery } from 'custom-types';
import moment from 'moment';

import { RootState } from '../../store/store';
import { fetchDashboardMeta } from '../app';
import * as performanceMetricsAPI from './performanceMetricsAPI';

export type IQueryTrendByDate = Array<{
  DATE: string;
  HOURS: number;
  QUERY_COUNT: number;
}>;

export interface IQueryTrendByWeekday {
  weekDay: string[];
  queryCount: number[];
}

interface IQueryCountByShiftHour {
  morningShift: number;
  genShift: number;
  nightShift: number;
  date: string;
}

type IQueryCountByShiftHours = IQueryCountByShiftHour[];

export interface IQueryCountByUsers {
  name: string[];
  numberOfQueries: number[];
  percent: number[];
  totalQueries: number;
}

export interface IQueryCountByWarehouseName {
  name: string[];
  numberOfQueries: number[];
  cost: number[];
}

export interface IQueryCountByWarehouseSize {
  size: string[];
  numberOfQueries: number[];
  cost: number[]; 
}

type IQueryCountVsUsers = Array<{
  DATE: string;
  HOURS: number;
  USER_COUNT: number;
  QUERY_COUNT: number;
}>;

export interface IQueryBiggerInfo {
  totalQueries: number;
  percent: number;
  queryCount: number;
}

export type IQueriesBiggerThanNextWarehouse = Array<{
  warehouseSize: string;
  execTime: IQueryBiggerInfo;
  byteScanned: IQueryBiggerInfo;
  execTimeAndByteScanned: IQueryBiggerInfo;
}>;

export interface IQuerySizeVsExecutionTime {
  querySize: number[];
  executionTime: number[];
}

export interface IAverageExecutionTimeByWarehouseName {
  name: string[];
  avgExecutionTime: number[];
}

export interface IAverageExecutionTimeByWarehouseSize {
  size: string[];
  avgExecutionTime: number[];
}

export interface IQueryCount {
  cardsValue: {
    queryCount: number;
    comparedPercent:number;
  };
  queryTrendData: {
    date: string[];
    queryCount: number[];
  };
}

export interface IMaxExecTime {
  cardsValue: {
    maximumExecutionTime: number;
    comparedPercent: number;
  };
  queryTrendData: {
    date: string[];
    maxExeTime: number[];
  };
}

export interface IMedianExecTime {
  cardsValue: {
    medianExecutionTime: number;
    comparedPercent: number;
  };
  queryTrendData: {
    date: string[];
    medianExeTime: number[];
  };
}

interface IQueries {
  USER_NAME: string;
  QUERY_ID: string;
  QUERY: string;
  WAREHOUSE_ID: string;
  NO_OF_RECORDS: number;
  EXECUTION_TIME_SECONDS: number;
}

export type QueryTrendTogglerType = 'Date' | 'Weekday';
export type QueryCountByWarehouseTogglerType = 'Name' | 'Size';
export type averageExecutionTimeByWarehouseTogglerType = 'Name' | 'Size';

export interface IPerformanceMetrics {
  maxExecTime: {
    value?: IMaxExecTime;
    loading: boolean;
    initialFetchDone: boolean;
  };
  medianExecTime: {
    value?: IMedianExecTime;
    loading: boolean;
    initialFetchDone: boolean;
  };
  queryCount: {
    value?: IQueryCount;
    loading: boolean;
    initialFetchDone: boolean;
  };
  queryTrendByDate: {
    value?: IQueryTrendByDate;
    valueByHours?: IQueryTrendByDate;
    loading: boolean;
    initialFetchDone: boolean;
    hoursInitialFetchDone: boolean;
  };
  queryTrendByWeekday: {
    value?: IQueryTrendByWeekday;
    loading: boolean;
    initialFetchDone: boolean;
  };
  queryCountByShiftHours: {
    value?: IQueryCountByShiftHours;
    valueByHours?: IQueryCountByShiftHours;
    loading: boolean;
    initialFetchDone: boolean;
    hoursInitialFetchDone: boolean;
  };
  queryCountByUsers: {
    value?: IQueryCountByUsers;
    loading: boolean;
    initialFetchDone: boolean;
  };
  queryCountByWarehouseName: {
   value?: IQueryCountByWarehouseName;
   loading: boolean;
   initialFetchDone: boolean;
  };
  queryCountByWarehouseSize: {
    value?: IQueryCountByWarehouseSize;
    loading: boolean;
    initialFetchDone: boolean;
  };
  queryCountVsUsers: {
    value?: IQueryCountVsUsers;
    valueByHours?: IQueryCountVsUsers;
    loading: boolean;
    initialFetchDone: boolean;
    hoursInitialFetchDone: boolean;
  };
  queriesBiggerThanNextWarehouse: {
    value?: IQueriesBiggerThanNextWarehouse;
    loading: boolean;
    initialFetchDone: boolean;
  };
  querySizeVsExecutionTime: {
    value?: IQuerySizeVsExecutionTime;
    loading: boolean;
    initialFetchDone: boolean;
  };
  averageExecutionTimeByWarehouseName: {
    value?: IAverageExecutionTimeByWarehouseName;
    loading: boolean;
    initialFetchDone: boolean;
  };
  averageExecutionTimeByWarehouseSize: {
    value?: IAverageExecutionTimeByWarehouseSize;
    loading: boolean;
    initialFetchDone: boolean;
  };
  users: {
    value?: string[];
    loading: boolean;
    initialFetchDone: boolean;
  };
  warehouses: {
    value?: string[];
    loading: boolean;
    initialFetchDone: boolean;
  };
  biggerQueriesForExeTime: {
    value?: IQueries[];
    loading: boolean;
    initialFetchDone: boolean;
  },
  biggerQueriesForByteScanned: {
    value?: IQueries[];
    loading: boolean;
    initialFetchDone: boolean;
  },
  biggerQueriesForExeTimeAndByteScanned: {
    value?: IQueries[];
    loading: boolean;
    initialFetchDone: boolean;
  },
  startDate: number;
  endDate: number;
  queryTrendToggler: QueryTrendTogglerType;
  queryCountByWarehouseToggler: QueryCountByWarehouseTogglerType;
  averageExecutionTimeByWarehouseToggler: averageExecutionTimeByWarehouseTogglerType;
  selectedUsers: string[];
  selectedWarehouses: string[];
  isInsightsDismissed: boolean;
  isInsightsCollapsed: boolean;
  selectedTab: string;
}

export const dateTogglerName = 'Date';
export const weekdayTogglerName = 'Weekday';
export const queryTrendTogglerList = [ dateTogglerName, weekdayTogglerName ];
export const nameTogglerName = 'Name';
export const sizeTogglerName = 'Size';
export const queryCountByWarehouseTogglerList = [ nameTogglerName, sizeTogglerName ];
export const averageExecutionTimeByWarehouseToggler = [ nameTogglerName, sizeTogglerName ];
export interface IAverageExecutionTimeByWarehouseSize {
  size: string[];
  avgExecutionTime: number[];
}

const getInitialReportsData = () => ({
  maxExecTime: {
    loading: false,
    initialFetchDone: false
  },
  medianExecTime: {
    loading: false,
    initialFetchDone: false
  },
  queryCount: {
    loading: false,
    initialFetchDone: false
  },
  queryTrendByDate: {
    loading: false,
    initialFetchDone: false,
    hoursInitialFetchDone: false
  },
  queryTrendByWeekday: {
    loading: false,
    initialFetchDone: false
  },
  queryCountByShiftHours: {
    loading: false,
    initialFetchDone: false,
    hoursInitialFetchDone: false
  },
  queryCountByUsers: {
    loading: false,
    initialFetchDone: false
  },
  queryCountByWarehouseName: {
    loading: false,
    initialFetchDone: false
   },
  queryCountByWarehouseSize: {
    loading: false,
    initialFetchDone: false
  },
  queryCountVsUsers: {
    loading: false,
    initialFetchDone: false,
    hoursInitialFetchDone: false
  },
  queriesBiggerThanNextWarehouse: {
    loading: false,
    initialFetchDone: false
  },
  querySizeVsExecutionTime: {
    loading: false,
    initialFetchDone: false
  },
  averageExecutionTimeByWarehouseName: {
    loading: false,
    initialFetchDone: false
  },
  averageExecutionTimeByWarehouseSize: {
    loading: false,
    initialFetchDone: false
  },
  users: {
    loading: false,
    initialFetchDone: false
  },
  warehouses: {
    loading: false,
    initialFetchDone: false
  },
  biggerQueriesForExeTime: {
    loading: false,
    initialFetchDone: false
  },
  biggerQueriesForByteScanned: {
    loading: false,
    initialFetchDone: false
  },
  biggerQueriesForExeTimeAndByteScanned: {
    loading: false,
    initialFetchDone: false
  },
});

export const tabs = {
  queryTrend: 'Query trend',
  queryExplorer: 'Query explorer'
};

const initialState: IPerformanceMetrics = {
  ...getInitialReportsData(),
  startDate: moment().subtract(6, 'days').startOf('day').valueOf(),
  endDate: moment().endOf('day').valueOf(),
  queryTrendToggler: dateTogglerName,
  queryCountByWarehouseToggler: nameTogglerName,
  averageExecutionTimeByWarehouseToggler: nameTogglerName,
  selectedUsers: [],
  selectedWarehouses: [],
  isInsightsDismissed: false,
  isInsightsCollapsed: false,
  selectedTab: tabs.queryTrend
};

export const fetchComparitiveQueryInsights = createAsyncThunk(
  'costMetrics/fetchComparitiveQueryInsights',
  async (queryObj: ICommonQuery) => {
    const { data: { cardsValue: [ cardsValue ], queryTrendData }} = await performanceMetricsAPI.fetchComparitiveQueryInsights(queryObj);
    const maxExecTime: IMaxExecTime = {
      cardsValue: {
        maximumExecutionTime: cardsValue?.CURR_MAX_EXECUTION_TIME_SECS || 0,
        comparedPercent: cardsValue?.MAX_EXECUTION_TIME_PERC_INCREASE || 0
      },
      queryTrendData: {
        date: [],
        maxExeTime: []
      }
    };
    const medianExecTime: IMedianExecTime = {
      cardsValue: {
        medianExecutionTime: cardsValue?.CURR_MEDIAN_EXECUTION_TIME_SECS || 0,
        comparedPercent: cardsValue?.MEDIAN_EXECUTION_TIME_PERC_INCREASE || 0
      },
      queryTrendData: {
        date: [],
        medianExeTime: []
      }
    };
    const queryCount: IQueryCount = {
      cardsValue: {
        queryCount: cardsValue?.CURRENT_TOT_QUERIES || 0,
        comparedPercent: cardsValue?.TOT_QUERIES_PERC_INCREASE || 0
      },
      queryTrendData: {
        date: [],
        queryCount: []
      }
    };
    (queryTrendData || []).forEach(({ DATE, MAX_ELAPSED_TIME_SECS, MEDIAN_ELAPSED_TIME_SECS, QUERY_CNT }: any) => {
      maxExecTime.queryTrendData.date.push(DATE);
      maxExecTime.queryTrendData.maxExeTime.push(MAX_ELAPSED_TIME_SECS);
      medianExecTime.queryTrendData.date.push(DATE);
      medianExecTime.queryTrendData.medianExeTime.push(MEDIAN_ELAPSED_TIME_SECS);
      queryCount.queryTrendData.date.push(DATE);
      queryCount.queryTrendData.queryCount.push(QUERY_CNT);
    });
    return { maxExecTime, medianExecTime, queryCount };
  }
);

export const fetchQueryTrendByDate = createAsyncThunk(
  'performanceMetrics/fetchQueryTrendByDate',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchQueryTrendByDate(queryObj);
    return response.data;
  }
);

export const fetchQueryTrendByWeekday = createAsyncThunk(
  'performanceMetrics/fetchQueryTrendByWeekday',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchQueryTrendByWeekday(queryObj);
    return response.data;
  }
);

export const fetchQueryCountByShiftHours = createAsyncThunk(
  'performance/fetchQueryCountByShiftHours',
  async (queryObj: ICommonQuery) => {
    const { data } = await performanceMetricsAPI.fetchQueryCountByShiftHours(queryObj);
    const temp: { [date: string]: { date: string; hours: number; morningShift: number; genShift: number; nightShift: number; }; } = {};
    type responseDataType = {
      DATE: string;
      HOURS: string;
      SHIFT_NO: number;
      QUERY_COUNT: number;
    };
    data.forEach((shift: responseDataType) => {
      const key = `${shift.DATE}${queryObj.fetchBy === 'HOURS' ? `_${shift.HOURS}` : ''}`;
      debugger;
      temp[key] = temp[key] || { date: shift.DATE, hours: shift.HOURS, morningShift: 0, nightShift: 0, genShift: 0 };
      switch(shift.SHIFT_NO) {
        case 1:
          temp[key].morningShift = shift.QUERY_COUNT;
          break;
        case 2:
          temp[key].genShift = shift.QUERY_COUNT;
          break;
        case 3:
          temp[key].nightShift = shift.QUERY_COUNT;
          break;     
      }
    });
    return Object.values(temp);
  }
);

export const fetchQueryCountByUsers = createAsyncThunk(
  'performance/fetchQueryCountByUsers',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchQueryCountByUsers(queryObj);
    return response.data;
  }
);

export const fetchQueryCountByWarehouseName = createAsyncThunk(
  'performance/fetchQueryCountByWarehouseName',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchQueryCountByWarehouseName(queryObj);
    return response.data;
  }
);

export const fetchQueryCountByWarehouseSize = createAsyncThunk(
  'performance/fetchQueryCountByWarehouseSize',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchQueryCountByWarehouseSize(queryObj);
    return response.data;
  }
);

export const fetchQueryCountVsUsers = createAsyncThunk(
  'performance/fetchQueryCountVsUsers',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchQueryCountVsUsers(queryObj);
    return response.data;
  }
);

export const fetchQueriesBiggerThanNxtWarehouse = createAsyncThunk(
  'performance/fetchQueriesBiggerThanNxtWarehouse',
  async (queryObj: ICommonQuery) => {
    const [ execTime, byteScanned, execTimeAndByteScanned ] = await performanceMetricsAPI.fetchQueriesBiggerThanNxtWarehouse(queryObj);
    const warehouseSizeInOrder: string[] = [ 'X-Small', 'Small', 'Medium', 'Large', 'X-Large' ]; 
    const queriesBiggerThanNextWarehouse: IQueriesBiggerThanNextWarehouse = [];
    for (let size of warehouseSizeInOrder) {
      const execTimeWarehouseIndex = (execTime?.data?.warehouseSize || []).indexOf(size);
      const execTimeAndByteScannedWarehouseIndex = (execTime?.data?.warehouseSize || []).indexOf(size);
      const byteScannedWarehouseIndex = (execTime?.data?.warehouseSize || []).indexOf(size);
      queriesBiggerThanNextWarehouse.push({
        warehouseSize: size,
        execTime: {
          totalQueries :execTime.data.totalQueries[execTimeWarehouseIndex] || 0,
          percent: execTime.data.percent[execTimeWarehouseIndex] || 0,
          queryCount: execTime.data.queryCount[execTimeWarehouseIndex] || 0
        },
        byteScanned: {
          totalQueries: byteScanned.data.totalQueries[byteScannedWarehouseIndex] || 0,
          percent: byteScanned.data.percent[byteScannedWarehouseIndex] || 0,
          queryCount: byteScanned.data.queryCount[byteScannedWarehouseIndex] || 0
        },
        execTimeAndByteScanned: {
          totalQueries: execTimeAndByteScanned.data.totalQueries[execTimeAndByteScannedWarehouseIndex] || 0,
          percent: execTimeAndByteScanned.data.percent[execTimeAndByteScannedWarehouseIndex] || 0,
          queryCount: execTimeAndByteScanned.data.queryCount[execTimeAndByteScannedWarehouseIndex] || 0
        }
      });
    }
    return queriesBiggerThanNextWarehouse;
  }
);


export const fetchQuerySizeVsExecutionTime = createAsyncThunk(
  'performance/fetchQuerySizeVsExecutionTime',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchQuerySizeVsExecutionTime(queryObj);
    return response.data;
  }
);

export const fetchAverageExecutionTimeByWarehouseName = createAsyncThunk(
  'counter/fetchAverageExecutionTimeByWarehouseName',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchAverageExecutionTimeByWarehouseName(queryObj);
    return response.data;
  }
);

export const fetchAverageExecutionTimeByWarehouseSize = createAsyncThunk(
  'counter/fetchAverageExecutionTimeByWarehouseSize',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchAverageExecutionTimeByWarehouseSize(queryObj);
    return response.data;
  }
);

export const fetchAllUsers = createAsyncThunk(
  'performanceMetrics/fetchAllUsers',
  async () => {
    const response = await performanceMetricsAPI.fetchAllUsers();
    return response.data?.name || [];
  }
);

export const fetchAllWarehouses = createAsyncThunk(
  'performanceMetrics/fetchAllWarehouses',
  async () => {
    const response = await performanceMetricsAPI.fetchAllWarehouses();
    return response.data?.name || [];
  }
);

export const fetchBiggerQueriesForExeTime = createAsyncThunk(
  'performanceMetrics/fetchBiggerQueriesForExeTime',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchBiggerQueriesForExeTime(queryObj);
    return response.data || [];
  }
);

export const fetchBiggerQueriesForByteScanned = createAsyncThunk(
  'performanceMetrics/fetchBiggerQueriesForByteScanned',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchBiggerQueriesForByteScanned(queryObj);
    return response.data || [];
  }
);

export const fetchBiggerQueriesForExeTimeAndOnByteScanned = createAsyncThunk(
  'performanceMetrics/fetchBiggerQueriesForExeTimeAndOnByteScanned',
  async (queryObj: ICommonQuery) => {
    const response = await performanceMetricsAPI.fetchBiggerQueriesForExeTimeAndOnByteScanned(queryObj);
    return response.data || [];
  }
);

export const performanceMetricsSlice = createSlice({
  name: 'performanceMetrics',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDateRange: (state, action: PayloadAction<{ startDate: number; endDate: number; }>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setQueryTrendToggler: (state, action: PayloadAction<QueryTrendTogglerType>) => {
      state.queryTrendToggler = action.payload;
    },
    setQueryCountByWarehouseToggler: (state, action: PayloadAction<QueryCountByWarehouseTogglerType>) => {
      state.queryCountByWarehouseToggler = action.payload;
    },
    setaverageExecutionTimeByWarehouseToggler:  (state, action: PayloadAction<averageExecutionTimeByWarehouseTogglerType>) => {
      state.averageExecutionTimeByWarehouseToggler = action.payload;
    },
    setSelectedUsers: (state, action: PayloadAction<string[]>) => {
      state.selectedUsers = action.payload;
    },
    setSelectedWarehouses: (state, action: PayloadAction<string[]>) => {
      state.selectedWarehouses = action.payload;
    },
    dismissInsights: (state) => {
      state.isInsightsDismissed = true;
    },
    selectTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload;
    },
    toggleInsightsCollapsedState: (state, action: PayloadAction<boolean>) => {
      state.isInsightsCollapsed = action.payload;
    },
    clearBiggerQuerieForExeTime: (state) => {
      const { biggerQueriesForExeTime } = getInitialReportsData();
      state.biggerQueriesForExeTime = biggerQueriesForExeTime;
    },
    clearBiggerQuerieForByteScanned: (state) => {
      const { biggerQueriesForByteScanned } = getInitialReportsData();
      state.biggerQueriesForByteScanned = biggerQueriesForByteScanned;
    },
    clearBiggerQuerieForExeTimeAndByteScanned: (state) => {
      const { biggerQueriesForExeTimeAndByteScanned } = getInitialReportsData();
      state.biggerQueriesForExeTimeAndByteScanned = biggerQueriesForExeTimeAndByteScanned;
    },
    clearReportsData: (state, action: PayloadAction<undefined>) => {
    const {
      queryTrendByDate,
      queryTrendByWeekday,
      queryCountByShiftHours,
      queryCountByUsers,
      queryCountByWarehouseName,
      queryCountByWarehouseSize,
      queryCountVsUsers,
      queriesBiggerThanNextWarehouse,
      querySizeVsExecutionTime,
      averageExecutionTimeByWarehouseName,
      averageExecutionTimeByWarehouseSize,
      biggerQueriesForExeTime,
      biggerQueriesForByteScanned,
      biggerQueriesForExeTimeAndByteScanned
    } = getInitialReportsData();
      state.queryTrendByDate = queryTrendByDate;
      state.queryTrendByWeekday = queryTrendByWeekday;
      state.queryCountByShiftHours = queryCountByShiftHours;
      state.queryCountByUsers = queryCountByUsers;
      state.queryCountByWarehouseName = queryCountByWarehouseName;
      state.queryCountByWarehouseSize = queryCountByWarehouseSize;
      state.queryCountVsUsers = queryCountVsUsers;
      state.queriesBiggerThanNextWarehouse = queriesBiggerThanNextWarehouse;
      state.querySizeVsExecutionTime = querySizeVsExecutionTime;
      state.averageExecutionTimeByWarehouseName = averageExecutionTimeByWarehouseName;
      state.averageExecutionTimeByWarehouseSize = averageExecutionTimeByWarehouseSize;
      state.biggerQueriesForExeTime = biggerQueriesForExeTime; 
      state.biggerQueriesForByteScanned = biggerQueriesForByteScanned; 
      state.biggerQueriesForExeTimeAndByteScanned = biggerQueriesForExeTimeAndByteScanned; 
    },
    resetPerformanceMetrics: (state) => state = initialState
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchComparitiveQueryInsights.pending, (state) => {
        state.maxExecTime.loading = true;
        state.maxExecTime.initialFetchDone = true;
        state.medianExecTime.loading = true;
        state.medianExecTime.initialFetchDone = true;
        state.queryCount.loading = true;
        state.queryCount.initialFetchDone = true;
      })
      .addCase(fetchComparitiveQueryInsights.fulfilled, (state, action) => {
        state.maxExecTime.loading = false;
        state.maxExecTime.value = action.payload.maxExecTime;
        state.medianExecTime.loading = false;
        state.medianExecTime.value = action.payload.medianExecTime;
        state.queryCount.loading = false;
        state.queryCount.value = action.payload.queryCount;
      }).addCase(fetchComparitiveQueryInsights.rejected, (state, action) => {
        state.maxExecTime.loading = false;
        state.medianExecTime.loading = false;
        state.queryCount.loading = false;
      })

      .addCase(fetchQueryTrendByDate.pending, (state, action) => {
        state.queryTrendByDate.loading = true;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryTrendByDate.hoursInitialFetchDone = true;
        } else  {
          state.queryTrendByDate.initialFetchDone = true;
        }
      })
      .addCase(fetchQueryTrendByDate.fulfilled, (state, action) => {
        state.queryTrendByDate.loading = false;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryTrendByDate.valueByHours = action.payload;
        } else  {
          state.queryTrendByDate.value = action.payload;
        }
      })
      .addCase(fetchQueryTrendByDate.rejected, (state, action) => {
        state.queryTrendByDate.loading = false;
      })

      .addCase(fetchQueryTrendByWeekday.pending, (state) => {
        state.queryTrendByWeekday.loading = true;
        state.queryTrendByWeekday.initialFetchDone = true;
      })
      .addCase(fetchQueryTrendByWeekday.fulfilled, (state, action) => {
        state.queryTrendByWeekday.loading = false;
        state.queryTrendByWeekday.value = action.payload;
      })
      .addCase(fetchQueryTrendByWeekday.rejected, (state, action) => {
        state.queryTrendByWeekday.loading = false;
      })

      .addCase(fetchQueryCountByShiftHours.pending, (state, action) => {
        state.queryCountByShiftHours.loading = true;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryCountByShiftHours.hoursInitialFetchDone = true;
        } else  {
          state.queryCountByShiftHours.initialFetchDone = true;
        }
      })
      .addCase(fetchQueryCountByShiftHours.fulfilled, (state, action) => {
        state.queryCountByShiftHours.loading = false;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryCountByShiftHours.valueByHours = action.payload;
        } else  {
          state.queryCountByShiftHours.value = action.payload;
        }
      })
      .addCase(fetchQueryCountByShiftHours.rejected, (state, action) => {
        state.queryCountByShiftHours.loading = false;
      })

      .addCase(fetchQueryCountByUsers.pending, (state) => {
        state.queryCountByUsers.loading = true;
        state.queryCountByUsers.initialFetchDone = true;
      })
      .addCase(fetchQueryCountByUsers.fulfilled, (state, action) => {
        state.queryCountByUsers.loading = false;
        state.queryCountByUsers.value = action.payload;
      })
      .addCase(fetchQueryCountByUsers.rejected, (state, action) => {
        state.queryCountByUsers.loading = false;
      })

      .addCase(fetchQueryCountByWarehouseName.pending, (state) => {
        state.queryCountByWarehouseName.loading = true;
        state.queryCountByWarehouseName.initialFetchDone = true;
      })
      .addCase(fetchQueryCountByWarehouseName.fulfilled, (state, action) => {
        state.queryCountByWarehouseName.loading = false;
        state.queryCountByWarehouseName.value = action.payload;
      })
      .addCase(fetchQueryCountByWarehouseName.rejected, (state, action) => {
        state.queryCountByWarehouseName.loading = false;
      })

      .addCase(fetchQueryCountByWarehouseSize.pending, (state) => {
        state.queryCountByWarehouseSize.loading = true;
        state.queryCountByWarehouseSize.initialFetchDone = true;
      })
      .addCase(fetchQueryCountByWarehouseSize.fulfilled, (state, action) => {
        state.queryCountByWarehouseSize.loading = false;
        state.queryCountByWarehouseSize.value = action.payload;
      })
      .addCase(fetchQueryCountByWarehouseSize.rejected, (state, action) => {
        state.queryCountByWarehouseSize.loading = false;
      })

      .addCase(fetchQueryCountVsUsers.pending, (state, action) => {
        state.queryCountVsUsers.loading = true;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryCountVsUsers.hoursInitialFetchDone = true;
        } else  {
          state.queryCountVsUsers.initialFetchDone = true;
        }
      })
      .addCase(fetchQueryCountVsUsers.fulfilled, (state, action) => {
        state.queryCountVsUsers.loading = false;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryCountVsUsers.valueByHours = action.payload;
        } else  {
          state.queryCountVsUsers.value = action.payload;
        }
      })
      .addCase(fetchQueryCountVsUsers.rejected, (state, action) => {
        state.queryCountVsUsers.loading = false;
      })

      .addCase(fetchQueriesBiggerThanNxtWarehouse.pending, (state) => {
        state.queriesBiggerThanNextWarehouse.loading = true;
        state.queriesBiggerThanNextWarehouse.initialFetchDone = true;
      })
      .addCase(fetchQueriesBiggerThanNxtWarehouse.fulfilled, (state, action) => {
        state.queriesBiggerThanNextWarehouse.loading = false;
        state.queriesBiggerThanNextWarehouse.value = action.payload;
      })
      .addCase(fetchQueriesBiggerThanNxtWarehouse.rejected, (state, action) => {
        state.queriesBiggerThanNextWarehouse.loading = false;
      })

      .addCase(fetchQuerySizeVsExecutionTime.pending, (state) => {
        state.querySizeVsExecutionTime.loading = true;
        state.querySizeVsExecutionTime.initialFetchDone = true;
      })
      .addCase(fetchQuerySizeVsExecutionTime.fulfilled, (state, action) => {
        state.querySizeVsExecutionTime.loading = false;
        state.querySizeVsExecutionTime.value = action.payload;
      })
      .addCase(fetchQuerySizeVsExecutionTime.rejected, (state, action) => {
        state.querySizeVsExecutionTime.loading = false;
      })

      .addCase(fetchAverageExecutionTimeByWarehouseName.pending, (state) => {
        state.averageExecutionTimeByWarehouseName.loading = true;
        state.averageExecutionTimeByWarehouseName.initialFetchDone = true;
      })
      .addCase(fetchAverageExecutionTimeByWarehouseName.fulfilled, (state, action) => {
        state.averageExecutionTimeByWarehouseName.loading = false;
        state.averageExecutionTimeByWarehouseName.value = action.payload;
      })
      .addCase(fetchAverageExecutionTimeByWarehouseName.rejected, (state, action) => {
        state.averageExecutionTimeByWarehouseName.loading = false;
      })

      .addCase(fetchAverageExecutionTimeByWarehouseSize.pending, (state) => {
        state.averageExecutionTimeByWarehouseSize.loading = true;
        state.averageExecutionTimeByWarehouseSize.initialFetchDone = true;
      })
      .addCase(fetchAverageExecutionTimeByWarehouseSize.fulfilled, (state, action) => {
        state.averageExecutionTimeByWarehouseSize.loading = false;
        state.averageExecutionTimeByWarehouseSize.value = action.payload;
      })
      .addCase(fetchAverageExecutionTimeByWarehouseSize.rejected, (state, action) => {
        state.averageExecutionTimeByWarehouseSize.loading = false;
      })

      .addCase(fetchAllUsers.pending, (state) => {
        state.users.loading = true;
        state.users.initialFetchDone = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.users.loading = false;
        state.users.value = action.payload;
        state.selectedUsers = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.users.loading = false;
      })

      .addCase(fetchAllWarehouses.pending, (state) => {
        state.warehouses.loading = true;
        state.warehouses.initialFetchDone = true;
      })
      .addCase(fetchAllWarehouses.fulfilled, (state, action) => {
        state.warehouses.loading = false;
        state.warehouses.value = action.payload;
        state.selectedWarehouses = action.payload;
      })
      .addCase(fetchAllWarehouses.rejected, (state, action) => {
        state.warehouses.loading = false;
      })

      .addCase(fetchBiggerQueriesForExeTime.pending, (state) => {
        state.biggerQueriesForExeTime.loading = true;
        state.biggerQueriesForExeTime.initialFetchDone = true;
      })
      .addCase(fetchBiggerQueriesForExeTime.fulfilled, (state, action) => {
        state.biggerQueriesForExeTime.loading = false;
        state.biggerQueriesForExeTime.value = action.payload;
      })
      .addCase(fetchBiggerQueriesForExeTime.rejected, (state, action) => {
        state.biggerQueriesForExeTime.loading = false;
      })

      .addCase(fetchBiggerQueriesForByteScanned.pending, (state) => {
        state.biggerQueriesForByteScanned.loading = true;
        state.biggerQueriesForByteScanned.initialFetchDone = true;
      })
      .addCase(fetchBiggerQueriesForByteScanned.fulfilled, (state, action) => {
        state.biggerQueriesForByteScanned.loading = false;
        state.biggerQueriesForByteScanned.value = action.payload;
      })
      .addCase(fetchBiggerQueriesForByteScanned.rejected, (state, action) => {
        state.biggerQueriesForByteScanned.loading = false;
      })

      .addCase(fetchBiggerQueriesForExeTimeAndOnByteScanned.pending, (state) => {
        state.biggerQueriesForExeTimeAndByteScanned.loading = true;
        state.biggerQueriesForExeTimeAndByteScanned.initialFetchDone = true;
      })
      .addCase(fetchBiggerQueriesForExeTimeAndOnByteScanned.fulfilled, (state, action) => {
        state.biggerQueriesForExeTimeAndByteScanned.loading = false;
        state.biggerQueriesForExeTimeAndByteScanned.value = action.payload;
      })
      .addCase(fetchBiggerQueriesForExeTimeAndOnByteScanned.rejected, (state, action) => {
        state.biggerQueriesForExeTimeAndByteScanned.loading = false;
      })

      // app slice effect
      .addCase(fetchDashboardMeta.fulfilled, (state, action) => {
        state.selectedUsers = action.payload.users;
        state.selectedWarehouses = action.payload.warehouses;
      })
  },
});

export const {
  setDateRange,
  setQueryTrendToggler,
  setQueryCountByWarehouseToggler,
  setaverageExecutionTimeByWarehouseToggler,
  clearReportsData,
  setSelectedUsers,
  setSelectedWarehouses,
  dismissInsights,
  toggleInsightsCollapsedState,
  selectTab,
  clearBiggerQuerieForExeTime,
  clearBiggerQuerieForByteScanned,
  clearBiggerQuerieForExeTimeAndByteScanned,
  resetPerformanceMetrics
} = performanceMetricsSlice.actions;

export const selectMaxExecTime = (state: RootState) => state.performanceMetrics.maxExecTime;
export const selectMedianExecTime = (state: RootState) => state.performanceMetrics.medianExecTime;
export const selectQueryCount = (state: RootState) => state.performanceMetrics.queryCount;
export const selectQueryTrendByDate = (state: RootState) => state.performanceMetrics.queryTrendByDate;
export const selectQueryTrendByWeekday = (state: RootState) => state.performanceMetrics.queryTrendByWeekday;
export const selectQueryCountByShiftHours = (state: RootState) => state.performanceMetrics.queryCountByShiftHours;
export const selectQueryCountByUsers = (state: RootState) => state.performanceMetrics.queryCountByUsers;
export const selectQueryCountByWarehouseName = (state: RootState) => state.performanceMetrics.queryCountByWarehouseName;
export const selectQueryCountByWarehouseSize = (state: RootState) => state.performanceMetrics.queryCountByWarehouseSize;
export const selectQueryCountVsUsers = (state: RootState) => state.performanceMetrics.queryCountVsUsers;
export const selectQueriesBiggerThanNextWarehouse = (state: RootState) => state.performanceMetrics.queriesBiggerThanNextWarehouse;
export const selectQuerySizeVsExecutionTime = (state: RootState) => state.performanceMetrics.querySizeVsExecutionTime;
export const selectAverageExecutionTimeByWarehouseName = (state: RootState) => state.performanceMetrics.averageExecutionTimeByWarehouseName;
export const selectAverageExecutionTimeByWarehouseSize = (state: RootState) => state.performanceMetrics.averageExecutionTimeByWarehouseSize;
export const selectStartDate = (state: RootState) => state.performanceMetrics.startDate;
export const selectEndDate = (state: RootState) => state.performanceMetrics.endDate;
export const selectQueryTrendToggler = (state: RootState) => state.performanceMetrics.queryTrendToggler;
export const selectQueryCountByWarehouseToggler = (state: RootState) => state.performanceMetrics.queryCountByWarehouseToggler;
export const selectAverageExecutionTimeByWarehouseToggler = (state: RootState) => state.performanceMetrics.averageExecutionTimeByWarehouseToggler;
export const selectUsers = (state: RootState) => state.performanceMetrics.users;
export const selectWarehouses = (state: RootState) => state.performanceMetrics.warehouses;
export const selectSelectedUsers = (state: RootState) => state.performanceMetrics.selectedUsers;
export const selectSelectedWarehouses = (state: RootState) => state.performanceMetrics.selectedWarehouses;
export const selectIsInsightsDismissed = (state: RootState) => state.performanceMetrics.isInsightsDismissed;
export const selectSelectedTab = (state: RootState) => state.performanceMetrics.selectedTab;
export const selectIsInsightsCollapsed = (state: RootState) => state.performanceMetrics.isInsightsCollapsed;
export const selectBiggerQueriesForExeTime = (state: RootState) => state.performanceMetrics.biggerQueriesForExeTime;
export const selectBiggerQueriesForByteScanned = (state: RootState) => state.performanceMetrics.biggerQueriesForByteScanned;
export const selectBiggerQueriesForExeTimeAndByteScanned = (state: RootState) => state.performanceMetrics.biggerQueriesForExeTimeAndByteScanned;

export default performanceMetricsSlice.reducer;
 