import React from 'react';
import Switch from 'react-bootstrap/esm/Switch';
import { Redirect, Route } from 'react-router';
import { NavLink } from 'react-router-dom';

import Date from '../Date';
import User from '../User';
import Warehouse from '../Warehouse';
import WarehouseSize from '../WarehouseSize';
import { PROTECTED_ROUTES } from '../../constants/route';
import { useAppSelector } from '../../store/hooks';
import { selectDashboardMeta } from '../../slice/app';

import './Drilldown.scss';
import Spinner from '../../components/Spinner';

const drilldownTabs = [
  { label: 'Warehouse', path: PROTECTED_ROUTES.warehouseDrilldown, component: Warehouse },
  { label: 'User', path: PROTECTED_ROUTES.userDrilldown, component: User },
  { label: 'Date', path: PROTECTED_ROUTES.dateDrilldown, component: Date },
  { label: 'Warehouse Size', path: PROTECTED_ROUTES.warehouseSizeDrilldown, component: WarehouseSize }
];

const Drilldown = () => {
  const { loading: dropdownDataLoading } = useAppSelector(selectDashboardMeta);
  return (
    <div className="drilldownContainer">
      { dropdownDataLoading ?
        <div className="text-center py-10"><Spinner /></div> :
        <>
        <div className="drilldownTabs d-flex justify-content-around">
          { drilldownTabs.map(({ label, path }) => (
            <NavLink to={path} className="drilldownTab no-text-decor font-weight-bold d-block pt-dot625 pb-2 px-1dot375 lh-1dot125 fs-dot875 text-white">{label}</NavLink>
          )) }
        </div>
        <div>
          <Switch className="p-0">
            {drilldownTabs.map(({ component, path }) => (
              <Route path={path} component={component} />
            ))}
            <Redirect to={PROTECTED_ROUTES.warehouseDrilldown} />
          </Switch>
        </div>
      </>}
    </div>
  );
}

export default Drilldown;
