import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, ThunkAction } from "@reduxjs/toolkit";

import Scrollbar from "../Scrollbar";
import Spinner from "../Spinner";
import TableReport from "../TableReport";
import { IInsight } from "../../slice/costMetrics";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/store";
import { chunkArray } from "../../utils/appUtils";
import clapIcon from '../../assets/image/clap.svg';
import arrowRight from '../../assets/image/arrow-right.svg';
import arrowDown from '../../assets/image/arrow-down.svg';
import dismissIcon from '../../assets/image/close.svg';

import './Insights.scss';

interface IInsightsModalProps {
  dataSelector: (state: RootState) => any;
  getDataAction: (query: any) => ThunkAction<any, any, any, any>;
  columns: any[];
  dataFormatter: (data: any) => object[];
}

interface IInsightsProps {
  insights?: IInsight[];
  loading?: boolean;
  dismissAction: ActionCreatorWithoutPayload<string>;
  collapseAction:  ActionCreatorWithPayload<boolean>;
  isInsightsDismissed?: boolean;
  isInsightsCollapsed?: boolean;
  insightsModalData: IInsightsModalProps[];
}

export const Insights = ({
  loading, insights = [], insightsModalData = [], dismissAction, collapseAction, isInsightsDismissed, isInsightsCollapsed
}: IInsightsProps) => {
  const dispatch = useAppDispatch();
  const insightsPagesContainer: RefObject<HTMLDivElement> = useRef(null);
  // Insights config
  const INSIGHTS_PER_PAGE: number = 4;
  const INSIGHT_CARD_PADDING_IN_PX: number = 36;
  const OVERALL_MARGIN: number = 44;
  const SCREEN_WIDTH: number = document.getElementsByTagName('body')[0].clientWidth;
  const chunkedInsights = chunkArray(insights, INSIGHTS_PER_PAGE);
  const FIRST_OR_LAST_PAGE_WIDTH: number = SCREEN_WIDTH + OVERALL_MARGIN - INSIGHT_CARD_PADDING_IN_PX * 2;

  const [leftArrowVisible, setLeftArrowVisibility] = useState(false);
  const [rightArrowVisible, setRightArrowVisibility] = useState(insights.length > 4);

  useEffect(() => {
    insights.length > INSIGHTS_PER_PAGE && !rightArrowVisible && setRightArrowVisibility(true);
    insights.length <= INSIGHTS_PER_PAGE && rightArrowVisible && setRightArrowVisibility(false);
  }, [insights, loading, INSIGHTS_PER_PAGE, rightArrowVisible]);
  const scrollElementRef: MutableRefObject<HTMLElement | null> = useRef(null);
  const onInsightScroll = useCallback(() => {
    const { scrollLeft, scrollWidth } = (scrollElementRef.current as HTMLElement);
    scrollLeft === 0 && leftArrowVisible && setLeftArrowVisibility(false);
    scrollLeft !== 0 && !leftArrowVisible && setLeftArrowVisibility(true);
    scrollWidth - scrollLeft < FIRST_OR_LAST_PAGE_WIDTH + INSIGHT_CARD_PADDING_IN_PX && rightArrowVisible && setRightArrowVisibility(false);
    scrollWidth - scrollLeft > FIRST_OR_LAST_PAGE_WIDTH + INSIGHT_CARD_PADDING_IN_PX && !rightArrowVisible && setRightArrowVisibility(true);
  }, [scrollElementRef, leftArrowVisible, rightArrowVisible, FIRST_OR_LAST_PAGE_WIDTH]);
  const scrollRight = useCallback(() => {
    if (insightsPagesContainer.current && scrollElementRef.current) {
      const pages = insightsPagesContainer.current.children;
      let toPosition;
      for (let i = 1; i < pages.length; i++) {
        const { offsetLeft } = pages[i] as HTMLElement;
        const { left } = pages[i].getBoundingClientRect();
        if (left > INSIGHT_CARD_PADDING_IN_PX) {
          toPosition = offsetLeft + OVERALL_MARGIN - INSIGHT_CARD_PADDING_IN_PX;
          break;
        }
      }
      if (toPosition) {
        scrollElementRef.current.scrollTo({ left: toPosition, behavior: 'smooth' });
      }
    }
  }, []);
  const scrollLeft = useCallback(() => {
    if (insightsPagesContainer.current && scrollElementRef.current) {
      const pages = insightsPagesContainer.current.children;
      let toPosition: number | undefined;
      for (let i = pages.length - 2; i >= 0; i--) {
        const { offsetLeft } = pages[i] as HTMLElement;
        const { right, left } = pages[i].getBoundingClientRect();
        if (right >= INSIGHT_CARD_PADDING_IN_PX && left < 0) {
          toPosition = i === 0 ? 0 : offsetLeft + OVERALL_MARGIN - INSIGHT_CARD_PADDING_IN_PX;
          break;
        }
      }
      if (toPosition !== undefined) {
        scrollElementRef.current.scrollTo({ left: toPosition, behavior: 'smooth' });
      }
    }
  }, []);

  const assignScrollRef = useCallback((ref) => {
    scrollElementRef.current = ref.current;
  }, []);

  const [ showModal, setShowModal ] = useState(false);
  const currentModalProps: MutableRefObject<IInsightsModalProps> = useRef(insightsModalData[0]);
  const openModal = useCallback((insightIndex: number) => {
    currentModalProps.current = insightsModalData[insightIndex];
    setShowModal(true);
  }, [ insightsModalData ]);

  if (isInsightsDismissed) {
    return null;
  }

  const renderAction = () => {
    if (!(insights.length || loading)) {
      return (
        <div
          className="fs-dot875 cursor-pointer dismissBtn"
          onClick={() => dispatch(dismissAction())}
        >
          <img className="mr-2" src={dismissIcon} alt="X"/>
          Dismiss
        </div>
      );
    }
    if (insights.length && !loading) {
      return (
        <div
          className="fs-dot875 cursor-pointer collapseBtn"
          onClick={() => dispatch(collapseAction(!isInsightsCollapsed))}
        >
          <img className={`collapseSymbol mr-2 ${isInsightsCollapsed ? '' : 'rotate-180'}`} src={arrowDown} alt=""/>
          {isInsightsCollapsed ? 'Show' : 'Hide'}
        </div>
      );
    }
    return null;
  }

  const showCarousal = SCREEN_WIDTH > 992;

  return (
    <div className="insightContainer text-light row mb-0 pt-3 position-relative">
      <div className={`col-12 ${insights.length ? 'mb-dot75' : 'mb-1dot25'} px-lg-9 px-md-5 px-4 d-flex align-items-center justify-content-between px-0`}>
        <div className="font-weight-bold">Insights overview:</div>
        { renderAction() }
      </div>
      {!insights.length && !loading ?
        <div className="position-absolute absolute-center d-flex align-items-center fs-dot875">
          <img src={clapIcon} alt="congrats" width={24} height={24} className="mr-dot75 mb-1" />
          Awesome, Everything looks perfect. No insights to show at the moment.
        </div> :
        <div className={`insightsCollapser col-12 px-0 overflow-hidden text-left position-relative ${isInsightsCollapsed ? 'collapsed' : ''}`}>
          {showCarousal &&
            <>
              <div
                onClick={scrollRight}
                className={`carousalArrow right position-absolute pointer \
                  ${rightArrowVisible ? 'd-flex align-items-center justify-content-center' : 'd-none'
                  }`
                }
                style={{ right: `${INSIGHT_CARD_PADDING_IN_PX}px` }}
              >
                <img src={arrowRight} alt="next" />
              </div>
              <div
                onClick={scrollLeft}
                className={`carousalArrow left position-absolute pointer \
                  ${leftArrowVisible ? 'd-flex align-items-center justify-content-center' : 'd-none'}`
                }
                style={{ left: `${INSIGHT_CARD_PADDING_IN_PX}px` }}
              >
                <img className="rotate-180" src={arrowRight} alt="prev" />
              </div>
            </>}
          <Scrollbar onScroll={onInsightScroll} showAlways={true} assignRef={assignScrollRef} className="vw-100">
            <div
              className="pb-1dot875 clearfix position-relative"
              style={{
                width: showCarousal ? `calc(${chunkedInsights.length * SCREEN_WIDTH
                  }px - ${chunkedInsights.length * INSIGHT_CARD_PADDING_IN_PX * 2
                  }px - ${2 * (OVERALL_MARGIN - INSIGHT_CARD_PADDING_IN_PX)
                  }px)` : 'unset',
                margin: `0 ${OVERALL_MARGIN}px`
              }}
              ref={insightsPagesContainer}
            >
              { !chunkedInsights.length && loading ?
                  <div className="d-flex align-items-center pl-9 loaderWithNoData">
                    <Spinner width={25} />
                  </div> : null}
              {chunkedInsights.map((insights: IInsight[], i) => (
                <div
                  key={`carousal_page_${i}`}
                  className="d-inline-block no-wrap float-left align-top carousalBatch"
                  style={{
                    width: showCarousal ? `calc(${SCREEN_WIDTH - 2 * INSIGHT_CARD_PADDING_IN_PX
                      }px - ${i === 0 || (i === chunkedInsights.length - 1) ?
                        OVERALL_MARGIN -INSIGHT_CARD_PADDING_IN_PX : 0}px)` : 'unset'}}
                >
                  { insights.map(({ value = '', desc }: IInsight, i: number) => (
                    <span key={`${value}_${i}`}>
                      <div
                        className={`${showCarousal ? 'w-25' : ''} wrap px-2dot25 d-inline-block align-top insightCard`}
                        key={`insight_${i}`}
                      >
                        <div className="fs-1dot125 lh-1dot5">
                          <span className={insightsModalData[i] ? 'clickable' : undefined} onClick={insightsModalData[i] ? () => openModal(i) : undefined}>
                            {value}
                          </span>
                        </div>
                        <div className="fs-dot75 lh-1 py-1">{desc}</div>
                      </div>
                      { chunkedInsights.length < 4 && insights.length - 1 === i && loading ?
                        <div className="d-inline-block pl-9 position-absolute insightLoader">
                          <Spinner width={25} />
                        </div> : null}
                    </span>
                  ))}
                </div>
              )
              )}
            </div>
          </Scrollbar>
        </div>}
        { showModal &&
        <div id="modal-background" className="modalBackground px-5 d-flex align-items-center justify-content-center"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => (e.target as any)?.id === 'modal-background' && setShowModal(false)}
        >
          <div className="col-8" style={{ height: '75vh'}}>
            <TableReport
              query={{}}
              { ...currentModalProps.current }
              closeBtn={true}
              onClose={() => setShowModal(false)}
              exportable={true}
            />
          </div>
        </div>
      }
    </div>
  );
}
