import React, { useState } from 'react';

import Logo from '../../assets/image/logo-blue.svg';
import { selectCreatingFreeSession } from '../../slice/demo';
import { useAppSelector } from '../../store/hooks';
import Input from '../Input';
import Spinner from '../Spinner';

interface IDemoInit {
  onStart: (email: string) => void;
}

const DemoInit = ({ onStart }: IDemoInit) => {
  const loading = useAppSelector(selectCreatingFreeSession);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const validateInput = () => {
    let emailValid = false;

    if (!email) {
      setEmailError('Please enter your email address');
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
      emailValid = true;
    }
    return emailValid;
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  }

  const onSubmit = () => {
    if (validateInput()) {
      onStart(email);
    }
  }

  return (
    <div className="text-center bg-white fs-dot875 exploreModal">
      <div className="mb-4"><img src={Logo} alt="logo" /></div>
      <div className="mb-3 font-weight-bold">Welcome to Snowflake Usage Dashboard</div>
      {/* <div className="mb-3">You can explore this app for the first 15 mins without registering.</div>
      <div className="mb-2">We have a demo video that might help you to understand the product better. Give it a try before you start exploring.</div>
      <div className="mb-4 link-custom text-decor-underline">Watch Demo Video</div> */}
      <div className="row mb-4">
        <div className="col-12 pl-0 pr-3">
          <Input
            value={email}
            type="text"
            label="Your email address*"
            error={emailError}
            onBlur={validateInput}
            onKeyUp={onKeyUp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        { loading ? <div className="mr-2"><Spinner width={10}/></div> : null }
        <button
          onClick={onSubmit}
          className={`rounded-white-border bg-primary text-white px-1dot375 py-2 ${false ? 'loading' : ''}`}
        >Start Exploring</button>
      </div>
    </div>
  );
}

export default DemoInit;
