import moment from "moment";

const veryFirstSessionAtKey = 'ftd';
const lastSessionAtKey = 'lsa';
const dayOneExtendedTimeUsedKey = 'dortu';
const dayOneExtendedTimeUsedValue = 'y';
const dayOneRegistrationKey = 'dor';
const dayOneRegistrationvalue = 'y';
const dayOneFreeTime = 600; // in sec
const dayOneExtensionTime = 900; // in sec
const registeredUserTime = 600; // in sec
export const disableDayOneFreTimeExtension = true;

class DemoProvider {
  private veryFirstSessionAt?: number;
  private lastSessionAt?: number;
  private isDayOneExtendedSessionUsed: boolean = false;
  private isDayOneRegistrationDone: boolean = false;
  constructor() {
    this.init();
  }

  private init() {
    const veryFirstSessionAt: number | typeof NaN = Number(String(localStorage.getItem(veryFirstSessionAtKey)));
    const lastSessionAt: number | typeof NaN = Number(String(localStorage.getItem(lastSessionAtKey)));
    const isDayOneExtendedSessionUsed = localStorage.getItem(dayOneExtendedTimeUsedKey) === dayOneExtendedTimeUsedValue;
    const isDayOneRegistrationDone = localStorage.getItem(dayOneRegistrationKey) === dayOneRegistrationvalue;
    if (!veryFirstSessionAt || !lastSessionAt) {
      localStorage.removeItem(veryFirstSessionAtKey);
      localStorage.removeItem(lastSessionAtKey);
      localStorage.removeItem(dayOneExtendedTimeUsedKey);
    } else {
      this.veryFirstSessionAt = veryFirstSessionAt;
      this.lastSessionAt = lastSessionAt;
      this.isDayOneExtendedSessionUsed = isDayOneExtendedSessionUsed;
      this.isDayOneRegistrationDone = isDayOneRegistrationDone;
    }
  }

  public getRemainingTime = (): number => {
    if (!this.lastSessionAt || !this.veryFirstSessionAt) return 0;
    if (this.isDayOne()) {
      if (this.isDayOneRegisteredSessionEnded()) {
        return 0;
      } else if (this.isDayOneRegisteredSessionInitialized()) {
        return registeredUserTime - Math.floor((moment().valueOf() - this.lastSessionAt) / 1000);
      } else if (!disableDayOneFreTimeExtension && this.isDayOneExtendedSessionEnded()) {
        return 0;
      } else if (!disableDayOneFreTimeExtension && this.isDayOneExtendedSessionInitialized()) {
        return  dayOneExtensionTime - Math.floor((moment().valueOf() - this.lastSessionAt) / 1000);
      } else if (this.isDayOneFreeSessionEnded()) {
        return 0;
      } else if (this.isDayOneSessionInitialized()) {
        return  dayOneFreeTime - Math.floor((moment().valueOf() - this.lastSessionAt) / 1000);
      } else {
        return 0;
      }
    } else {
      if (this.isTodaysSessionEnded()) {
        return 0;
      } else if (this.isTodaysSessionStarted()) {
        return  registeredUserTime - Math.floor((moment().valueOf() - this.lastSessionAt) / 1000);
      }
    }
    return 0;
  }

  public isDayOne(): boolean {    
    return !this.lastSessionAt || !this.veryFirstSessionAt || Math.floor((moment().valueOf() - this.lastSessionAt) / 1000) < (24 * 60 * 60);  
  }

  public isDayOneSessionInitialized = (): boolean => {
    return Boolean(this.lastSessionAt && this.veryFirstSessionAt);
  }

  public isDayOneFreeSessionEnded = (): boolean => {
    return Boolean(this.veryFirstSessionAt && this.lastSessionAt && (
      !this.isDayOne() ||
      Math.floor((moment().valueOf() - this.lastSessionAt) / 1000) >= dayOneFreeTime
    ));
  }

  public isDayOneExtendedSessionInitialized = (): boolean => {
    return disableDayOneFreTimeExtension || this.isDayOneExtendedSessionUsed;
  }

  public isDayOneExtendedSessionEnded = (): boolean => {
    return disableDayOneFreTimeExtension || (Boolean(this.lastSessionAt && this.veryFirstSessionAt && this.isDayOne() && (
      this.isDayOneExtendedSessionInitialized() &&
      Math.floor((moment().valueOf() - this.lastSessionAt) / 1000) > dayOneExtensionTime
    )));
  }

  public isDayOneRegisteredSessionInitialized = (): boolean => {
    return this.isDayOneRegistrationDone;
  }

  public isDayOneRegisteredSessionEnded = (): boolean => {
    return Boolean(this.lastSessionAt && this.isDayOneRegisteredSessionInitialized() &&
      Math.floor((moment().valueOf() - this.lastSessionAt) / 1000) >= registeredUserTime);
  }
  
  public isTodaysSessionStarted = (): boolean => {
    const firstSessionDataObj = moment(this.veryFirstSessionAt);
    return Boolean(this.veryFirstSessionAt && this.lastSessionAt && moment()
      .set('hour', firstSessionDataObj.hour())
      .set('minute', firstSessionDataObj.minute())
      .set('second', firstSessionDataObj.second()).valueOf() < this.lastSessionAt);
  }

  public isTodaysSessionEnded = (): boolean => {
    return Boolean(this.veryFirstSessionAt && this.lastSessionAt && this.isTodaysSessionStarted() &&
      Math.floor((moment().valueOf() - this.lastSessionAt) / 1000) >= registeredUserTime);
  }

  public initializeDayOneFreeSession = () => {
    const now = String(moment().valueOf());
    localStorage.setItem(veryFirstSessionAtKey, now);
    localStorage.setItem(lastSessionAtKey, now);
    this.init();
  }

  public initializeExtendedSession = () => {
    const now = String(moment().valueOf());
    localStorage.setItem(dayOneExtendedTimeUsedKey, dayOneExtendedTimeUsedValue);
    localStorage.setItem(lastSessionAtKey, now);
    this.init();
  }

  public initializeRegisteredSession = () => {
    const now = String(moment().valueOf());
    localStorage.setItem(dayOneRegistrationKey, dayOneRegistrationvalue);
    localStorage.setItem(lastSessionAtKey, now);
    this.init();
  }
}

export default DemoProvider;
