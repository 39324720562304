import { Chart, registerables } from 'chart.js';
import React, { MutableRefObject, RefObject, useEffect, useMemo, useRef } from 'react';
import { ThunkAction } from 'redux-thunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { getNestedValue } from '../../utils/appUtils';
import NoDataTemplate from '../NoDataTemplate';
import Spinner from '../Spinner';
import NoDataIcon from '../../assets/image/no-data-bar.svg';
import LineChartIcon from '../../assets/image/line-chart-symbol.svg';

import './TimeTrend.scss';

Chart.register(...registerables);
Chart.defaults.font.family = 'Mulish';
Chart.defaults.font.size = 12;

interface IStackedBarChartProps {
  xLabel?: string;
  barLabel?: string;
  query: any;
  skipInitialRender?: boolean;
  getDataAction: (query: any) => ThunkAction<any, any, any, any>;
  dataSelector: (state: RootState) => any;
  categoryKey: string;
  hourKey?: string;
  valKeys: string[];
  isArrayOfObj?: boolean;
  enableChartOption?: boolean;
  colors?: string[];
  datasetLabels: string[];
  lineKey?: string;
  lineLabel?: string;
  assignLeftAxisForLine?: boolean;
}

const StackedBarChart = ({
  categoryKey,
  valKeys,
  xLabel = '',
  barLabel = '',
  query,
  isArrayOfObj,
  getDataAction,
  dataSelector,
  colors,
  datasetLabels,
  lineKey,
  lineLabel,
  assignLeftAxisForLine
}: IStackedBarChartProps) => {
  const colorList = useMemo(() => (
    colors || ['#151EF9', '#DE81FF', '#5C6CBC', '#DABC52', '#52C9DA', '#5CBC71', '#FC7D58']
  ), [ colors ]);
  const isInitialRenderDone: MutableRefObject<boolean> = useRef(false);
  const queryRef: MutableRefObject<any> = useRef(query);
  const dispatch = useAppDispatch();
  const canvasElement: RefObject<HTMLCanvasElement> = useRef(null);
  const yLabelRef: RefObject<HTMLDivElement> = useRef(null);
  const lineLabelRef: RefObject<HTMLDivElement> = useRef(null);
  const xLabelRef: RefObject<HTMLDivElement> = useRef(null);
  const { value, loading, initialFetchDone } = useAppSelector(dataSelector);

  const memoizedData = useMemo(() => {
    if (!loading && value) {
      let dates: string[] = []; 
      let lineValues: number[] = [];
      let values: { [key: string]: number[]; } =  {};
      if (isArrayOfObj) {
        dates = value.map((obj: object) => getNestedValue(obj, categoryKey));
        if (lineKey) {
          lineValues = value.map((obj: object) => getNestedValue(obj, lineKey));
        }
        valKeys.forEach((key: string) => {
          values[key] = value.map((obj: object) => getNestedValue(obj, key));
        });
      } else {
        dates = getNestedValue(value, categoryKey);
        if (lineKey) {
          lineValues = value[lineKey];
        }
        valKeys.forEach((key: string) => {
          values[key] = getNestedValue(value, key);
        });
      }
      return dates.length && Object.keys(values).length ? {
        type: 'bar' as any,
        data: {
          labels: dates,
          datasets: [
            ...Object.keys(values).map((key: string, i: number) => ({
              label: datasetLabels[i],
              data: values[key],
              order: i + 1,
              borderColor: colorList[i],
              backgroundColor: colorList[i],
              maxBarThickness: 50,
              yAxisID: assignLeftAxisForLine ? 'y1' : 'y'
            })),
            ...(lineKey && lineValues?.length ? [{
              label: lineLabel || lineKey,
              data: lineValues,
              order: 0,
              type: 'line' as any,
              yAxisID: assignLeftAxisForLine ? 'y' : 'y1'
            }] : [])
          ]
        },
        plugins: [{ 
          id: 'yLabelPositioner',
          beforeBuildTicks: () => {
            if (yLabelRef.current) {
              yLabelRef.current.style.paddingLeft = 'unset';
            }
            if (lineLabelRef.current) {
              lineLabelRef.current.style.paddingRight = 'unset';
            }
            if (xLabelRef.current) {
              xLabelRef.current.style.paddingLeft = 'unset';
              xLabelRef.current.style.paddingRight = 'unset';
            }
          },
          afterBuildTicks: (chart: any) => {
            const height = chart?.scales?.x?.height;
            const yWidth = chart?.scales?.y?.width;
            const y1Width = chart?.scales?.y1?.width;
            if (yLabelRef.current && height) {
              yLabelRef.current.style.paddingLeft = `calc(${height}px + 1.5rem)`;
            }
            if (lineLabelRef.current && height) {
              lineLabelRef.current.style.paddingLeft = `calc(${height}px + 1.5rem)`;
            }
            if (xLabelRef.current && yWidth) {
              xLabelRef.current.style.paddingLeft = `calc(${yWidth}px + 1.5rem)`;
            }
            if (xLabelRef.current && y1Width) {
              xLabelRef.current.style.paddingRight = `calc(${y1Width}px + 1.5rem)`;
            }
          }
        }],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "x",
          elements: {
            bar: {
              borderRadius: 2
            },
            point: {
              borderWidth: 1,
              borderColor: '#fff',
              backgroundColor: '#151EF9',
              radius: 4
            },
            line: {
              borderColor: '#151EF9',
              borderWidth: 2
            }
          },
          scales: {
            y: {
              grid: {
                display: false,
                drawBorder: false
              },
              ticks: {
                maxTicksLimit: 5
              }
            },
            x: {
              stacked: true,
              grid: {
                display: false,
                borderDash: [5, 5],
                color: '#ECF0F4',
                drawBorder: false
              },
              ticks: {
                autoSkip: true,
                maxRotation: 0
              }
            },
            ...(lineKey && lineValues.length ? {
              y1: {
                position: "right",                
                grid: {
                  display: false,
                  drawBorder: false
                },
                ticks: {
                  maxTicksLimit: 5
                }
              }
            } : {})
          },
          plugins: {
            legend: {
              display: true,
              align: 'end',
              position: 'top',
              labels: {
                boxWidth: 12,
                boxHeight: 12
              }
            }
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
            axis: 'x'
          }
        }
      } : undefined
    }
  }, [
    loading,
    categoryKey,
    valKeys,
    colorList,
    datasetLabels,
    isArrayOfObj,
    lineKey,
    lineLabel,
    value,
    assignLeftAxisForLine
  ]);
  useEffect(() => {
    if (!(isInitialRenderDone.current || initialFetchDone)) {
      isInitialRenderDone.current = true;
      dispatch(getDataAction(query));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (query !== queryRef.current) {
      queryRef.current = query;
      dispatch(getDataAction(query));
    }
  });
  useEffect(() => {
    if (memoizedData) {
      const chart = new Chart(canvasElement.current as HTMLCanvasElement, memoizedData);
      return () => chart.destroy();
    }
  }, [memoizedData]);
  useEffect(() => {
    isInitialRenderDone.current = true;
  }, []);
  return (
    <div
      className={`barTimeTrend position-relative w-100 h-100 ${
        barLabel ? (assignLeftAxisForLine ? 'pr-4' : 'pl-4') : ''} ${
        xLabel ? 'pb-4' : ''} ${
        lineLabel ? (assignLeftAxisForLine ? 'pl-4' : 'pr-4') : ''}`
      }
    >
      { !memoizedData && !loading  ? <NoDataTemplate icon={NoDataIcon} /> : null }
      {loading ?
        <div className="w-100 h-100 bg-white position-absolute z-index-2 top-0 left-0 d-flex align-items-center justify-content-center">
          <Spinner />
        </div> : null}
      <canvas ref={canvasElement} />
      { memoizedData && barLabel ?
          <div
            ref={yLabelRef}
            className={`${assignLeftAxisForLine ? 'customY1Label' : 'customYLabel'} d-flex align-items-center font-weight-bold text-center position-absolute`}
          >
            {barLabel}
          </div> : null }
        { memoizedData && lineLabel ?
          <div
            ref={lineLabelRef}
            className={`${assignLeftAxisForLine ? 'customYLabel' : 'customY1Label'} d-flex align-items-center font-weight-bold text-center position-absolute`}
          >
            <img className="lineIndicator" src={LineChartIcon} alt="" />
            {lineLabel}
          </div> : null }
        { memoizedData && xLabel ?
          <div
            ref={xLabelRef}
            className="customXLabel d-flex align-items-center font-weight-bold text-center position-absolute"
          >
            {xLabel}
          </div> : null }
    </div>
  );
}

export default StackedBarChart;
