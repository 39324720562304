import React, { ReactElement, RefObject } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import './Popover.scss';

interface ICustomPopoverProps {
  content?: string; // default it is set to the children of this HOC
  trigger?: 'hover' | 'click' | 'focus' | Array<'hover' | 'click' | 'focus'>; // default trigger is hover
  dark?: boolean; // Set true to enable dark theme
  maxWidth?: number; // 500px is default max width of popover
  contentClass?: string;  // class to be applied for the popover content
  renderOnOverflow?: boolean;
  isMultiLine?: boolean;
  children: ReactElement;
  disable?: boolean;
  delay?: number;
  placement?: 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start';
  hideArrow?: boolean;
  contentHeader?: string;
  headerClass?: string;
  popoverClass?: string;
  offsetConfig?: string;
}

/**
 * A HOC for that implements the popover for the child component
 * Renders the popover when the content overflows the element when max-width is specified.
 */
export default class CustomPopover extends React.Component<ICustomPopoverProps, {}> {
  private childRef: RefObject<HTMLDivElement> = React.createRef();

  public render() {
    const { children, trigger, delay, placement, offsetConfig } = this.props;
    return (
      <span className="custom-popover" ref={this.childRef}>
        <OverlayTrigger
          trigger={trigger}
          overlay={this.popoverRenderer}
          delay={delay || { show: 100, hide: 0 }}
          popperConfig={{
            modifiers: {
              // @ts-ignore
              preventOverflow: { enabled: true },
              // ~hide modifier when set to true passes a prop outOfBoundaries to the overlay component
              hide: { enabled: true },
              offset: {
                enabled: Boolean(offsetConfig),
                offset: offsetConfig
              }
            }
          }}
          placement={placement || 'auto'}
        >
          {children}
        </OverlayTrigger>
      </span>);
  }

  /**
   * To check if the content is overflowed, for both single line and multi line content.
   */
  private isOverFlowed = () => {
    const { renderOnOverflow, isMultiLine } = this.props;
    if (renderOnOverflow && this?.childRef?.current?.children?.length && this.childRef.current.children[0]) {
      const child = this.childRef.current.children[0];
      return isMultiLine ? child.scrollHeight > child.clientHeight
        : child.scrollWidth > child.clientWidth;
    }
    return true;
  }

  /**
   * Renders the popover
   * @param {any} tooltipProps props injected by the overlay trigger
   */
  private popoverRenderer = (tooltipProps: any) => {
    const { content, children, dark, maxWidth, contentClass, disable,
      hideArrow = false, contentHeader, headerClass, popoverClass } = this.props;
    // Props does not belong to the DOM are removed: Suppress warning
    const { outOfBoundaries, arrowProps, scheduleUpdate, ...rest } = tooltipProps;
    tooltipProps = { ...rest, show: rest.show.toString() };
    const { placement, show, style } = tooltipProps;
    // To hide the tooltip when its reference element is out of boundaries or hidden inside scroll of parent
    if (outOfBoundaries && show) {
      tooltipProps = { ...tooltipProps };
      tooltipProps.style = { ...style, visibility: 'hidden', pointerEvents: 'none'};
    }
    return (
      <div
        className={`${(this.isOverFlowed() && !disable) ? '' : 'd-none'} custom-tooltip bs-tooltip-${placement} ${popoverClass || ''}`}
        {...tooltipProps}
      >
        { hideArrow ? null : <div className={`arrow ${dark ? '' : 'light'}`} {...arrowProps}/> }
        <div
          className={`custom-tooltip-inner ${dark ? '' : 'light'} ${contentClass || ''}`}
          style={{ maxWidth: `${maxWidth || 500}px` }}
          data-testid="custom-tooltip"
        >
          {contentHeader ?
          <div className={`content-header ${headerClass || ''}`} data-testid="content-header" >
            {contentHeader}
          </div> : null}
          {content || children}
        </div>
      </div>
    );
  }
}
