import picker from 'daterangepicker';
import { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ArrowLeftIcon from '../../assets/image/arrow-left-black.svg';
import CalendarIcon from '../../assets/image/calendar.svg';

import 'daterangepicker/daterangepicker.css'
import './DatePicker.scss';

interface IDatePicker {
  onSelect: (date: number) => void;
  date?: number;
  className?: string;
  showTime?: boolean;
  minDate?: number;
  maxDate?: number;
  enableToggler?: boolean;
}

const DatePicker = ({ onSelect, className = '', date, minDate, maxDate, showTime, enableToggler }: IDatePicker) => {
  const pickerElementRef: RefObject<HTMLDivElement> = useRef(null);
  const pickerObjRef: MutableRefObject<picker | undefined> = useRef();
  const defaultDate = date || minDate || moment().startOf('month').valueOf();
  const [ val, setVal ] = useState(defaultDate);
  useEffect(() => setVal(defaultDate), [ defaultDate ])
  const displayFormat = showTime ? 'HH:mm DD MMM, yyyy' : 'DD MMM, yyyy';
  useEffect(() => {
    const pickerElement = pickerElementRef.current;
    if (pickerElement) {
      const pickerObj = new picker(pickerElement, {
        singleDatePicker: true,
        startDate: new Date(val),
        endDate: new Date(val),
        minDate: minDate ? new Date(minDate) : undefined,
        maxDate: maxDate ? new Date(maxDate) : undefined,
        opens: 'left',
        drops: 'auto'
      }, (date) => {
        const dateInMs = moment(date).valueOf();
        setVal(dateInMs);
        onSelect(dateInMs);
      });
      pickerObjRef.current = pickerObj;
      const scrollhost = document.getElementsByClassName('scrollhost')[0];
      scrollhost?.addEventListener('scroll', () => {
        pickerObj.container.hide('slow');
      });
      return () => {
        pickerObj.remove();
        scrollhost.removeEventListener('scroll', () => {
          pickerObj.container.hide('slow');
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onSelect, pickerElementRef, minDate, maxDate ]);

  const handleToggle = (e: React.MouseEvent, isPrev?: boolean) => {
    e.stopPropagation();
    const nxtDateInMS = new Date(val).setDate(new Date(val).getDate() + (isPrev ? -1 : 1));
    pickerObjRef.current?.setStartDate(new Date(nxtDateInMS));
    pickerObjRef.current?.setEndDate(new Date(nxtDateInMS));
    setVal(nxtDateInMS);
    onSelect(nxtDateInMS);
  }

  return (
    <div className={`datepickerWrapper d-flex ${enableToggler ? 'px-2' : ''}`}>
      <div
        ref={pickerElementRef}
        className={`datePicker py-1 fs-dot75 d-flex align-items-center ${className} ${enableToggler ? '' : 'cursor-pointer px-2'}`}
      >
        <div className={`d-flex align-items-center ${enableToggler ? 'cursor-pointer' : ''}`}>
          <img src={CalendarIcon} className="mr-1 calenderIcon" alt="" />
          { moment(val).format(displayFormat) }
        </div>
      </div>
      {enableToggler ?
        <>
          <img
            src={ArrowLeftIcon}
            alt="<"
            className={`ml-4 cursor-pointer ${moment(val).diff(moment(minDate), 'days') ? '' : 'disabledToggler'}`}
            onClick={(e) => handleToggle(e, true)}
          />
          <img
            src={ArrowLeftIcon}
            className={`rotate-180 ml-3 cursor-pointer ${moment(val).diff(moment(maxDate), 'days') ? '' : 'disabledToggler'}`}
            alt=">"
            onClick={(e) => handleToggle(e)}
          />
        </> : null }
    </div>
  );
}

export default DatePicker;
