import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDemoUserData } from 'custom-types';
import toast from 'react-hot-toast';

import { RootState } from '../../store/store';
import * as demoAPI  from './demoAPI';

export interface IDemoSlice {
  timeRemaining: number;
  creatingFreeSession: boolean;
  savingAnonymouserUserMail: boolean;
  extendingSession: boolean;
  creatingUser: boolean;
  canShowData: boolean;
}

export const createDemoUser = createAsyncThunk(
  'demo/createDemoUser',
  async (user: IDemoUserData) => {
    try {
      const { data } = await demoAPI.addDemoUser(user);
      return data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const extendUserSession = createAsyncThunk(
  'demo/extendUserSession',
  async (email: string) => {
    try {
      const { data } = await demoAPI.extendUserSession(email);
      return data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const createFreeSession = createAsyncThunk(
  'demo/createFreeSession',
  async () => {
    try {
      const { data } = await demoAPI.createFreeSession();
      return data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'Something went wrong');
    }
  }
)

export const saveAnonymousUserMail = createAsyncThunk(
  'demo/saveAnonymousUserMail',
  async (email: string) => {
    try {
      const { data } = await demoAPI.saveAnonymousUserMail(email);
      return data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'Something went wrong');
    }
  }
)

const initialState = {
  timeRemaining: 0,
  creatingFreeSession: false,
  savingAnonymouserUserMail: false,
  extendingSession: false,
  creatingUser: false,
  canShowdata: false
};

export const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    decrementTimeRemaining: (state) => {
      state.timeRemaining = state.timeRemaining - 1;
    },
    setTimeRemaining: (state, action: PayloadAction<number>) => {
      state.timeRemaining = action.payload;
    },
    setCanShowDemoData: (state, action) => {
      state.canShowdata = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createFreeSession.pending, (state) => {
        state.creatingFreeSession = true;
      })
      .addCase(createFreeSession.fulfilled, (state, action) => {
        state.creatingFreeSession = false;
      }).addCase(createFreeSession.rejected, (state, action) => {
        toast.error(action.error?.message || 'Something went wrong');
        state.creatingFreeSession = false;
      })

      .addCase(saveAnonymousUserMail.pending, (state) => {
        state.savingAnonymouserUserMail = true;
      })
      .addCase(saveAnonymousUserMail.fulfilled, (state, action) => {
        state.savingAnonymouserUserMail = false;
      }).addCase(saveAnonymousUserMail.rejected, (state, action) => {
        toast.error(action.error?.message || 'Something went wrong');
        state.savingAnonymouserUserMail = false;
      })

      .addCase(createDemoUser.pending, (state) => {
        state.creatingUser = true;
      })
      .addCase(createDemoUser.fulfilled, (state, action) => {
        state.creatingUser = false;
      }).addCase(createDemoUser.rejected, (state, action) => {
        toast.error(action.error?.message || 'Something went wrong');
        state.creatingUser = false;
      })

      .addCase(extendUserSession.pending, (state) => {
        state.extendingSession = true;
      })
      .addCase(extendUserSession.fulfilled, (state, action) => {
        state.extendingSession = false;
      }).addCase(extendUserSession.rejected, (state, action) => {
        toast.error(action.error?.message || 'Something went wrong');
        state.extendingSession = false;
      })
  },
});

export const { decrementTimeRemaining, setTimeRemaining, setCanShowDemoData } = demoSlice.actions;

export const selectTimeRemaining = (state: RootState) => state.demo.timeRemaining;
export const selectCreatingFreeSession = (state: RootState) => state.demo.creatingFreeSession;
export const selectExtendingSession = (state: RootState) => state.demo.extendingSession;
export const selectCreatingUser = (state: RootState) => state.demo.creatingUser;
export const selectCanShowData = (state: RootState) => state.demo.canShowdata;

export default demoSlice.reducer;
 