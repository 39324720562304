import axios, { AxiosResponse, AxiosError } from 'axios';
import { createDemoUserAPI, extendSessionAPI, freeSessionAPI, loginAPIPath } from './constants/appConstants';

export const setupInterceptors = (store: any) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  const isDemo = window.location.pathname.startsWith('/demo');
  if (localStorage.getItem(isDemo ? 'dtoken' : 'token')) {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(isDemo ? 'dtoken' : 'token')}`;
  }
  axios.interceptors.request.use(config => {
    if (config.baseURL === process.env.REACT_APP_API_URL) {
      config.withCredentials = true;
    }
    return config;
  }, error => Promise.reject(error));
  axios.interceptors.response.use((response: AxiosResponse) => {
    if (response.config.url === loginAPIPath) {
      if (response.data.token) {
        /* To add the authorization token on successful login */
        axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
        // axios.defaults.headers.c['Authorization'] = `Bearer ${response.data.token}`;
        localStorage.setItem('token', response.data.token);
      }
    } else if (
      response.config.url === freeSessionAPI ||
      response.config.url === createDemoUserAPI ||  
      response.config.url === extendSessionAPI  
    ) {
      if (response.data.access_token) {
        /* To add the authorization token on successful login */
        axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
        // axios.defaults.headers.c['Authorization'] = `Bearer ${response.data.token}`;
        localStorage.setItem('dtoken', response.data.access_token);
      }
    }
    return response;
  }, (error: AxiosError) => {
    throw error;
  });
};
