import { ICommonQuery } from 'custom-types';
import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import QueryExplorer from './QueryExplorer';
import QueryTrend from './QueryTrend';

import ComparitiveInsight, { dataCateogory, IComparitiveInsight } from '../../components/ComparitiveInsight';
import DateRangePicker from '../../components/DateRangePicker';
import Spinner from '../../components/Spinner';
import { Insights } from '../../components/Insights';
import MultiSelect, { IOption } from '../../components/Select/MultiSelect';
import { selectDashboardMeta } from '../../slice/app';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  clearReportsData,
  selectEndDate,
  selectStartDate,
  setDateRange,
  selectMaxExecTime,
  selectMedianExecTime,
  selectQueryCount,
  fetchComparitiveQueryInsights,
  dismissInsights,
  selectIsInsightsDismissed,
  selectIsInsightsCollapsed,
  toggleInsightsCollapsedState,
  selectSelectedTab,
  selectTab,
  tabs,
  selectSelectedUsers,
  selectSelectedWarehouses,
  setSelectedUsers,
  setSelectedWarehouses
} from '../../slice/performanceMetrics';
import filterIcon from '../../assets/image/filter.svg';
import userIcon from '../../assets/image/user-blue.svg';
import warehouseIcon from '../../assets/image/warehouse-blue.svg';

import './Performance.scss';
import {
  fetchHigherUsageuserInsight,
  fetchHigherUsageusers,
  fetchOverloadWhInsight,
  fetchOverloadWHQueries,
  IInsight,
  selectHigherUsageUserInsights,
  selectHigherUsageUsers,
  selectOverloadWHInsight,
  selectOverloadWHQueries
} from '../../slice/costMetrics';
import { formatByType } from '../../utils/appUtils';
import { infoType } from '../../constants/appConstants';

Chart.register(...registerables);
Chart.defaults.font.family = 'Mulish';
Chart.defaults.font.size = 12;

const Performance = () => {
  const initialRenderDone: MutableRefObject<boolean> = useRef(false);

  const dispatch = useAppDispatch();

  // const defaultCostPerCredit = 3.75;
  // const [costPerCredit, setCostPerCredit] = useState(defaultCostPerCredit);
  // const onCostPerCreditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (!/^\d*\.?\d*$/.test(e.target.value)) {
  //     e.preventDefault();
  //     return;
  //   }
  //   setCostPerCredit(e.target.value as unknown as number)
  // }
  // const onCostPerCreditBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (!Number(e.target.value)) {
  //     setCostPerCredit(defaultCostPerCredit)
  //   }
  // }

  const {
    value: medianExecTime,
    loading: medianExecTimeLoading
  } = useAppSelector(selectMedianExecTime);
  const {
    value: maxExecTime,
    loading: maxExecTimeLoading
  } = useAppSelector(selectMaxExecTime);
  const {
    value: queryCount,
    loading: queryCountLoading,
    initialFetchDone: queryCountFetchedOnce
  } = useAppSelector(selectQueryCount);

  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const fetchAllReportData = useCallback((option?: Partial<ICommonQuery>, onlyCostReport?: boolean) => {
    const {
      startDate: nxtStartDate, endDate: nxtEndDate,
      /* users: nxtSelectedUsers, warehouses: nxtSelectedWarehouses */
    } = option || {};
    const query = {
      startDate: nxtStartDate || startDate,
      endDate: nxtEndDate || endDate,
      // users: users?.filter((user: string) => !(nxtSelectedUsers || selectedUsers).includes(user)),
      // warehouses: warehouses?.filter((warehouse: string) => !(nxtSelectedWarehouses || selectedWarehouses).includes(warehouse))
    };

    dispatch(fetchComparitiveQueryInsights(query));
  }, [
    startDate, endDate, dispatch,
    /* selectedUsers, users, warehouses, selectedWarehouses */
  ]);

  const handleDaterangeSelect = useCallback(([startDate, endDate]) => {
    dispatch(clearReportsData());
    fetchAllReportData({ startDate, endDate })
    dispatch(setDateRange({ startDate, endDate }));
  }, [dispatch, fetchAllReportData]);

  const days = moment(endDate).diff(startDate, 'days') + 1;
  const diffPeriod = `${moment(startDate).subtract(days, 'days').format('D MMM, yy')} - ${moment(endDate).subtract(days, 'days').format('D MMM, yy')}`;
  const comparitiveInsights = useMemo(() => ({
    loading: (
      medianExecTimeLoading ||
      maxExecTimeLoading ||
      queryCountLoading
    ),
    data: [
      {
        title: 'Median execution time',
        dataCateogory: 'time' as dataCateogory,
        diffPercent: medianExecTime?.cardsValue?.comparedPercent || 0,
        value: medianExecTime?.cardsValue?.medianExecutionTime || 0,
        diffPeriod,
        lineChartData: medianExecTime?.queryTrendData?.medianExeTime
      },
      {
        title: 'Max. execution time',
        dataCateogory: 'time' as dataCateogory,
        diffPercent: maxExecTime?.cardsValue?.comparedPercent || 0,
        value: maxExecTime?.cardsValue?.maximumExecutionTime || 0,
        diffPeriod,
        lineChartData: maxExecTime?.queryTrendData?.maxExeTime
      },
      {
        title: 'Query count',
        dataCateogory: 'number' as dataCateogory,
        diffPercent: queryCount?.cardsValue?.comparedPercent || 0,
        value: queryCount?.cardsValue?.queryCount || 0,
        diffPeriod,
        lineChartData: queryCount?.queryTrendData?.queryCount
      },
    ]
  }), [
    queryCount,
    maxExecTime,
    medianExecTime,
    queryCountLoading,
    maxExecTimeLoading,
    medianExecTimeLoading,
    diffPeriod
  ]);

  const selectedTab = useAppSelector(selectSelectedTab);
  const switchTab = useCallback(() => {
    dispatch(selectTab(selectedTab === tabs.queryTrend ? tabs.queryExplorer : tabs.queryTrend));
  }, [dispatch, selectedTab ]);

  const { warehouses, users, loading: loadingDropdownData } = useAppSelector(selectDashboardMeta);

  const selectedUsers = useAppSelector(selectSelectedUsers);
  const selectedUsersOptions = useMemo(() => (
    selectedUsers.map((user: string) => ({ value: user, label: user }))
  ), [selectedUsers]);
  const userOptions = useMemo(() => (
    (users || []).map((user: string) => ({ value: user, label: user }))
  ), [users]);

  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const selectedWarehouseOptions = useMemo(() => (
    selectedWarehouses.map((warehouse: string) => ({ value: warehouse, label: warehouse }))
  ), [selectedWarehouses]);
  const warehouseOptions = useMemo(() => (
    (warehouses || []).map((warehouse: string) => ({ value: warehouse, label: warehouse }))
  ), [warehouses]);

  const handleUserFilterChange = useCallback((userOptions: IOption[]) => {
    const users = userOptions.map(({ value }) => value);
    dispatch(clearReportsData());
    dispatch(setSelectedUsers(users));
  }, [dispatch]);

  const handleWarehouseFilterChange = useCallback((warehouseOptions: IOption[]) => {
    const warehouses = warehouseOptions.map(({ value }) => value);
    dispatch(clearReportsData());
    dispatch(setSelectedWarehouses(warehouses));
  }, [dispatch]);

  // Initial data fetching is done by this effect
  // Checks if cached data is available,
  // if the data is available prevents the fetch action
  useEffect(() => {
    if (!initialRenderDone.current) {
      initialRenderDone.current = true;
      const query = { startDate, endDate };

      if (!queryCountFetchedOnce) {
        dispatch(fetchComparitiveQueryInsights(query));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const excludedUsersStr: string = (users?.filter((user: string) => !selectedUsers.includes(user)) || []).sort().join(',');
  const excludedWarehousesStr: string = (warehouses?.filter((warehouse: string) => !selectedWarehouses.includes(warehouse)) || []).sort().join(',');

  const memoizedQuery =  useMemo(() => {
    const excludedUsers = excludedUsersStr.split(',').filter(val => val);
    const excludedWarehouses = excludedWarehousesStr.split(',').filter(val => val);
    return {
      startDate,
      endDate,
      users: excludedUsers.length ? excludedUsers : undefined,
      warehouses: excludedWarehouses.length ? excludedWarehouses : undefined
    };
  }, [startDate, endDate, excludedWarehousesStr, excludedUsersStr]);

  const { insights, insightsModalData, isInsightsDismissed, isInsightsCollapsed, loading: loadingInsights } = useInsights();

  return (
    <div className="container-fluid">
      <Insights
        insightsModalData={insightsModalData}
        insights={insights}
        loading={loadingInsights}
        dismissAction={dismissInsights}
        collapseAction={toggleInsightsCollapsedState}
        isInsightsDismissed={isInsightsDismissed}
        isInsightsCollapsed={isInsightsCollapsed}
      />
      <div className="row filters mb-0 px-lg-9 px-md-5 px-4 py-dot75">
        <div className="col-md-auto mr-md-auto px-0 text-primary d-flex align-items-center font-weight-bold lh-1dot25">
          <img src={filterIcon} alt="filter" className="mr-1 w-1 h-1" />
          Filters
        </div>
        {/* <div className="col-md-auto col-6 d-flex align-items-center flex-row-reverse ml-md-auto px-0">
          <div className="position-absolute dollarSymbol">$</div>
          <input
            className="text-right py-dot375 pl-3 pr-2 lh-1dot125 no-outline costPerCreditInput filterInputBorder"
            value={costPerCredit}
            onChange={onCostPerCreditChange}
            onBlur={onCostPerCreditBlur}
          />
          <span className="filterLabel fs-dot75 mr-2">Cost per credit</span>
        </div> */}
        <div className="filterMob col-md-auto col-6 ml-md-dot75 px-0">
          <MultiSelect
            className="userSelect"
            value={selectedUsersOptions}
            onChange={handleUserFilterChange as any}
            options={userOptions}
            isMulti={true}
            subject="user"
            subjectPlural="users"
            loading={loadingDropdownData}
            icon={userIcon}
          />
        </div>
        <div className="filterMob col-md-auto col-6 px-0 ml-md-dot75">
          <MultiSelect
            className="userSelect"
            value={selectedWarehouseOptions}
            onChange={handleWarehouseFilterChange as any}
            options={warehouseOptions}
            isMulti={true}
            subject="warehouse"
            subjectPlural="warehouses"
            loading={loadingDropdownData}
            icon={warehouseIcon}
            iconWidth={20}
          />
        </div>
        <div className="col-auto d-flex align-items-center pl-0 ml-md-dot75 ml-auto mr-md-0 mr-auto mt-3 mt-md-0">
          <DateRangePicker
            onSelect={handleDaterangeSelect}
            className="py-dot375 px-2 lh-1dot125"
            startDate={startDate}
            endDate={endDate}
            enablePresets={true}
            maxDate={moment().endOf('day').valueOf()}
          />
        </div>
      </div>
      <div
        className={`row mb-0 px-lg-9 px-md-5 px-4 \
          ${comparitiveInsights.loading || !comparitiveInsights.data.length ?
              'justify-content-center align-items-center loadingComparitivInsight fs-dot75'
              : 'justify-content-around py-dot75'
          }`}
      >
        {comparitiveInsights.loading ? <Spinner /> : null}
        {!comparitiveInsights.loading && !comparitiveInsights.data.length ? 'Info not available' : null}
        {!comparitiveInsights.loading && comparitiveInsights.data.length ? comparitiveInsights.data.map((insightData: IComparitiveInsight, i: number) => (
          <ComparitiveInsight {...insightData} key={`comp-insight-${i}`} />
        )) : null}
      </div>
      <div className="mild-background row justify-content-start mb-0 py-3 px-lg-9 px-md-5 px-4">
        <div className="col-12 mb-0 d-flex align-items-center px-dot75">
          <div
            onClick={selectedTab === tabs.queryTrend ? undefined : switchTab}
            className={`performanceTab cursor-pointer ${selectedTab === tabs.queryTrend ? 'text-primary font-weight-bold active' : ''} d-flex flex-column align-items-center `}
          >
            {tabs.queryTrend}
          </div>
          <div
            onClick={selectedTab === tabs.queryExplorer ? undefined : switchTab}
            className={`performanceTab cursor-pointer ${selectedTab === tabs.queryExplorer ? 'text-primary font-weight-bold active' : ''} d-flex flex-column align-items-center ml-1`}
          >
            {tabs.queryExplorer}
          </div>
        </div>
        {selectedTab === tabs.queryTrend ?
          <QueryTrend
            startDate={startDate}
            endDate={endDate}
            query={memoizedQuery}
          /> :
          <QueryExplorer
            startDate={startDate}
            endDate={endDate}
            query={memoizedQuery}
          />
        }
      </div>
    </div>
  );
}

export default Performance;

const useInsights = () => {
  const dispatch = useAppDispatch();
  const isInsightsDismissed = useAppSelector(selectIsInsightsDismissed);
  const isInsightsCollapsed = useAppSelector(selectIsInsightsCollapsed);
  const {
    value: higherUsageUserInsights,
    loading: loadingHigherUsageUserInsights,
    initialFetchDone: higherUsageUserInsightsFetchedOnce
  } = useAppSelector(selectHigherUsageUserInsights);
  const {
    value: overloadWHInsight,
    loading: loadingOverloadWHInsight,
    initialFetchDone: overloadWHInsightFetchedOnce
  } = useAppSelector(selectOverloadWHInsight);

  const { insights, insightsModalData }: {insights: IInsight[]; insightsModalData: Array<any>} = useMemo(() => {
    const insights = [];
    const insightsModalData = [];
    const columns = [
      { field: 'QUERY_ID', headerName: 'Query ID', resizable: true, filter: true, floatingFilter: false, isVisible: true },
      { field: 'USER_NAME', headerName: 'User', resizable: true, filter: true, floatingFilter: false, isVisible: true },
      { field: 'WAREHOUSE_NAME', headerName: 'Warehouse', resizable: true, filter: true, floatingFilter: false, isVisible: true },
      { field: 'QUERY_TEXT', headerName: 'Query', resizable: false, filter: true, floatingFilter: false, isVisible: true },
      { field: 'EXECUTION_TIME', headerName: 'Execution Time (Sec)', resizable: true, filter: true, floatingFilter: false, isVisible: true },
      { field: 'START_TIME', headerName: 'Query Started At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true, valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) },
      { field: 'END_TIME', headerName: 'Query Ended At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true , valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) }
    ];
    const dataFormatter = (data: any) => data;
    if (higherUsageUserInsights) {
      insights.push(higherUsageUserInsights);
      insightsModalData.push({
        dataSelector: selectHigherUsageUsers,
        getDataAction: fetchHigherUsageusers,
        columns,
        dataFormatter
      });
    }
    if (overloadWHInsight) {
      insights.push(overloadWHInsight);
      insightsModalData.push({
        dataSelector: selectOverloadWHQueries,
        getDataAction: fetchOverloadWHQueries,
        columns,
        dataFormatter
      });
    }
    return { insights, insightsModalData };
  }, [higherUsageUserInsights, overloadWHInsight]);
  useEffect(() => {
    if (!higherUsageUserInsightsFetchedOnce) {
      dispatch(fetchHigherUsageuserInsight());
    }
    if (!overloadWHInsightFetchedOnce) {
      dispatch(fetchOverloadWhInsight());
    }
  }, [
    dispatch,
    higherUsageUserInsightsFetchedOnce,
    overloadWHInsightFetchedOnce
  ]);
  return {
    insightsModalData,
    insights,
    isInsightsDismissed,
    isInsightsCollapsed,
    loading: loadingHigherUsageUserInsights ||
      loadingOverloadWHInsight
  };
};
