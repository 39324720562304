import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import { Chart } from 'chart.js';
import { compactInteger, formatNumber } from 'humanize-plus';

import './ComparitiveInsight.scss';

export type dataCateogory = "number" | "time" | "dollar";

export interface IComparitiveInsight {
  title: string;
  value: number;
  diffPercent: number;
  timePeriod?: number;
  lineChartData?: number[];
  diffPeriod: string;
  dataCateogory: dataCateogory;
}

const ComparitiveInsight = ({
  title, value, lineChartData, diffPercent, diffPeriod, dataCateogory
}: IComparitiveInsight) => {
  let isLineChartDataValid = false;
  const validatedLineChartData = lineChartData?.map((pointVal: number) => {
    if (!isLineChartDataValid && lineChartData?.length > 1 && !isNaN(pointVal)) {
      isLineChartDataValid = true;
    }
    return pointVal ?? null
  }) || [];
  const canvasElement: RefObject<HTMLCanvasElement> = useRef(null);
  const comparisionKey = diffPercent < 0 ? 'down' : (diffPercent > 0 ? 'up' : 'tie');
  const comparisionMeta = useMemo(() => ({
    up: {
      descriptor: (diff: number, period: string) => `${Number(Math.abs(diff).toFixed(0))}% increase compared to ${period}`,
      className: 'text-danger',
      color: '#FF4B4B'
    },
    down: {
      descriptor: (diff: number, period: string) => `${Number(Math.abs(diff).toFixed(0))}% decrease compared to ${period}`,
      className: 'text-success',
      color: '#00A638'
    },
    tie: {
      descriptor: (diff: number, period: string) => `same as ${period}`,
      className: 'text-tie',
      color: '#4C5862'
    }
  }), []);
  // var gradient = Chart.createLinearGradient(0, 0, 0, 400);
  // gradient.addColorStop(0, 'rgba(250,174,50,1)');   
  // gradient.addColorStop(1, 'rgba(250,174,50,0)');
  const data = useMemo(() => isLineChartDataValid && validatedLineChartData.length ? {
    type: "line" as any,
    data: {
      labels: validatedLineChartData.map((a: any, i: number) => String(i)),
      datasets: [
        {
          data: validatedLineChartData,
          borderColor: comparisionMeta[comparisionKey].color,
          backgoundColor: 'red',
          fill: false
        }
      ]
    },
    options: {
      backgroundColor: "#fff",
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: validatedLineChartData.map((value: number, index: number) => index === validatedLineChartData.length - 1 ? 2 : 0),
        },
        line: {
          borderWidth: 2,
          borderColor: comparisionMeta[comparisionKey].color,
          borderJoinStyle: 'round',
          tension: 0.5
        }
      },
      scales: {
        y: {
          display: false,
          max: Math.max(...validatedLineChartData) + Math.max(...(validatedLineChartData)) / 2,
        },
        x: { display: false }
      },
      plugins: {
        legend: { display: false },
        tooltip: { enabled: false }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } : null, [ comparisionKey, isLineChartDataValid, comparisionMeta]);

  useEffect(() => {
    if (data) {
      const chart = new Chart(
        canvasElement.current as HTMLCanvasElement,
        data
      );
      return () => chart.destroy();
    }
  }, [ title, value, diffPercent, data ]);
  
  return (
    <div className="comparitiveInsight m-1 py-3">
      <div className="fs-dot875 text-center lh-1dot125 font-weight-semibold mb-dot375 no-wrap">{title}</div>
      <div className="fs-1dot5 text-center lh-1dot875 mb-dot375">{formatValue(dataCateogory, value)}</div>
      <div className="comparisionInfo d-flex px-dot75">
        <div className={`trendGraph pr-2 ${isLineChartDataValid ? '' : 'd-none'}`}>
          <canvas ref={canvasElement} />
        </div>
        <div
          className={`text-left fs-dot75 lh-1dot125 ${
            comparisionMeta[comparisionKey].className} ${isLineChartDataValid ? 'float-right' : ''}`
          }
        >
          { comparisionMeta[comparisionKey].descriptor(diffPercent, diffPeriod) }
        </div>
      </div>
    </div>
  );
}

const formatValue = (dataCateogory: dataCateogory, value: number = 0) => {
  switch (dataCateogory) {
    case 'dollar':
      return `$${value > 99999 ? compactInteger(value, 3) : formatNumber(Number(value.toFixed(2)))}`;
    case 'time':
      const hours = Math.floor(value / (60 * 60));
      const minutes = Math.floor((value - hours * 60 * 60) / 60);
      let seconds = hours || minutes ? value - hours * 60 * 60 - minutes * 60 : (value - hours * 60 * 60 - minutes * 60);
      seconds = Number(seconds.toFixed(2));
      let ms;
      if (!(hours || minutes || seconds)) { 
        return 0;
      }
      if (!hours && !minutes && seconds && seconds < 1) {
        ms = seconds * 1000;
        seconds = 0;
      }
      return <span>{<>
        { hours ?
          <span>{hours}<span className="fs-dot875">hr{ hours > 1 ? 's' : ''}</span>&nbsp;</span> : null }
        { minutes ?
          <span>{minutes}<span className="fs-dot875">min{ minutes > 1 ? 's' : ''}</span>&nbsp;</span> : null }
        { seconds ?
          <span>{seconds}<span className="fs-dot875">sec{ seconds > 1 ? 's' : ''}</span></span> : null }
        { ms ?
          <span>{ms}<span className="fs-dot875">ms</span></span> : null }
      </>}</span>
    default:
      return value > 99999 ? compactInteger(value, 3) : formatNumber(Number(value.toFixed(2)));
  }
}

export default ComparitiveInsight;
