import axios from "axios";
import { ICommonQuery } from "custom-types";
import { constructCommonQuery } from "../../utils/appUtils";

export const fetchQueryTrendByDate = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queryTrendByDate?${constructCommonQuery(queryObj)}`
});

export const fetchQueryTrendByWeekday = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queryTrendByWeekday?${constructCommonQuery(queryObj)}`
});

export const fetchQueryCountByShiftHours = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queryCountByShiftHours?${constructCommonQuery(queryObj)}`
});

export const fetchQueryCountByUsers = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queryCountByUsers?${constructCommonQuery(queryObj)}`
});

export const fetchQueryCountByWarehouseName = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queryCountByWarehouseNameWithCr?${constructCommonQuery(queryObj)}`
});

export const fetchQueryCountByWarehouseSize = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queryCountByWarehouseSizeWithCr?${constructCommonQuery(queryObj)}`
});


export const fetchQueryCountVsUsers = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queryCountVsNumOfUser?${constructCommonQuery(queryObj)}`
});

export const fetchQueriesBiggerThanNxtWarehouse = (queryObj: ICommonQuery) => axios.all([
  axios({
    method: 'get',
    url: `/performance/queriesBiggerNxWhExeTime?${constructCommonQuery(queryObj)}`
  }),
  axios({
    method: 'get',
    url: `/performance/queriesBiggerNxWhOnByteScanned?${constructCommonQuery(queryObj)}`
  }),
  axios({
    method: 'get',
    url: `/performance/queriesBiggerNxWhExeAndOnByteScanned?${constructCommonQuery(queryObj)}`
  })
]);

export const fetchQuerySizeVsExecutionTime = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/querySizeVsExecutionTime?${constructCommonQuery(queryObj)}`
});

export const fetchAverageExecutionTimeByWarehouseName = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/avgExecutionTimeByWarehouseName?${constructCommonQuery(queryObj)}`
});

export const fetchAverageExecutionTimeByWarehouseSize = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/avgExecutionTimeByWarehouseSize?${constructCommonQuery(queryObj)}`
});

export const fetchAllUsers = () => axios({
  method: 'get',
  url: `/performance/queryCountByUsers?startDate=4/08/2020 00:00&endDate=4/08/2027 00: 00`
});

export const fetchAllWarehouses = () => axios({
  method: 'get',
  url: '/performance/queryCountByWarehouseNameWithCr?startDate=4/08/2020 00:00&endDate=4/08/2027 00:00'
});

export const fetchBiggerQueriesForExeTime = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queriesBiggerNxWhExeTime-details?${constructCommonQuery(queryObj, true)}&limit=null`
});

export const fetchBiggerQueriesForByteScanned = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queriesBiggerNxWhOnByteScanned-details?${constructCommonQuery(queryObj, true)}&limit=null`
});

export const fetchBiggerQueriesForExeTimeAndOnByteScanned = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/performance/queriesBiggerNxWhExeAndOnByteScanned-details?${constructCommonQuery(queryObj, true)}&limit=null`
});

export const fetchComparitiveQueryInsights = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/performance-query-report?${constructCommonQuery(queryObj)}`
});
