import { ICommonQuery } from 'custom-types';
import { Chart, registerables } from 'chart.js';
import React, { useEffect, useRef, useState, MutableRefObject, useMemo, useCallback } from 'react';
import moment from 'moment';

import ComparitiveInsight, { IComparitiveInsight, dataCateogory } from '../../components/ComparitiveInsight';
import { Insights } from '../../components/Insights';
import ReportLayout from '../../components/ReportLayout';
import DateRangePicker from '../../components/DateRangePicker';
import MultiSelect, { IOption } from '../../components/Select/MultiSelect';
import Spinner from '../../components/Spinner';
import BarChart from '../../components/Chart/BarChart';
import ScatterPlot from '../../components/Chart/ScatterPlot';
import BarTimeTrend from '../../components/Chart/BarTimeTrend';
import DonutChart from '../../components/Chart/DonutChart';
import StackedBarChart from '../../components/Chart/StackedBarChart';
import TableReport from '../../components/TableReport';
import { PROTECTED_ROUTES } from '../../constants/route';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { infoType } from '../../constants/appConstants';
import { selectDashboardMeta } from '../../slice/app';
import {
  fetchComparitiveCostInsights,
  fetchTopUsersByUsageCost,
  fetchTopDatesByUsageCost,
  selectTotalUsageCost,
  selectTopDatesByUsageCost,
  selectTopUsersByUsageCost,
  selectTopWarehouseByUsageCost,
  fetchTopWarehouseByUsageCost,
  selectAverageDailyCost,
  fetchWarehouseSizeVsUsageCost,
  selectWarehouseSizeVsUsageCost,
  selectStartDate,
  selectEndDate,
  setDateRange,
  selectUsageCostByWarehouseToggler,
  usageCostByWarehouseTogglerList,
  setUsageCostByWarehouseToggler,
  selectCostPerCredit,
  setCostPerCredit,
  defaultCostPerCredit,
  setSelectedUsers,
  selectSelectedUsers,
  selectSelectedWarehouses,
  setSelectedWarehouses,
  clearCostMetricsReports,
  selectHigherUsageUserInsights,
  selectComplexQueriesInsight,
  fetchComplexQueriesInsight,
  fetchHigherUsageuserInsight,
  IInsight,
  selectOverloadWHInsight,
  fetchOverloadWhInsight,
  selectIsInsightsDismissed,
  dismissInsights,
  selectIsInsightsCollapsed,
  toggleInsightsCollapsedState,
  selectComplexQueries,
  fetchComplexQueries,
  selectHigherUsageUsers,
  fetchHigherUsageusers,
  selectOverloadWHQueries,
  fetchOverloadWHQueries
} from '../../slice/costMetrics';
import {
  fetchComparitiveQueryInsights,
  fetchQuerySizeVsTime,
  fetchQueryTimeTrend,
  fetchQueryTimeVsWarehouseSize,
  fetchTopQueriesByCostAndTime,
  selectMaxExecTime,
  selectMedianExecTime,
  selectQueryCount,
  selectQuerySizeVsTime,
  selectQueryTimeTrend,
  selectQueryTimeVsWarehouseSize,
  selectTopQueriesByCostAndTime,
  clearQueryMetricsReports,
  ITopQueriesByCostAndTime
} from '../../slice/queryMetrics';
import {
  formatByType,
  formatCost,
  formatPercentage,
} from '../../utils/appUtils';
import filterIcon from '../../assets/image/filter.svg';
import userIcon from '../../assets/image/user-blue.svg';
import warehouseIcon from '../../assets/image/warehouse-blue.svg';

import './Overview.scss';

Chart.register(...registerables);
Chart.defaults.font.family = 'Mulish';
Chart.defaults.font.size = 12;

const Overview = () => {
  const dispatch = useAppDispatch();
  const costPerCredit = useAppSelector(selectCostPerCredit);

  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  // Dropdown data control
  const { warehouses, users, loading: loadingDropdownData } = useAppSelector(selectDashboardMeta);

  const selectedUsers = useAppSelector(selectSelectedUsers);
  const selectedUsersOptions = useMemo(() => (
    selectedUsers.map((user: string) => ({ value: user, label: user }))
  ), [selectedUsers]);
  const userOptions = useMemo(() => (
    (users || []).map((user: string) => ({ value: user, label: user }))
  ), [users]);

  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const selectedWarehouseOptions = useMemo(() => (
    selectedWarehouses.map((warehouse: string) => ({ value: warehouse, label: warehouse }))
  ), [selectedWarehouses]);
  const warehouseOptions = useMemo(() => (
    (warehouses || []).map((warehouse: string) => ({ value: warehouse, label: warehouse }))
  ), [warehouses]);

  const usageCostByWarehouseToggler = useAppSelector(selectUsageCostByWarehouseToggler);
  const handleUsageCostByWarehouseToggle = useCallback((nxtToggler) => {
    dispatch(setUsageCostByWarehouseToggler(nxtToggler));
  }, [dispatch]);

  const excludedUsersStr: string = (users?.filter((user: string) => !selectedUsers.includes(user)) || []).sort().join(',');
  const excludedWarehousesStr: string = (warehouses?.filter((warehouse: string) => !selectedWarehouses.includes(warehouse)) || []).sort().join(',');

  const memoizedQuery = useMemo(() => {
    const excludedUsers = excludedUsersStr.split(',').filter(val => val);
    const excludedWarehouses = excludedWarehousesStr.split(',').filter(val => val);
    return {
      costPerCredit: Number(costPerCredit),
      startDate,
      endDate,
      users: excludedUsers.length ? excludedUsers : undefined,
      warehouses: excludedWarehouses.length ? excludedWarehouses : undefined
    };
  }, [costPerCredit, startDate, endDate, excludedWarehousesStr, excludedUsersStr]);
  const memoizedQueryWithoutCost = useMemo(() => {
    const excludedUsers = excludedUsersStr.split(',').filter(val => val);
    const excludedWarehouses = excludedWarehousesStr.split(',').filter(val => val);
    return {
      startDate, endDate,
      users: excludedUsers.length ? excludedUsers : undefined,
      warehouses: excludedWarehouses.length ? excludedWarehouses : undefined
    };
  }, [startDate, endDate, excludedUsersStr, excludedWarehousesStr ]);

  const handleUserFilterChange = useCallback((userOptions: IOption[]) => {
    const users = userOptions.map(({ value }) => value);
    dispatch(clearCostMetricsReports());
    dispatch(clearQueryMetricsReports());
    dispatch(setSelectedUsers(users));
  }, [dispatch]);

  const handleWarehouseFilterChange = useCallback((warehouseOptions: IOption[]) => {
    const warehouses = warehouseOptions.map(({ value }) => value);
    dispatch(clearCostMetricsReports());
    dispatch(clearQueryMetricsReports());
    dispatch(setSelectedWarehouses(warehouses));
  }, [dispatch]);

  // Handler for date range select event
  const handleDateRangeChange = useCallback(([startDate, endDate]) => {
    dispatch(clearCostMetricsReports());
    dispatch(clearQueryMetricsReports());
    dispatch(setDateRange({ startDate, endDate }));
  }, [dispatch]);

  const [tempCostPerCredit, setTempCostPerCredit] = useState(costPerCredit);
  const onCostPerCreditChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const nxtCostPerCredit = e.target.value;
    if (!/^\d*\.?\d*$/.test(nxtCostPerCredit)) {
      e.preventDefault();
      return false;
    }
    setTempCostPerCredit(nxtCostPerCredit as unknown as number);
  }, []);

  const onCostPerCreditBlur = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let nxtCostPerCredit = Number(e.target.value);
    if (!nxtCostPerCredit) {
      nxtCostPerCredit = defaultCostPerCredit;
      setTempCostPerCredit(nxtCostPerCredit);
    }
    if (costPerCredit !== nxtCostPerCredit) {
      dispatch(clearCostMetricsReports());
      dispatch(setCostPerCredit(nxtCostPerCredit));
    }
  }, [dispatch, costPerCredit]);

  const topQueriesByUsageAndTimeParser = useCallback((topQueriesByUsageAndTime: ITopQueriesByCostAndTime) => {
    const {
      queryId = [], userId = [], warehouseName = [], executionTime = [], usageCost = [], query = []
    } = topQueriesByUsageAndTime || {};
    return queryId.map((id: number, i: number) => ({
      queryId: id, userId: userId[i], warehouseName: warehouseName[i], executionTime: executionTime[i], usageCost: usageCost[i], query: query[i]
    }));
  }, []);

  const allColumns = useMemo(() => [
    { field: 'queryId', headerName: 'Query ID', sortable: true, resizable: true, floatingFilter: false, isVisible: true, filter: 'agTextColumnFilter' },
    { field: 'userId', headerName: 'User ID', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'warehouseName', headerName: 'Warehouse', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'executionTime', type: 'numberColumn', headerName: 'Execution Time (Sec)', sortable: true, resizable: true, floatingFilter: false, isVisible: true, valueFormatter: ({ value }: any) => Number(Number(value).toFixed(1)), filter: 'agNumberColumnFilter' },
    { field: 'usageCost', type: 'numberColumn', headerName: 'Usage Cost', sortable: true, resizable: false, floatingFilter: false, isVisible: true, valueFormatter: ({ value }: any) => formatCost(Number(Number(value).toFixed(2))), filter: 'agNumberColumnFilter' },
    { field: 'query', headerName: 'Query', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'startTime', headerName: 'Query Started At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true, valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) },
    { field: 'endtime', headerName: 'Query Ended At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true , valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) }
  ], []);

  // Navigation handlers
  const warehousePageLinkFormatter = useCallback((warehouse: string) => ({
    pathname: PROTECTED_ROUTES.warehouse,
    state: { startDate, endDate, selectedWarehouse: warehouse }
  }), [startDate, endDate]);

  const sizePageLinkFormatter = useCallback((warehouseSize: string) => ({
    pathname: PROTECTED_ROUTES.warehouseSize,
    state: { startDate, endDate, selectedWarehouseSize: warehouseSize }
  }), [startDate, endDate]);

  const datePageLinkFormatter = useCallback((date: string) => ({
    pathname: PROTECTED_ROUTES.date,
    state: {
      startDate: moment(date).startOf('day').valueOf(),
      endDate: moment(date).endOf('day').valueOf()
    }
  }), []);

  const userLinkFormatter = useCallback((user: string) => ({
    pathname: PROTECTED_ROUTES.user,
    state: { startDate, endDate, selectedUser: user }
  }), [startDate, endDate]);

  const queryTimeVsWarehouseSizeMeta = useMemo(() => ({
    datasetLabels: ['Avg query wait time', 'Avg query execution time (sec)'],
    valKeys: ['queryWaitTime', 'queryExecutionTime'],
    colors: ['#FC7D58', '#DE81FF']
  }), []);

  const donutLegends = useMemo(() => ([
    { key: 'userName', label: 'User', order: 0, className: 'username' },
    { key: 'usageCost', label: 'Usage cost', order: 1, className: 'usageCost', formatter: formatCost as any },
    { key: 'totalCostPercent', label: '% of total cost', order: 1, className: 'percentageCost', isColored: true, formatter: formatPercentage as any },
  ]), []);

  const { insights, insightsModalData, isInsightsDismissed, isInsightsCollapsed, loading: loadingInsights } = useInsights();

  const { loading: loadingComparitiveInsights, data: comparitiveInsights } = useComparitiveInsights(memoizedQuery, memoizedQueryWithoutCost);

  return (
    <div className="container-fluid overview">
      <Insights
        insights={insights}
        insightsModalData={insightsModalData}
        loading={loadingInsights}
        dismissAction={dismissInsights}
        collapseAction={toggleInsightsCollapsedState}
        isInsightsDismissed={isInsightsDismissed}
        isInsightsCollapsed={isInsightsCollapsed}
      />
      <div className="row filters mb-0 px-lg-9 px-md-5 px-4 py-dot75">
        <div className="col-md-auto mr-md-auto px-0 text-primary d-flex align-items-center font-weight-bold lh-1dot25">
          <img src={filterIcon} alt="filter" className="mr-1 w-1 h-1" />
          Filters
        </div>
        <div className="filterMob col-md-auto col-6 d-flex align-items-center flex-row-reverse justify-content-end px-0 ml-md-auto">
          <span className="position-relative dollarSymbol">$</span>
          <input
            type="text"
            className="text-right py-dot375 pl-3 pr-2 lh-1dot125 no-outline costPerCreditInput filterInputBorder"
            value={tempCostPerCredit}
            onChange={onCostPerCreditChange}
            onBlur={onCostPerCreditBlur}
          />
          <span className="filterLabel fs-dot75 mr-2">Cost per credit</span>
        </div>
        <div className="filterMob col-md-auto col-6 ml-md-dot75 px-0">
          <MultiSelect
            className="userSelect"
            value={selectedUsersOptions}
            onChange={handleUserFilterChange as any}
            options={userOptions}
            isMulti={true}
            subject="user"
            subjectPlural="users"
            loading={loadingDropdownData}
            icon={userIcon}
          />
        </div>
        <div className="filterMob col-md-auto col-6 px-0 ml-md-dot75">
          <MultiSelect
            className="userSelect"
            value={selectedWarehouseOptions}
            onChange={handleWarehouseFilterChange as any}
            options={warehouseOptions}
            isMulti={true}
            subject="warehouse"
            subjectPlural="warehouses"
            loading={loadingDropdownData}
            icon={warehouseIcon}
            iconWidth={20}
          />
        </div>
        <div className="filterMob col-auto d-flex align-items-center pl-0 ml-md-dot75 mr-md-0 mt-md-0">
          <DateRangePicker
            onSelect={handleDateRangeChange}
            className="py-dot375 px-2 lh-1dot125"
            startDate={startDate}
            endDate={endDate}
            enablePresets={true}
            maxDate={moment().endOf('day').valueOf()}
          />
        </div>
      </div>
      <div
        className={`row mb-0 px-lg-9 px-md-5 px-4 \
          ${loadingComparitiveInsights || !comparitiveInsights.length ?
              'justify-content-center align-items-center loadingComparitivInsight fs-dot75'
              : 'justify-content-around py-dot75'
          }`}
      >
        {loadingComparitiveInsights ? <Spinner /> : null}
        {!loadingComparitiveInsights && !comparitiveInsights.length ? 'Info not available' : null}
        {!loadingComparitiveInsights && comparitiveInsights.length ? comparitiveInsights.map((insightData: IComparitiveInsight, i: number) => (
          <ComparitiveInsight key={`insight_${i}`} {...insightData} />
        )) : null}
      </div>
      <div className="mild-background row mb-0 pt-dot875 pb-3 px-lg-9 px-md-5 px-4">
        <div className="col-lg-6 col-12 reportWrapper p-dot75">
          <ReportLayout title="Dates with highest usage cost" info="Dates with highest usage cost">
            <BarChart
              getDataAction={fetchTopDatesByUsageCost}
              dataSelector={selectTopDatesByUsageCost}
              isHorizantal={true}
              xKey="cost"
              yKey="dates"
              xLabel="Cost"
              dataType="dollar"
              query={memoizedQuery}
              enableCateogoryLink={true}
              linkFormatter={datePageLinkFormatter}
            />
          </ReportLayout>
        </div>
        <div className="col-lg-6 col-12 reportWrapper p-dot75">
          <ReportLayout title="Query size vs execution time">
            <ScatterPlot
              getDataAction={fetchQuerySizeVsTime}
              dataSelector={selectQuerySizeVsTime}
              xKey="querySize"
              yKey="executionTime"
              query={memoizedQueryWithoutCost}
              xLabel="Query size (Mb)"
              yLabel="Execution time (sec)"
            />
          </ReportLayout>
        </div>
        <div className="col-lg-6 col-12 reportWrapper topUsersReport p-dot75">
          <ReportLayout
            title="Users with highest estimated usage cost"
            className="donutChartLayout"
          >
            <DonutChart
              getDataAction={fetchTopUsersByUsageCost}
              dataSelector={selectTopUsersByUsageCost}
              linkFormatter={userLinkFormatter}
              valueKey="usageCost"
              totalKey="totalUsageCost"
              totalLabel="Total usage cost"
              labelKey="userName"
              legendMeta={donutLegends}
              query={memoizedQuery}
              dataType="dollar"
            />
          </ReportLayout>
        </div>
        <div className="col-lg-6 col-12 reportWrapper p-dot75">
          <ReportLayout
            title="Query time over time"
          >
            <BarTimeTrend
              getDataAction={fetchQueryTimeTrend}
              dataSelector={selectQueryTimeTrend}
              dateKey="DATE"
              hourKey="HOURS"
              lineKey="EXECUTION_TIME_SECONDS"
              valKey="TOTAL_QUERIES"
              query={memoizedQueryWithoutCost}
              startDate={startDate}
              endDate={endDate}
              xLabel="Time period"
              barLabel="Number of queries"
              lineLabel="Avg query exec time (sec)"
              enableChartOption={true}
              isArrayOfObj={true}
            />
          </ReportLayout>
        </div>
        <div className="col-lg-6 col-12 reportWrapper p-dot75">
          <ReportLayout
            title="Usage cost by warehouse"
            toggler={usageCostByWarehouseTogglerList as [string, string]}
            activeToggler={usageCostByWarehouseToggler}
            onToggle={handleUsageCostByWarehouseToggle}
          >
            {usageCostByWarehouseToggler === 'Name' ?
              <BarChart
                key="warehouseName"
                getDataAction={fetchTopWarehouseByUsageCost}
                dataSelector={selectTopWarehouseByUsageCost}
                query={memoizedQuery}
                isHorizantal={true}
                enableCateogoryLink={true}
                xKey="cost"
                yKey="warehouseName"
                xLabel="Cost"
                dataType="dollar"
                linkFormatter={warehousePageLinkFormatter}
              /> :
              <BarChart
                key="warehouseSize"
                getDataAction={fetchWarehouseSizeVsUsageCost}
                dataSelector={selectWarehouseSizeVsUsageCost}
                query={memoizedQuery}
                isHorizantal={true}
                enableCateogoryLink={true}
                xKey="cost"
                yKey="warehouseSize"
                xLabel="Cost"
                dataType="dollar"
                linkFormatter={sizePageLinkFormatter}
              />}
          </ReportLayout>
        </div>
        <div className="col-lg-6 col-12 reportWrapper p-dot75">
          <ReportLayout title="Query time by warehouse size">
            <StackedBarChart
              getDataAction={fetchQueryTimeVsWarehouseSize}
              dataSelector={selectQueryTimeVsWarehouseSize}
              categoryKey="warehouseSize"
              xLabel="Warehouse size"
              barLabel="Avg query execution time (sec)"
              datasetLabels={queryTimeVsWarehouseSizeMeta.datasetLabels}
              valKeys={queryTimeVsWarehouseSizeMeta.valKeys}
              lineKey="numberOfQueries"
              lineLabel="Number of queries"
              colors={queryTimeVsWarehouseSizeMeta.colors}
              query={memoizedQueryWithoutCost}
              assignLeftAxisForLine={true}
            />
          </ReportLayout>
        </div>
        <div className="col-12 reportWrapper tableWrapper p-dot75">
          <TableReport
            query={memoizedQuery}
            dataFormatter={topQueriesByUsageAndTimeParser}
            getDataAction={fetchTopQueriesByCostAndTime}
            dataSelector={selectTopQueriesByCostAndTime}
            columns={allColumns}
            title="Top 10 High Cost Consumed Queries"
          />
        </div>
      </div>
    </div>
  );
}

export default Overview;

const useInsights = () => {
  const dispatch = useAppDispatch();
  const isInsightsDismissed = useAppSelector(selectIsInsightsDismissed);
  const isInsightsCollapsed = useAppSelector(selectIsInsightsCollapsed);
  const {
    value: higherUsageUserInsights,
    loading: loadingHigherUsageUserInsights,
    initialFetchDone: higherUsageUserInsightsFetchedOnce
  } = useAppSelector(selectHigherUsageUserInsights);
  const {
    value: complexQueriesInsight,
    loading: loadingComplexQueriesInsight,
    initialFetchDone: complexQueriesInsightFetchedOnce
  } = useAppSelector(selectComplexQueriesInsight);
  const {
    value: overloadWHInsight,
    loading: loadingOverloadWHInsight,
    initialFetchDone: overloadWHInsightFetchedOnce
  } = useAppSelector(selectOverloadWHInsight);

  const { insights, insightsModalData }: {insights: IInsight[]; insightsModalData: Array<any>} = useMemo(() => {
    const insights = [];
    const insightsModalData = [];
    const columns = [
      { field: 'QUERY_ID', headerName: 'Query ID', resizable: true, filter: true, floatingFilter: false, isVisible: true },
      { field: 'USER_NAME', headerName: 'User', resizable: true, filter: true, floatingFilter: false, isVisible: true },
      { field: 'WAREHOUSE_NAME', headerName: 'Warehouse', resizable: true, filter: true, floatingFilter: false, isVisible: true },
      { field: 'QUERY_TEXT', headerName: 'Query', resizable: false, filter: true, floatingFilter: false, isVisible: true },
      { field: 'EXECUTION_TIME', headerName: 'Execution Time (Sec)', resizable: true, filter: true, floatingFilter: false, isVisible: true },
      { field: 'START_TIME', headerName: 'Query Started At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true, valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) },
      { field: 'END_TIME', headerName: 'Query Ended At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true , valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) }
    ];
    const dataFormatter = (data: any) => data;
    if (higherUsageUserInsights) {
      insights.push(higherUsageUserInsights);
      insightsModalData.push({
        dataSelector: selectHigherUsageUsers,
        getDataAction: fetchHigherUsageusers,
        columns,
        dataFormatter
      });
    }
    if (complexQueriesInsight) {
      insights.push(complexQueriesInsight);
      insightsModalData.push({
        dataSelector: selectComplexQueries,
        getDataAction: fetchComplexQueries,
        columns,
        dataFormatter
      });
    }
    if (overloadWHInsight) {
      insights.push(overloadWHInsight);
      insightsModalData.push({
        dataSelector: selectOverloadWHQueries,
        getDataAction: fetchOverloadWHQueries,
        columns,
        dataFormatter
      });
    }
    return { insights, insightsModalData };
  }, [higherUsageUserInsights, complexQueriesInsight, overloadWHInsight]);
  useEffect(() => {
    if (!complexQueriesInsightFetchedOnce) {
      dispatch(fetchComplexQueriesInsight());
    }
    if (!higherUsageUserInsightsFetchedOnce) {
      dispatch(fetchHigherUsageuserInsight());
    }
    if (!overloadWHInsightFetchedOnce) {
      dispatch(fetchOverloadWhInsight());
    }
  }, [
    dispatch,
    complexQueriesInsightFetchedOnce,
    higherUsageUserInsightsFetchedOnce,
    overloadWHInsightFetchedOnce
  ]);
  return {
    insightsModalData,
    insights,
    isInsightsDismissed,
    isInsightsCollapsed,
    loading: loadingComplexQueriesInsight ||
      loadingHigherUsageUserInsights ||
      loadingOverloadWHInsight
  };
};

const useComparitiveInsights = (queryWithCost: ICommonQuery, queryWithoutCost: ICommonQuery) => {
  const initialRenderDone: MutableRefObject<boolean> = useRef(false);
  const dispatch = useAppDispatch();
  const { startDate, endDate } = queryWithCost;
  const {
    value: totalUsageCost,
    loading: totalUsageCostLoading,
    initialFetchDone: comparitiveCostInsightsFetchedOnce
  } = useAppSelector(selectTotalUsageCost);
  const {
    value: averageDailyCost,
    loading: averageDailyCostLoading
  } = useAppSelector(selectAverageDailyCost);
  const {
    value: medianExecTime,
    loading: medianExecTimeLoading
  } = useAppSelector(selectMedianExecTime);
  const {
    value: maxExecTime,
    loading: maxExecTimeLoading
  } = useAppSelector(selectMaxExecTime);
  const {
    value: queryCount,
    loading: queryCountLoading,
    initialFetchDone: comparitiveQueryInsightsFetchedOnce
  } = useAppSelector(selectQueryCount);

  useEffect(() => {
    if (initialRenderDone.current) {
      dispatch(fetchComparitiveCostInsights(queryWithCost));
    }
  }, [ dispatch, queryWithCost ]);

  useEffect(() => {
    if (initialRenderDone.current) {
      dispatch(fetchComparitiveQueryInsights(queryWithoutCost));
    }
  }, [ dispatch, queryWithoutCost ]);

  useEffect(() => {
    if (!initialRenderDone.current) {
      !comparitiveCostInsightsFetchedOnce && dispatch(fetchComparitiveCostInsights(queryWithCost));
      !comparitiveQueryInsightsFetchedOnce && dispatch(fetchComparitiveQueryInsights(queryWithoutCost));
      initialRenderDone.current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const days = moment(endDate).diff(startDate, 'days') + 1;
  const diffPeriod = days === 1 ?
    `${moment(startDate).subtract(days, 'days').format('D MMM, yy')}` :
    `${moment(startDate).subtract(days, 'days').format('D MMM, yy')} - ${
      moment(endDate).subtract(days, 'days').format('D MMM, yy')}`;
  const comparitiveInsights = useMemo(() => ({
    loading: (
      totalUsageCostLoading ||
      averageDailyCostLoading ||
      medianExecTimeLoading ||
      maxExecTimeLoading ||
      queryCountLoading
    ),
    data: [
      {
        title: 'Total usage cost',
        dataCateogory: 'dollar' as dataCateogory,
        diffPercent: totalUsageCost?.cardsValue?.comparedPercent || 0,
        value: totalUsageCost?.cardsValue?.totalUsageCost || 0,
        diffPeriod,
        lineChartData: totalUsageCost?.queryTrendData?.cost
      },
      {
        title: 'Average daily cost',
        dataCateogory: 'dollar' as dataCateogory,
        diffPercent: averageDailyCost?.cardsValue.comparedPercent || 0,
        value: averageDailyCost?.cardsValue?.avgDailyCost || 0,
        diffPeriod,
        lineChartData: averageDailyCost?.queryTrendData?.avgCost
      },
      {
        title: 'Median execution time',
        dataCateogory: 'time' as dataCateogory,
        diffPercent: medianExecTime?.cardsValue?.comparedPercent || 0,
        value: medianExecTime?.cardsValue?.medianExecutionTime || 0,
        diffPeriod,
        lineChartData: medianExecTime?.queryTrendData?.medianExeTime
      },
      {
        title: 'Max. execution time',
        dataCateogory: 'time' as dataCateogory,
        diffPercent: maxExecTime?.cardsValue?.comparedPercent || 0,
        value: maxExecTime?.cardsValue?.maximumExecutionTime || 0,
        diffPeriod,
        lineChartData: maxExecTime?.queryTrendData?.maxExeTime
      },
      {
        title: 'Query count',
        dataCateogory: 'number' as dataCateogory,
        diffPercent: queryCount?.cardsValue?.comparedPercent || 0,
        value: queryCount?.cardsValue?.queryCount || 0,
        diffPeriod,
        lineChartData: queryCount?.queryTrendData?.queryCount
      },
    ]
  }), [
    queryCount,
    maxExecTime,
    medianExecTime,
    averageDailyCost,
    totalUsageCost,
    queryCountLoading,
    maxExecTimeLoading,
    medianExecTimeLoading,
    averageDailyCostLoading,
    totalUsageCostLoading,
    diffPeriod
  ]);

  return comparitiveInsights;
}
