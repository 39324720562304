import picker from 'daterangepicker';
import { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ArrowLeftIcon from '../../assets/image/arrow-left-black.svg';
import CalendarIcon from '../../assets/image/calendar.svg';

import 'daterangepicker/daterangepicker.css'
import './DateRangePicker.scss';

interface IDateRangePicker {
  onSelect: (dateRange: [ number, number ]) => void;
  startDate?: number;
  endDate?: number;
  className?: string;
  showTime?: boolean;
  enablePresets?: boolean;
  minDate?: number;
  maxDate?: number;
  maxSpan?: number;
  enableToggler?: boolean;
}

const DateRangePicker = ({
  onSelect, className = '', startDate, endDate, showTime, enablePresets,
  minDate, maxDate, maxSpan, enableToggler
}: IDateRangePicker) => {
  const pickerElementRef: RefObject<HTMLDivElement> = useRef(null);
  const pickerObjRef: MutableRefObject<picker | undefined> = useRef();
  const defaultStartDate = startDate || moment().subtract(6, 'days').valueOf();
  const defaultEndDate = endDate || moment().valueOf();
  const [ dateRange, setDateRange ] = useState([ defaultStartDate, defaultEndDate ]);
  const [ isVisible, setVisibility ] = useState(false);
  const displayFormat = showTime ? 'HH:mm D MMM, yyyy' : 'D MMM, yyyy';
  useEffect(() => {
    const pickerElement = pickerElementRef.current;
    if (pickerElement) {
      const pickerObj = new picker(pickerElement, {
        ranges: enablePresets ? {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
          'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
          'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
          'This Month': [moment().startOf('month'), moment().endOf('day')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        } : undefined,
        alwaysShowCalendars: true,
        startDate: moment(new Date(defaultStartDate)),
        endDate: moment(new Date(defaultEndDate)),
        opens: 'left',
        maxSpan: maxSpan ? { days: maxSpan } : undefined,
        minDate: minDate ? moment(new Date(minDate)) : undefined,
        maxDate: maxDate ? moment(new Date(maxDate)) : undefined,
        timePicker: showTime
      }, (startDate, endDate) => {
        const startDateInMs = moment(startDate).valueOf();
        const endDateInMs = moment(endDate).valueOf();
        setDateRange([ startDateInMs, endDateInMs ]);
        onSelect([ startDateInMs, endDateInMs ]);
      });
      pickerObjRef.current = pickerObj;
      const jQueryEl: any = (pickerObj as any)?.element;
      const setVisibilityToTrue = () => setVisibility(true);
      const setVisibilityToFalse = () => setVisibility(false);
      jQueryEl && jQueryEl.on('show.daterangepicker', setVisibilityToTrue);
      jQueryEl && jQueryEl.on('hide.daterangepicker', setVisibilityToFalse);
      const scrollhost = document.getElementsByClassName('scrollhost')[0];
      scrollhost?.addEventListener('scroll', () => {
        hideCalendarOnscroll(pickerObj);
      });
      return () => {
        pickerObj.remove();
        jQueryEl && jQueryEl.off('show.daterangepicker', setVisibilityToTrue);
        jQueryEl && jQueryEl.off('hide.daterangepicker', setVisibilityToFalse);
        scrollhost.removeEventListener('scroll', () => {
          hideCalendarOnscroll(pickerObj);
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onSelect, pickerElementRef, enablePresets, minDate, maxDate, maxSpan ]);

  const hideCalendarOnscroll = (pickerObj:any) => {
    setVisibility(false);
    pickerObj.container.hide('slow');
  }

  const handleToggle = (e: React.MouseEvent, isPrev?: boolean) => {
    e.stopPropagation();
    const dateDiff = moment(dateRange[1]).diff(dateRange[0], 'days');
    if (isPrev) {
      const nxtEndDateInMS = moment(dateRange[0]).subtract(1, 'days').valueOf();
      let nxtStartDateInMS = moment(nxtEndDateInMS).subtract(dateDiff, 'days').valueOf();
      if (minDate && moment(nxtEndDateInMS).isBefore(minDate)) {
        nxtStartDateInMS = moment(minDate).valueOf();
      }
      pickerObjRef.current?.setStartDate(new Date(nxtStartDateInMS));
      pickerObjRef.current?.setEndDate(new Date(nxtEndDateInMS));
      setDateRange([nxtStartDateInMS, nxtEndDateInMS]);
      onSelect([nxtStartDateInMS, nxtEndDateInMS]);
    } else {
      const nxtStartDateInMS = moment(dateRange[1]).add(1, 'days').valueOf();
      let nxtEndDateInMS = moment(nxtStartDateInMS).add(dateDiff, 'days').valueOf();
      if (maxDate && moment(nxtEndDateInMS).isAfter(maxDate)) {
        nxtEndDateInMS = moment(maxDate).valueOf();
      }
      pickerObjRef.current?.setStartDate(new Date(nxtStartDateInMS));
      pickerObjRef.current?.setEndDate(new Date(nxtEndDateInMS));
      setDateRange([nxtStartDateInMS, nxtEndDateInMS]);
      onSelect([nxtStartDateInMS, nxtEndDateInMS]);
    }
  }

  return (
    <div
      className={`dateRangePicker no-wrap d-flex align-items-center ${className} ${isVisible ? 'active' : ''}`}
      ref={enableToggler ? undefined : pickerElementRef}
    >
      <div className="d-inline-flex align-items-center" ref={enableToggler ? pickerElementRef : undefined}>
        <img src={CalendarIcon} className="mr-1 calendarIcon" alt="" />
        { moment(dateRange[0]).format(displayFormat) } - { moment(dateRange[1]).format(displayFormat) }
      </div>
      {enableToggler ?
        <>
          <img
            src={ArrowLeftIcon}
            alt="<"
            className={`ml-4 cursor-pointer ${moment(dateRange[0]).diff(moment(minDate), 'days') ? '' : 'disabledToggler'}`}
            onClick={(e) => handleToggle(e, true)}
          />
          <img
            src={ArrowLeftIcon}
            className={`rotate-180 ml-3 cursor-pointer ${moment(dateRange[1]).diff(moment(maxDate), 'days') ? '' : 'disabledToggler'}`}
            alt=">"
            onClick={(e) => handleToggle(e)}
          />
        </> : null }
        <i className={`ml-3 fa fa-caret-${isVisible ? 'up' : 'down'}`} />
    </div>
  );
}

export default DateRangePicker;
