import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { demoProvider } from '../..';
import { decrementTimeRemaining, setTimeRemaining, createFreeSession, setCanShowDemoData, saveAnonymousUserMail } from '../../slice/demo';
import { useAppDispatch } from '../../store/hooks';
import DemoInit from './DemoInit';
import { disableDayOneFreTimeExtension } from './DemoProvider';
import RegisterOrLoginForm from './RegisterOrLoginForm';
import Timeup from './Timeup';

const DemoModule = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  // is demo URL
  const isDemo = location.pathname.startsWith('/demo');

  // isAlreadyVisited
  const [ registerModal, setRegisterModal ] = useState(false);
  const [ disableExtension, setDisableExtension ] = useState(false);

  const [ initModal, setInitModal ] = useState(false);
  const [ timeup, setTimeUp ] = useState(false);

  const setTimer = useCallback((time: number, onEnd: Function) => {
    dispatch(setTimeRemaining(time));
    const clockId = setInterval(() => { dispatch(decrementTimeRemaining()) }, 1000);
    setTimeout(() => {
      clearTimeout(clockId);
      onEnd();
    }, time * 1000);
  }, [ dispatch ]);

  const onExplore = useCallback((email: string) => {
    dispatch(saveAnonymousUserMail(email));
    dispatch(createFreeSession())
      .then(unwrapResult)
      .then(() => {
        dispatch(setCanShowDemoData(true));
        dispatch(setCanShowDemoData)
        demoProvider.initializeDayOneFreeSession();
        const remainingTime = demoProvider.getRemainingTime();
        setInitModal(false);
        setTimer(remainingTime, () => {
          setDisableExtension(disableDayOneFreTimeExtension);
          setRegisterModal(true);
        });
      })
  }, [ dispatch, setTimer ]); 

  const onRegisterOrLogin = useCallback(() => {
    dispatch(setCanShowDemoData(true));
    demoProvider.initializeRegisteredSession();
    setRegisterModal(false);
    setTimer(demoProvider.getRemainingTime(), () => setTimeUp(true));
  }, [ setTimer, dispatch ]);

  const onExtend = useCallback(() => {
    dispatch(setCanShowDemoData(true));
    demoProvider.initializeExtendedSession();
    setRegisterModal(false);
    setTimer(demoProvider.getRemainingTime(), () => {
      setDisableExtension(true);
      setRegisterModal(true);
    });
  }, [ setTimer, dispatch ]);

  // init effect
  useEffect(() => {
    if (!isDemo) return;
    if (demoProvider.isDayOne()) {
      if (!demoProvider.isDayOneSessionInitialized()) {
        setInitModal(true);
        return;
      } else if (!demoProvider.isDayOneFreeSessionEnded()) {
        dispatch(setCanShowDemoData(true));
        setTimer(demoProvider.getRemainingTime(), () => setRegisterModal(true));
        return;
      } else if (!demoProvider.isDayOneExtendedSessionInitialized() && !demoProvider.isDayOneRegisteredSessionInitialized()) {
        setRegisterModal(true);
        return;
      } else if (demoProvider.isDayOneRegisteredSessionInitialized()) {
        if (demoProvider.isDayOneRegisteredSessionEnded()) {
          setTimeUp(true);
          return;
        } else {
          dispatch(setCanShowDemoData(true));
          setTimer(demoProvider.getRemainingTime(), () => setTimeUp(true));
          return;
        }
      } else {
        if (!demoProvider.isDayOneExtendedSessionInitialized()) {
          setRegisterModal(true);
          return;
        } else if (!demoProvider.isDayOneExtendedSessionEnded()) {
          dispatch(setCanShowDemoData(true));
          setTimer(demoProvider.getRemainingTime(), () => {
            setDisableExtension(true);
            setRegisterModal(true);
          });
          return;
        } else {
          setDisableExtension(true);
          setRegisterModal(true);
          return;
        }
      }
    } else {
      if (demoProvider.isTodaysSessionEnded()) {
        setTimeUp(true);
        return;
      } else if (demoProvider.isTodaysSessionStarted()) {
        setTimer(demoProvider.getRemainingTime(), () => {
          setTimeUp(true);
        });
        return;
      } else {
        setDisableExtension(true);
        setRegisterModal(true);
        return;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    (initModal || registerModal || timeup) ?
      <div id="modal-background" className="modalBackground px-5 d-flex align-items-center justify-content-center">
        { initModal &&
          <DemoInit onStart={onExplore} /> }
        { timeup && <Timeup /> }
        { registerModal &&
          <RegisterOrLoginForm
            onRegisterOrLogin={onRegisterOrLogin}
            disableExtension={disableExtension}
            onExtend={onExtend}
          /> }
      </div> : null
  );
}

export default DemoModule;
