import axios from "axios";
import { freeSessionAPI, extendSessionAPI, createDemoUserAPI, saveAnonymousUserMailAPI } from "../../constants/appConstants";

export const addDemoUser = (data: any) => axios({
  method: 'post',
  url: createDemoUserAPI,
  data
});

export const createFreeSession = () => axios({
  method: 'post',
  url: freeSessionAPI
});

export const saveAnonymousUserMail = (email: string) => axios({
  method: 'post',
  url: saveAnonymousUserMailAPI,
  data: { email }
})

export const extendUserSession = (email: string) => axios({
  method: 'post',
  url: extendSessionAPI,
  data: { email }
});
