import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../store/store';
import * as appAPI from './appAPI';


export interface IUser {
  _id: string;
  email: string;
}

export interface IAppSlice {
  dashboardMeta: {
    users?: string[];
    warehouses?: string[];
    warehouseSizes?: string[];
    snowflakeAccounts?: string[];
    activeSnowflakeAccount?: string;
    loading: boolean;
    isCacheAvailable: boolean;
  },
  timezone: {
    loading: boolean;
    value?: string;
    isCacheAvailable: boolean;
  }
}

const getInitialState = () => ({
  dashboardMeta: {
    loading: false,
    isCacheAvailable: false
  },
  timezone: {
    loading: false,
    isCacheAvailable: false
  }
});

const initialState: IAppSlice = getInitialState();

export const fetchDashboardMeta = createAsyncThunk(
  'app/fetchDashboardMeta',
  async () => {
    const { data: { users = [], whNames = [], whSizes = [], snowflakeAccounts = [], activeAccountName}} = await appAPI.fetchDashboardMeta();
    return { users, warehouses: whNames, warehouseSizes: whSizes, snowflakeAccounts: snowflakeAccounts, activeAccountName: activeAccountName };
  }
);

export const fetchTimezone = createAsyncThunk(
  'app/fetchTimezone',
  async () => {
    const { data } = await appAPI.fetchTimezone();
    return data?.length ? data[0]?.value : undefined
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetApp: (state) => state = initialState
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardMeta.pending, (state) => {
        state.dashboardMeta.loading = true;
      })
      .addCase(fetchDashboardMeta.fulfilled, (state, action) => {
        state.dashboardMeta.loading = false;
        state.dashboardMeta.users = action.payload.users;
        state.dashboardMeta.warehouses = action.payload.warehouses;
        state.dashboardMeta.warehouseSizes = action.payload.warehouseSizes;
        state.dashboardMeta.snowflakeAccounts = action.payload.snowflakeAccounts;
        state.dashboardMeta.activeSnowflakeAccount = action.payload.activeAccountName;
        state.dashboardMeta.isCacheAvailable = true;
      }).addCase(fetchDashboardMeta.rejected, (state) => {
        state.dashboardMeta.loading = false;
      })

      .addCase(fetchTimezone.pending, (state) => {
        state.timezone.loading = true
      })
      .addCase(fetchTimezone.fulfilled, (state, action) => {
        state.timezone.loading = false;
        state.timezone.value = action.payload;
        state.timezone.isCacheAvailable = true;
      }).addCase(fetchTimezone.rejected, (state) => {
        state.timezone.loading = false;
      })
  },
});

export const { resetApp } = appSlice.actions;

export const selectDashboardMeta = (state: RootState) => state.app.dashboardMeta;
export const selectTimezone = (state: RootState) => state.app.timezone;

export default appSlice.reducer;
 