import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import * as queryMetricsAPI from './queryMetricsAPI';
import { ICommonQuery } from 'custom-types';


export interface IQuerySizeVsTime {
  querySize: number[];
  executionTime: number[];
}

interface IQueryByDate {
  DATE: string;
  HOURS: number;
  TOTAL_QUERIES: number;
  EXECUTION_TIME_SECONDS: number;
}

type IQueryTimeTrend = Array<IQueryByDate>;

export interface IQueryTimeVsWarehouseSize {
  warehouseSize: string[];
  numberOfQueries: number[];
  queryWaitTime: number[];
  queryExecutionTime: number[];
}

export interface IQueryCount {
  cardsValue: {
    queryCount: number;
    comparedPercent:number;
  };
  queryTrendData: {
    date: string[];
    queryCount: number[];
  };
}

export interface IMaxExecTime {
  cardsValue: {
    maximumExecutionTime: number;
    comparedPercent: number;
  };
  queryTrendData: {
    date: string[];
    maxExeTime: number[];
  };
}

export interface IMedianExecTime {
  cardsValue: {
    medianExecutionTime: number;
    comparedPercent: number;
  };
  queryTrendData: {
    date: string[];
    medianExeTime: number[];
  };
}

export interface ITopQueriesByCostAndTime {
  queryId: number[];
  userId: number[];
  warehouseName: string[];
  executionTime: number[];
  usageCost: number[];
  query: string[];
  startTime: string[];
  endtime: string[];
}

export interface IqueryMetrics {
  maxExecTime: {
    value?: IMaxExecTime;
    loading: boolean;
    initialFetchDone: boolean;
  };
  medianExecTime: {
    value?: IMedianExecTime;
    loading: boolean;
    initialFetchDone: boolean;
  };
  queryCount: {
    value?: IQueryCount;
    loading: boolean;
    initialFetchDone: boolean;
  };
  querySizeVsTime: {
    value?: IQuerySizeVsTime,
    loading: boolean;
    initialFetchDone: boolean;
  };
  queryTimeTrend: {
    value?: IQueryTimeTrend;
    valueByHours?: IQueryTimeTrend;
    loading: boolean;
    initialFetchDone: boolean;
    hoursInitialFetchDone: boolean;
  };
  queryTimeVsWarehouseSize: {
    value?: IQueryTimeVsWarehouseSize,
    loading: boolean;
    initialFetchDone: boolean;
  };
  topQueriesByCostAndTime: {
    value?: ITopQueriesByCostAndTime,
    loading: boolean;
    initialFetchDone: boolean;
  }
}

const initialState: IqueryMetrics = {
  maxExecTime: {
    loading: false,
    initialFetchDone: false
  },
  medianExecTime: {
    loading: false,
    initialFetchDone: false
  },
  queryCount: {
    loading: false,
    initialFetchDone: false
  },
  querySizeVsTime: {
    loading: false,
    initialFetchDone: false
  },
  queryTimeTrend: {
    loading: false,
    initialFetchDone: false,
    hoursInitialFetchDone: false
  },
  queryTimeVsWarehouseSize: {
    loading: false,
    initialFetchDone: false
  },
  topQueriesByCostAndTime: {
    loading: false,
    initialFetchDone: false
  }
};

export const fetchComparitiveQueryInsights = createAsyncThunk(
  'costMetrics/fetchComparitiveQueryInsights',
  async (queryObj: ICommonQuery) => {
    const { data: { cardsValue: [ cardsValue ], queryTrendData }} = await queryMetricsAPI.fetchComparitiveQueryInsights(queryObj);
    const maxExecTime: IMaxExecTime = {
      cardsValue: {
        maximumExecutionTime: cardsValue?.CURR_MAX_EXECUTION_TIME_SECS || 0,
        comparedPercent: cardsValue?.MAX_EXECUTION_TIME_PERC_INCREASE || 0
      },
      queryTrendData: {
        date: [],
        maxExeTime: []
      }
    };
    const medianExecTime: IMedianExecTime = {
      cardsValue: {
        medianExecutionTime: cardsValue?.CURR_MEDIAN_EXECUTION_TIME_SECS || 0,
        comparedPercent: cardsValue?.MEDIAN_EXECUTION_TIME_PERC_INCREASE || 0
      },
      queryTrendData: {
        date: [],
        medianExeTime: []
      }
    };
    const queryCount: IQueryCount = {
      cardsValue: {
        queryCount: cardsValue?.CURRENT_TOT_QUERIES || 0,
        comparedPercent: cardsValue?.TOT_QUERIES_PERC_INCREASE || 0
      },
      queryTrendData: {
        date: [],
        queryCount: []
      }
    };
    (queryTrendData || []).forEach(({ DATE, MAX_ELAPSED_TIME_SECS, MEDIAN_ELAPSED_TIME_SECS, QUERY_CNT }: any) => {
      maxExecTime.queryTrendData.date.push(DATE);
      maxExecTime.queryTrendData.maxExeTime.push(MAX_ELAPSED_TIME_SECS);
      medianExecTime.queryTrendData.date.push(DATE);
      medianExecTime.queryTrendData.medianExeTime.push(MEDIAN_ELAPSED_TIME_SECS);
      queryCount.queryTrendData.date.push(DATE);
      queryCount.queryTrendData.queryCount.push(QUERY_CNT);
    });
    return { maxExecTime, medianExecTime, queryCount };
  }
);

export const fetchQuerySizeVsTime = createAsyncThunk(
  'counter/fetchQuerySizeVsTime',
  async (queryObj: ICommonQuery) => {
    const response = await queryMetricsAPI.fetchQuerySizeVsTime(queryObj);
    return response.data;
  }
);

export const fetchQueryTimeTrend = createAsyncThunk(
  'counter/fetchQueryTimeTrend',
  async (queryObj: ICommonQuery) => {
    const response = await queryMetricsAPI.fetchQueryTimeTrend(queryObj);
    return response.data;
  }
);

export const fetchQueryTimeVsWarehouseSize = createAsyncThunk(
  'counter/fetchQueryTimeVsWarehouseSize',
  async (queryObj: ICommonQuery) => {
    const response = await queryMetricsAPI.fetchQueryTimeVsWarehouseSize(queryObj);
    return response.data;
  }
);

export const fetchTopQueriesByCostAndTime = createAsyncThunk(
  'counter/fetchTopQueriesByCostAndTime',
  async (queryObj: ICommonQuery) => {
    const response = await queryMetricsAPI.fetchTopQueriesByCostAndTime(queryObj);
    return response.data;
  }
);

export const queryMetricsSlice = createSlice({
  name: 'queryMetrics',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearQueryMetrics: (state) => {
      state.maxExecTime = initialState.maxExecTime;
      state.medianExecTime = initialState.medianExecTime;
      state.queryCount = initialState.queryCount;
      state.querySizeVsTime = initialState.querySizeVsTime;
      state.queryTimeTrend = initialState.queryTimeTrend;
      state.queryTimeVsWarehouseSize = initialState.queryTimeVsWarehouseSize;
      state.topQueriesByCostAndTime = initialState.topQueriesByCostAndTime;
    },
    clearQueryMetricsReports: (state) => {
      state.querySizeVsTime = initialState.querySizeVsTime;
      state.queryTimeTrend = initialState.queryTimeTrend;
      state.queryTimeVsWarehouseSize = initialState.queryTimeVsWarehouseSize;
      state.topQueriesByCostAndTime = initialState.topQueriesByCostAndTime;
    },
    resetQueryMetrics: (state) => state = initialState
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchComparitiveQueryInsights.pending, (state) => {
        state.maxExecTime.loading = true;
        state.maxExecTime.initialFetchDone = true;
        state.medianExecTime.loading = true;
        state.medianExecTime.initialFetchDone = true;
        state.queryCount.loading = true;
        state.queryCount.initialFetchDone = true;
      })
      .addCase(fetchComparitiveQueryInsights.fulfilled, (state, action) => {
        state.maxExecTime.loading = false;
        state.maxExecTime.value = action.payload.maxExecTime;
        state.medianExecTime.loading = false;
        state.medianExecTime.value = action.payload.medianExecTime;
        state.queryCount.loading = false;
        state.queryCount.value = action.payload.queryCount;
      }).addCase(fetchComparitiveQueryInsights.rejected, (state, action) => {
        state.maxExecTime.loading = false;
        state.medianExecTime.loading = false;
        state.queryCount.loading = false;
      })

      .addCase(fetchQuerySizeVsTime.pending, (state) => {
        state.querySizeVsTime.loading = true;
        state.querySizeVsTime.initialFetchDone = true;
      })
      .addCase(fetchQuerySizeVsTime.fulfilled, (state, action) => {
        state.querySizeVsTime.loading = false;
        state.querySizeVsTime.value = action.payload;
      })
      .addCase(fetchQuerySizeVsTime.rejected, (state, action) => {
        state.querySizeVsTime.loading = false;
      })

      .addCase(fetchQueryTimeTrend.pending, (state, action) => {
        state.queryTimeTrend.loading = true;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryTimeTrend.hoursInitialFetchDone = true;
        } else  {
          state.queryTimeTrend.initialFetchDone = true;
        }
      })
      .addCase(fetchQueryTimeTrend.fulfilled, (state, action) => {
        state.queryTimeTrend.loading = false;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryTimeTrend.valueByHours = action.payload;
        } else  {
          state.queryTimeTrend.value = action.payload;
        }
      })
      .addCase(fetchQueryTimeTrend.rejected, (state, action) => {
        state.queryTimeTrend.loading = false;
      })

      .addCase(fetchQueryTimeVsWarehouseSize.pending, (state) => {
        state.queryTimeVsWarehouseSize.loading = true;
        state.queryTimeVsWarehouseSize.initialFetchDone = true;
      })
      .addCase(fetchQueryTimeVsWarehouseSize.fulfilled, (state, action) => {
        state.queryTimeVsWarehouseSize.loading = false;
        state.queryTimeVsWarehouseSize.value = action.payload;
      })
      .addCase(fetchQueryTimeVsWarehouseSize.rejected, (state, action) => {
        state.queryTimeVsWarehouseSize.loading = false;
      })

      .addCase(fetchTopQueriesByCostAndTime.pending, (state) => {
        state.topQueriesByCostAndTime.loading = true;
        state.topQueriesByCostAndTime.initialFetchDone = true;
      })
      .addCase(fetchTopQueriesByCostAndTime.fulfilled, (state, action) => {
        state.topQueriesByCostAndTime.loading = false;
        state.topQueriesByCostAndTime.value = action.payload;
      })
      .addCase(fetchTopQueriesByCostAndTime.rejected, (state, action) => {
        state.topQueriesByCostAndTime.loading = false;
      })
  },
});

export const { clearQueryMetrics, clearQueryMetricsReports, resetQueryMetrics } = queryMetricsSlice.actions;

export const selectMaxExecTime = (state: RootState) => state.queryMetrics.maxExecTime;
export const selectMedianExecTime = (state: RootState) => state.queryMetrics.medianExecTime;
export const selectQueryCount = (state: RootState) => state.queryMetrics.queryCount;
export const selectQuerySizeVsTime = (state: RootState) => state.queryMetrics.querySizeVsTime;
export const selectQueryTimeTrend = (state: RootState) => state.queryMetrics.queryTimeTrend;
export const selectQueryTimeVsWarehouseSize = (state: RootState) => state.queryMetrics.queryTimeVsWarehouseSize;
export const selectTopQueriesByCostAndTime = (state: RootState) => state.queryMetrics.topQueriesByCostAndTime;

export default queryMetricsSlice.reducer;
