import React from "react";
import CustomPopover from "../Popover";
import Spinner from "../Spinner";

import './ReportLayout.scss';

interface IReportLayoutProps {
  title: string;
  description?: string;
  children?: React.ReactElement | null;
  className?: string;
  loading?: boolean;
  noData?: boolean;
  toggler?: [string, string];
  activeToggler?: string;
  onToggle?: (toggle: string) => void;
  headerElement?: () => React.ReactElement;
  info?: string;
}

const ReportLayout = ({
  title, children = null, className = '', headerElement,
  loading, onToggle, noData, toggler, activeToggler, info
}: IReportLayoutProps) => {
  return (
    <div className={`reportLayout h-100 ${className}`}>
      <div className="reportHeader d-flex align-items-center pl-4 pr-1dot25">
        <div className="fs-dot875 font-weight-semibold reportTitle">{title}</div>
        {toggler && toggler.length === 2 ?
          <div className="toggler d-flex">
            <div
              onClick={activeToggler === toggler[0] ? undefined : () => onToggle && onToggle(toggler[0])}
              className={`d-flex align-items-center justify-content-center option ${activeToggler === toggler[0] ? 'active' : ''}`}
            >{toggler[0]}</div>
            <div
              onClick={activeToggler === toggler[1] ? undefined : () => onToggle && onToggle(toggler[1])}
              className={`d-flex align-items-center justify-content-center option ${activeToggler === toggler[1] ? 'active' : ''}`}
            >{toggler[1]}</div>
          </div> : null}
        {headerElement && !loading && !noData ? headerElement() : null}
        {info ? 
          <div className={`${headerElement ? 'ml-2' : 'ml-auto'}`}>
            <CustomPopover content={info}>
              <div className="cursor-pointer reportInfo d-flex align-items-center justify-content-center fs-dot5">
                <i className="fa fa-info" />
              </div>
            </CustomPopover>
          </div>
          : null}
      </div>
      <div className="divider" />
      <div className={`reportBody pl-4 pr-1dot25 py-3 ${loading || noData ? 'position-relative' : ''}`}>
        {loading || noData ?
          <div className="d-flex align-items-center justify-content-center loaderWrapper position-absolute w-100 h-100">
            {loading ?
              <Spinner /> : <div className="noData">No data available</div>}
          </div> : null}
        {children}
      </div>
    </div>
  );
}

export default ReportLayout;
