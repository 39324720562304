import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { PUBLIC_ROUTES } from '../../constants/route';
import Input from '../../components/Input';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { login, selectLoggingIn } from '../../slice/user';
import logo from '../../assets/image/logo.svg';

import './Login.scss';

const Login = () => {
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ usernameError, setUsernameError ] = useState('');
  const [ passwordError, setPasswordError ] = useState('');
  const dispatch = useAppDispatch();
  const loggingIn = useAppSelector(selectLoggingIn);

  const valdateInput = () => {
    let usernameValid = false;
    let passwordValid = false;
    if (!username) {
      setUsernameError('Please enter your email address');
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(username)) {
      setUsernameError('Please enter a valid email address');
    } else {
      setUsernameError('');
      usernameValid = true;
    }
    if (!password) {
      setPasswordError('Please enter your password');
    } else {
      setPasswordError('');
      passwordValid = true;
    }
    return usernameValid && passwordValid;
  }

  const onSubmit = () => {
    if (valdateInput()) {
      dispatch(login({ email: username, password }));
    }
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  }

  return (
    <div className="loginContainer d-flex justify-content-center align-items-center bg-primary">
      <div className="loginForm">
        <div className="loginHeader d-flex align-items-end justify-content-center">
          <img height={58} src={logo} className="mr-dot75 p-dot125" alt="logo"/>
        </div>
        <div className="login-field">
          <Input
            value={username}
            type="text"
            label="Email"
            error={usernameError}
            onBlur={valdateInput}
            onKeyUp={onKeyUp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
          />
        </div>
        <div className="login-field last">
          <Input
            value={password}
            label="Password"
            type="password"
            error={passwordError}
            onBlur={valdateInput}
            onKeyUp={onKeyUp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          />
        </div>
        <div className="loginFooter d-flex justify-content-between align-items-center fs-dot875">
          <Link to={PUBLIC_ROUTES.forgotPassword} className="text-white cursor-pointer">
            <u>Forgot password?</u>
          </Link>
          <div className="d-flex align-items-center">
            { loggingIn ? <div className="loaderWrapper mr-2"><Spinner /></div> : null }
            <button
              onClick={onSubmit}
              className={`submitBtn bg-primary text-white px-1dot375 py-2 ${loggingIn ? 'loading' : ''}`}
            >Login</button>
          </div>
        </div>
        <Link
          to={PUBLIC_ROUTES.signup}
          className="text-white d-block no-text-decor fs-dot875 lh-1dot125 px-3 py-2 mt-5 mx-2dot5 cursor-pointer signUpLink"
        >
          Are you a new user? Signup
        </Link>
      </div>
    </div>
  );
}

export default Login;
 