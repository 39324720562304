import axios from "axios";
import { ICommonQuery } from "custom-types";
import { constructCommonQuery } from "../../utils/appUtils";

export interface IWarehouseAPIQuery {
  name?: string;
  size?: string;
  user?: string;
}

export const fetchWarehouseInfo = (queryObj: IWarehouseAPIQuery & ICommonQuery) => axios.all([
  axios({
    method: 'get',
    url: `/drilldown/warehouse-info?${constructCommonQuery(queryObj, true)}`
  }),
  axios({
    method: 'get',
    url: `/drilldown/warehouse-exe-details?${constructCommonQuery(queryObj)}`
  })
]);

export const fetchWarehouseSizeInfo = (queryObj: IWarehouseAPIQuery & ICommonQuery) => axios({
  method: 'get',
  url: `/drilldown/warehouse-exe-details?${constructCommonQuery(queryObj)}`
});

export const fetchQuerySizeVsTimeByWarehouse = (queryObj: ICommonQuery) =>
  axios({
    method: 'get',
    url: `/performance/querySizeVsExecutionTime?${constructCommonQuery(queryObj)}`
  })

export const fetchQueryTimeTrendByWarehouse = (queryObj: ICommonQuery) =>
  axios({
    method: 'get',
    url: `/drilldown/numOfQueries?${constructCommonQuery(queryObj)}`
  })

export const fetchCostTimeTrendByWarehouse = (queryObj: ICommonQuery) =>
  axios({
    method: 'get',
    url: `/drilldown/costOverTimePeriod?${constructCommonQuery(queryObj)}`
  })

export const fetchQueryQueueTimeTrend = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/drilldown/queryQueqeTime?${constructCommonQuery(queryObj)}`
});

export const fetchUsageCostByTopWarehouse = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/drilldown/warehouse-usage-by-user?${constructCommonQuery(queryObj)}`
});

export const fetchQueries = (queryObj: ICommonQuery & IWarehouseAPIQuery) => axios({
  method: 'get',
  url: `/drilldown/queryDetails?${constructCommonQuery(queryObj)}`,
});

export const fetchUserInfo = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/drilldown/userInfo?${constructCommonQuery(queryObj)}`
})
