import { ICommonQuery } from 'custom-types';
import moment from 'moment';
import { compactInteger, formatNumber } from 'humanize-plus';
import { infoType } from '../constants/appConstants';

/**
 * Calculates the percentage from the params
 * @param value 
 * @param total 
 * @returns 
 */
export const percentageOf = (value: number, total: number) => {
  return (value / total) * 100;
}

export const constructCommonQuery = ({
  startDate,
  endDate,
  costPerCredit,
  users,
  warehouses,
  fetchBy,
  includedUsers,
  includedWarehouses,
  includedWarehouseSizes
}: ICommonQuery, ignoreQuote?: boolean) => {
  return `${startDate ? `startDate=${moment(startDate).format('M/DD/yyyy HH:mm')}` : ''
    }${endDate ? `&endDate=${moment(endDate).format('M/DD/yyyy HH:mm')}` : ''
    }${costPerCredit ? `&creditValue=${costPerCredit}` : ''
    }${users?.length ? `&excludedUserNames=${ignoreQuote ? '' : `'`}${users.join(ignoreQuote ? ',' : `','`)}${ignoreQuote ? '' : `'`}` : ''
    }${warehouses?.length ? `&excludedWarehouses=${ignoreQuote ? '' : `'`}${warehouses.join(ignoreQuote ? ',' : `','`)}${ignoreQuote ? '' : `'`}` : ''
    }${includedUsers?.length ? `&userName=${ignoreQuote ? '' : `'`}${includedUsers.join("','")}${ignoreQuote ? '' : `'`}` : ''
    }${includedWarehouses?.length ? `&warehouseName=${ignoreQuote ? '' : `'`}${includedWarehouses.join(ignoreQuote ? ',' : `','`)}${ignoreQuote ? '' : `'`}` : ''
    }${includedWarehouseSizes?.length ? `&warehouseSize=${ignoreQuote ? '' : `'`}${includedWarehouseSizes.join(ignoreQuote ? ',' : `','`)}${ignoreQuote ? '' : `'`}` : ''
    }${fetchBy ? `&fetchBy=${fetchBy}` : ''}`
}

export const formatPercentage = (value: number = 0, decimalDigits: number = 2) =>
  `${Number(value.toFixed(decimalDigits))}%`

export const formatCost = (value: number = 0, decimalDigits: number = 2) =>
  `$${value > 99999 ? compactInteger(value, 3) : formatNumber(Number((value || 0).toFixed(decimalDigits)), 2)}`

export const chunkArray = (array: any[], chunkSize: number) => {
  var chunkedArray = [];
  for (var i = 0; i < array.length; i += chunkSize)
  chunkedArray.push(array.slice(i, i + chunkSize));
  return chunkedArray;
}

export const getDateRangeFromURL = (location: Location) => {
  const search = new URLSearchParams(location.search);
  const startDate = Number(search.get('f'));
  const endDate = Number(search.get('t'));
  return { startDate, endDate };
}

export const getWarehouseFromURL = (location: Location) => {
  const search = new URLSearchParams(location.search);
  const warehouses = search.get('w');
  return warehouses ? warehouses.split(',') : undefined;
}

export const getWarehouseSizesFromURL = (location: Location) => {
  const search = new URLSearchParams(location.search);
  const sizes = search.get('ws');
  return sizes ? sizes.split(',') : undefined;
}

export const updateDateRangeFromURL = (history: any, startDate: number, endDate: number) => {
  const search = new URLSearchParams(history.location.search);
  search.set('f', String(startDate));
  search.set('t', String(endDate));
  history.replace({ ...history.location, search: search.toString() })
}

export const updateWarehouseInURL = (history: any, warehouse: string) => {
  const search = new URLSearchParams(history.location.search);
  search.set('w', String(warehouse));
  history.replace({ ...history.location, search: search.toString() })
}

export const updateWarehouseSizeInURL = (history: any, warehouseSizes: string) => {
  const search = new URLSearchParams(history.location.search);
  search.set('ws', String(warehouseSizes));
  history.replace({ ...history.location, search: search.toString() })
}

export const formatWarehouseURLParam = (warehouses: string[] = []) => {
  return `w=${warehouses.join(',')}`;
}

export const formatWarehouseSizesURLParam = (warehouseSizes: string[] = []) => {
  return `ws=${warehouseSizes.join(',')}`;
}

export const formatUserURLParam = (users: string[] = []) => {
  return `u=${users.join(',')}`;
}

export const formatDateRangeURLParam = (startDate: number, endDate: number) => {
  return `f=${startDate}&t=${endDate}`;
}

export const getUserFromURL = (location: Location) => {
  const search = new URLSearchParams(location.search);
  const usersParam = search.get('u');
  return usersParam?.length ? usersParam.split(',') : undefined;
}

export const updateUserInURL = (history: any, user: string) => {
  const search = new URLSearchParams(history.location.search);
  if (user) {
    search.set('u', String(user));
  } else if (search.get('u') && !user) {
    search.delete('u');
  }
  history.replace({ ...history.location, search: search.toString() })
}

export const formatByType = (value: string | number, type: string, options?: { deciimalDigits?: number; unit?: string;  }) => {
  switch (type) {
    case infoType.number:
      return formatNumber(value as number, options?.deciimalDigits);
    case infoType.text:
      return value || '';
    case infoType.dollar:
      return formatCost(value as number);
    case infoType.time:
      return formatTime(value as number, options?.unit as any);
    case infoType.date:
      const date = moment(value);
      return date.isValid() ? date.format('DD MMM, yyyy') : '';
    case infoType.datetime:
      const datetime = moment(value);
      return datetime.isValid() ? datetime.format('HH:mm:ss DD MMM, yyyy') : '';
    case infoType.dataSize:
      return formatDataSize(value as number, options?.unit || 'Mb');
    default:
      return value;
  }
}

export const formatDataSize = (value: number = 0, unit: string) => {
  let roundedval;
  try {
    roundedval = Number(value.toFixed(2));
  } catch (e) {
    roundedval = 0;
  }
  return <span>{roundedval} <span className="fs-dot875">{unit}</span></span>;
}

export const isURL = (testString: string) => {
  try {
    new URL(testString);
    return true;
  } catch(e) {
    return false;
  }
}

export const formatTime = (value: number, unit: 'sec' | 'ms' = 'sec') => {
  if (unit === 'ms') {
    value = value / 1000;
  }
  const hours = Math.floor(value / (60 * 60));
  const minutes = Math.floor((value - hours * 60 * 60) / 60);
  let seconds = hours || minutes ? value - hours * 60 * 60 - minutes * 60 : Math.floor(value - hours * 60 * 60 - minutes * 60);
  let ms = !(hours || minutes || seconds) ? Math.round((value - hours * 60 * 60 - minutes * 60 - seconds) * 1000) : 0;
  seconds = Number(seconds.toFixed(2));
  if (!(hours || minutes || seconds || ms)) {
    return '0';
  }
  return (
    <span>{<>
      { hours ?
        <span>{hours}<span className="fs-dot875">hr{ hours > 1 ? 's' : ''}</span>&nbsp;</span> : null }
      { minutes ?
        <span>{minutes}<span className="fs-dot875">min{ minutes > 1 ? 's' : ''}</span>&nbsp;</span> : null }
      { seconds ?
        <span>{seconds}<span className="fs-dot875">sec{ seconds > 1 ? 's' : ''}</span></span> : null }
      { ms ?
        <span>{ms}<span className="fs-dot875">ms</span></span> : null }
    </>}</span>
  );
}

export const isMobileDevice = ()=> {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  } catch (e) {
    return false;
  }
}


export const getNestedValue = (obj: object, key: string) => {
  try {
    const nestedKeys = key.split('.');
    let result: any = obj;
    for (const nestedKey of nestedKeys) {
      result = result[nestedKey];
      if (!result) {  
        break;
      }
    }
    return result;
  } catch (e) {
    return;
  }
} 

export const addZerosBefore = (num: number, digits: number): string => {
  let res = String(num);
  const length = res.length;
  if (length >= digits) { return res };
  for (let i = 1; i <= digits - length; i++) {
    res = '0' + res;
  }
  return res;
}
