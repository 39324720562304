import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, { components, OptionsType, ValueType } from 'react-select';
import Checkbox from '../Checkbox';
import Scrollbar from '../Scrollbar';
import Spinner from '../Spinner';
import {isMobileDevice} from '../../utils/appUtils'

import './Select.scss';

export interface IOption {
  label: string;
  value: string;
}

export type selectValueType = IOption | OptionsType<IOption>;
interface ISelect {
  options: IOption[];
  value?: IOption[];
  onChange: (value: ValueType<IOption, boolean>) => void;
  className?: string;
  isMulti?: boolean;
  subject?: string;
  subjectPlural?: string;
  loading?: boolean;
  icon?: string;
  iconWidth?: number;
}

const selectAllKey = 's_e_l_e_c_t_a_l_l';

const MenuList = (options: IOption[], selecteds: IOption[] = [], onApply: (options: IOption[]) => void) => {
  const [ selectedValues, setSelectedValues ] = useState(selecteds);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, option: IOption) => {
    const { checked } = e.target;
    if (option.value === selectAllKey) {
      if (checked) {
        setSelectedValues(options);
      } else {
        setSelectedValues([]);
      }
    } else {
      if (checked) {
        setSelectedValues(selectedValues.length === options.length - 2 ? options : [...selectedValues, option ])
      } else {
        setSelectedValues(selectedValues.filter(({value}) => (
          value !== option.value && (
            selectedValues.length !== options.length ||
            value !== selectAllKey
          )
        )));
      }
    }
  }
  const valueKeys = selectedValues.map(({value}) => value);
  const valueKeysStr = selectedValues.map(({value}) => value).sort().join();
  const actualValuesStr = selecteds.map(({value}) => value).sort().join();
  return (
    <>
    <div className="menuScroll" onClick={(e) => e.stopPropagation() }>
      <Scrollbar>
        <div className="pt-2">
          {
            (options as any).map((option: IOption, i: number) => {
              const {label, value} = option;
              const id = `${Date.now()}_${i}`;
              return (
                <label htmlFor={id} key={value} className="mb-0 px-2 py-2 d-flex align-items-center flex-nowrap customMenu cursor-pointer">
                  <Checkbox
                    id={id}
                    checked={valueKeys.includes(value)}
                    onChange={(e) => handleChange(e, option)}
                  />
                  <span>{label}</span>
                </label>
              );
            })
          }
        </div>
      </Scrollbar>
    </div>
    <div className="text-right px-2 py-2 fs-dot875">
      <button
        onClick={() => onApply(selectedValues)}
        className={
          `bg-primary text-white applyBtn \
          px-3 py-2 font-weight-bold ${actualValuesStr === valueKeysStr ? 'disabled' : ''}`}>
        Apply
      </button>
    </div>
    </>
  );
}

const MultiSelect = ({
  subject,
  subjectPlural,
  options: actualOptions,
  value,
  onChange,
  loading,
  icon,
  iconWidth,
  ...props
}: ISelect) => {

  const selectAllOption = useMemo(() => ({ value: selectAllKey, label: 'Select All' }), []);
  let options = [selectAllOption, ...actualOptions];

  if (((value?.length || 0) + 1) === options.length) {
    value = [selectAllOption, ...(value || [])];
  }

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const blockWindowClick: MutableRefObject<boolean> = useRef(false);

  const DropdownIndicator = useCallback((
    props: any
  ) => {
    return (
      <div className="d-flex align-items-center p-2">
        {/* <components.DropdownIndicator {...props}> */}
          { loading ?
          <Spinner width={14} height={14} />
          : <i className="customIndicator fa fa-caret-down" /> }
        {/* </components.DropdownIndicator> */}
      </div>
    );
  }, [loading]);

  const ValueContainer = useCallback((
    props: any
  ) => {
    const value = props.getValue();
    const valueCount = value.length;
    let displayText: string | React.ReactElement = `${valueCount} ${subjectPlural || subject || 'selected'}`;
    if (value.length > 1 && value.length === props.options.length) {
      displayText = subjectPlural || subject ? `All ${subjectPlural || subject}` : 'Selected All';
    } else if (value.length === 1) {
      displayText = value.length === 1 ? value[0].label : 'Select';
    } else if (value.length === 0) {
      displayText = <span className="text-light-grey">Select..</span>;
    }
    return (
      <components.ValueContainer {...props}>
        <div className="d-flex align-items-center no-wrap">
          {icon ? <img src={icon} width={iconWidth || 16} height={16} alt="icon" className="pr-1 selectIcon" /> : null}
          {displayText}
        </div>
      </components.ValueContainer>
    );
  }, [subject, subjectPlural, icon, iconWidth]);

  const Control = useCallback((props: any) => {
    const handleClickEvent = {
      [isMobileDevice() ? "onTouchStart" : "onClick"]: () => {
        blockWindowClick.current = true;
        setMenuIsOpen(!menuIsOpen);
      }
    };
  
    return (
      <span {...handleClickEvent}>
        <components.Control {...props} />
      </span>
    );
  }, [ menuIsOpen, blockWindowClick ]);

  const Option = useCallback((props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <div className="no-wrap">
            <Checkbox />
            <label className="m-0">{props.label}</label>
          </div>
        </components.Option>
      </div>
    );
  }, []);

  useEffect(() => {
    const closeMenu = (e: Event) => {
      if (blockWindowClick.current) {
        blockWindowClick.current = false;
      } else {
        setMenuIsOpen(false);
      }
    }
    window.addEventListener('click', closeMenu);
    return () => window.removeEventListener('click', closeMenu);
  }, [ menuIsOpen ])

  const handleChange = useCallback((options: IOption[]) => {
    setMenuIsOpen(false);
    onChange(options.filter(({value}: IOption) => selectAllKey !== value));
  }, [ onChange ]);

  return (
    <ReactSelect
      {...props}
      menuIsOpen={!loading && menuIsOpen}
      onMenuOpen={loading ? undefined : () => setMenuIsOpen(true)}
      onMenuClose={loading ? undefined : () => setMenuIsOpen(false)}
      value={value}
      options={options}
      classNamePrefix="custom-select"
      isClearable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti={true}
      components={{
        DropdownIndicator,
        ValueContainer,
        Option,
        Control,
        MenuList: () => MenuList(options, value as IOption[], handleChange)
      }}
    />
  );
}

export default MultiSelect;
