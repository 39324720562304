import React, { useState, useCallback, useEffect, useRef, RefObject, MutableRefObject } from "react";

import "./Scrollbar.scss";

interface IScrollbarProps {
  children: React.ReactElement;
  className?: string;
  assignRef?: (scrolllHost: RefObject<HTMLDivElement>) => void;
  showAlways?: boolean;
  onScroll?: () => void;
}

export default function Scrollbar(
  { children, className = '', assignRef, showAlways, onScroll }: IScrollbarProps) {
  const scrollHostRef: RefObject<HTMLDivElement> = useRef(null);
  const scrollContainerRef: RefObject<HTMLDivElement> = useRef(null);
  const xThumbRef: RefObject<HTMLDivElement> = useRef(null);
  const yThumbRef: RefObject<HTMLDivElement> = useRef(null);
  const contentWrapperRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    assignRef && assignRef(scrollHostRef);
  }, [ scrollHostRef, assignRef ]);

  useEffect(() => {
    const scrollHostElement = scrollHostRef.current;
    if (onScroll && scrollHostElement) {
      scrollHostElement.addEventListener('scroll', onScroll);
      return () => scrollHostElement.removeEventListener('scroll', onScroll);
    }
  }, [scrollHostRef, onScroll]);

  const hovering = useHoverState(scrollContainerRef);
  const [xThumbPosition, xThumbLength, draggingX, xOverflowed] = useThumbState(scrollHostRef, xThumbRef, contentWrapperRef, false);
  const [yThumbPosition, yThumbLength, draggingY, yOverflowed] = useThumbState(scrollHostRef, yThumbRef, contentWrapperRef, true);
  return (
    <div
      ref={scrollContainerRef}
      className={`scrollhost-container ${className}`}
    >
      <div
        ref={scrollHostRef}
        className="scrollhost"
      >
        <div className="d-inline-block" ref={contentWrapperRef}>{children}</div>
      </div>
      <div className={yOverflowed ? 'scroll-bar scrollY' : 'd-none'} style={{ opacity: hovering || draggingY || showAlways ? 1 : 0 }}>
        <div
          ref={yThumbRef}
          className={"scroll-thumb"}
          style={{ height: `${yThumbLength}px`, top: `${yThumbPosition}px` }}
        />
      </div>
      <div className={xOverflowed ? 'scroll-bar scrollX' : 'd-none'} style={{ opacity: hovering || draggingX || showAlways ? 1 : 0 }}>
        <div
          ref={xThumbRef}
          className={"scroll-thumb"}
          style={{ width: `${xThumbLength}px`, left: `${xThumbPosition}px` }}
        />
      </div>
    </div>
  );
}

// hooks for hover state on given ref element
const useHoverState = (elementRef: RefObject<HTMLElement>) => {
  const [hovering, setHovering] = useState(false);
  const handleMouseEnter = useCallback(() => {
    !hovering && setHovering(true);
  }, [hovering]);
  const handleMouseLeave = useCallback(() => {
    !!hovering && setHovering(false);
  }, [hovering]);
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [elementRef, hovering, handleMouseEnter, handleMouseLeave]);
  return hovering;
}

const useDragState = (elementRef: RefObject<HTMLElement>, onDragStart?: (e: MouseEvent) => void) => {
  const [dragging, setDragging] = useState(false);
  const handleMouseUp = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    !!dragging && setDragging(false);
  }, [dragging]);
  const handleMouseDown = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    !dragging && setDragging(true);
    onDragStart && onDragStart(e);
  }, [dragging, onDragStart]);
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mouseleave', handleMouseUp);
      return () => {
        element.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mouseleave', handleMouseUp);
      };
    }
  }, [elementRef, dragging, handleMouseDown, handleMouseUp]);
  return dragging;
}

const useThumbState = (scrollHostRef: RefObject<HTMLElement>, thumbRef: RefObject<HTMLElement>, contentWrapperRef: RefObject<HTMLElement>, isYthumb: boolean, scrollPosition?: number) => {
  const offsetLengthKey = isYthumb ? 'offsetHeight' : 'offsetWidth';
  const scrollLengthKey = isYthumb ? 'scrollHeight' : 'scrollWidth';
  const pointerPositionKey = isYthumb ? 'clientY' : 'clientX';
  const scrollPositionKey = isYthumb ? 'scrollTop' : 'scrollLeft';
  const dragStartPosition: MutableRefObject<number> = useRef(0);
  const handleDragStart = useCallback((e: MouseEvent) => {
    dragStartPosition.current = e[pointerPositionKey];
  }, [ pointerPositionKey ]);
  const dragging = useDragState(thumbRef, handleDragStart);
  const [thumbPosition, setThumbPosition] = useState(0);
  const [thumbLength, setThumbLength] = useState(0);
  const [overflowed, setOverflowed] = useState(false);

  const onMouseMove = useCallback((e: MouseEvent) => {
    if (dragging) {
      e.preventDefault();
      e.stopPropagation();
      const scrollHostElement = scrollHostRef.current as HTMLDivElement;
      const offsetLength = scrollHostElement[offsetLengthKey];
      const scrollLength = scrollHostElement[scrollLengthKey];
      const delta = e[pointerPositionKey] - dragStartPosition.current;
      const nxtThumbPosition = Math.max(Math.min(thumbPosition + delta, offsetLength - thumbLength), 0);
      setThumbPosition(nxtThumbPosition);
      dragStartPosition.current = dragStartPosition.current + delta;
      (scrollHostRef.current as HTMLElement)[scrollPositionKey] = (nxtThumbPosition / offsetLength) * scrollLength;
    }
  }, [
    dragStartPosition, scrollHostRef, thumbPosition, dragging,
    pointerPositionKey, offsetLengthKey, scrollLengthKey,
    scrollPositionKey, thumbLength
  ]);

  const updateThumbPosition = useCallback(() => {
    const scrollHostElement = scrollHostRef.current as HTMLElement;
    const offsetLength = scrollHostElement[offsetLengthKey];
    const scrollLength = scrollHostElement[scrollLengthKey];
    const scrollPosition = scrollHostElement[scrollPositionKey];
    setThumbPosition((scrollPosition / scrollLength) * offsetLength);
  }, [scrollHostRef, offsetLengthKey, scrollPositionKey, scrollLengthKey]);


  const updateThumbLength = useCallback(() => {
    const scrollHostElement = scrollHostRef.current;
    if (scrollHostElement) {
      const offsetLength = scrollHostElement[offsetLengthKey];
      const scrollLength = scrollHostElement[scrollLengthKey];
      setThumbLength((offsetLength / scrollLength) * offsetLength);
    }
  }, [scrollHostRef, offsetLengthKey, scrollLengthKey]);

  const updateIsOverflowed = useCallback(() => {
    const scrollHostElement = scrollHostRef.current;
    if (scrollHostElement) {
      const offsetLength = scrollHostElement[offsetLengthKey];
      const scrollLength = scrollHostElement[scrollLengthKey];
      const isOverflowed = scrollLength > offsetLength;
      isOverflowed !== overflowed && setOverflowed(isOverflowed);
    }
  }, [scrollHostRef, overflowed, offsetLengthKey, scrollLengthKey]);

  const handleScrollHostResize = useCallback(() => {
      updateThumbLength();
      updateIsOverflowed();
  }, [updateThumbLength, updateIsOverflowed]);

  useEffect(() => {
    const scrollHostElement = scrollHostRef.current;
    if (scrollHostElement) {
      document.addEventListener('mousemove', onMouseMove);
      scrollHostElement.addEventListener('scroll', updateThumbPosition, true);
      // @ts-ignore
      const resizeObserver = new ResizeObserver(
        handleScrollHostResize
      );
      resizeObserver.observe(contentWrapperRef.current as HTMLElement);
      return () => {
        resizeObserver.unobserve(scrollHostElement);
        resizeObserver.disconnect();
        document.removeEventListener('mousemove', onMouseMove);
        scrollHostElement.removeEventListener('scroll', updateThumbPosition, true);
      }
    }
  }, [onMouseMove, updateThumbPosition, scrollHostRef, handleScrollHostResize, contentWrapperRef]);

  // To calculate the overflow state of scroll host and to calculate the thumb length on init
  useEffect(() => {
    handleScrollHostResize();
  }, [ handleScrollHostResize ]);

  return [thumbPosition, thumbLength, dragging, overflowed];
}
