import { useLocation } from 'react-router';
import React, { MutableRefObject, useRef } from 'react';
import { Toaster } from 'react-hot-toast';

import AppNav from './components/AppNav';
import Scrollbar from './components/Scrollbar';
import Spinner from './components/Spinner';
import Demo from './components/Demo';
import Routes from './routes';
import { selectDashboardMeta, fetchDashboardMeta, selectTimezone, fetchTimezone } from './slice/app';
import { selectCanShowData } from './slice/demo';
import { selectIsUserLoggedIn } from './slice/user';
import { useAppDispatch, useAppSelector } from './store/hooks';

import '@fortawesome/fontawesome-free/css/all.css';
import './App.scss';

function App() {
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  // is demo URL
  const location = useLocation();
  const isDemoURL = location.pathname.startsWith('/demo');
  const canShowDemoData = useAppSelector(selectCanShowData);

  useAppInit(isUserLoggedIn, isDemoURL, canShowDemoData);

  return (
    <div className="App">
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <Scrollbar showAlways={true}>
        <>
          {isUserLoggedIn || isDemoURL ? <AppNav /> : null}
          {(canShowDemoData || !isDemoURL) ? <Routes /> : 
            <div className="d-flex align-items-center h-100 w-100 justify-content-center">
              <Spinner />
            </div> }
        </>
      </Scrollbar>
      <Demo />
    </div>
  );
}

export default App;

const useAppInit = (isUserLoggedIn: boolean, isDemo: boolean, canShowDemoData: boolean) => {
  const isInitialRenderDone: MutableRefObject<boolean> = useRef(false);
  const dispatch = useAppDispatch();
  const { isCacheAvailable: isDashboardMetaFetchedAlready } = useAppSelector(selectDashboardMeta);
  const { isCacheAvailable: isTimezoneFetchedAlready } = useAppSelector(selectTimezone);
  if ((!isUserLoggedIn && !isDemo) && isInitialRenderDone.current) {
    isInitialRenderDone.current = false;
  }
  if (!isInitialRenderDone.current && ((isUserLoggedIn && !isDemo) || (isDemo && canShowDemoData))) {
    !isDashboardMetaFetchedAlready && dispatch(fetchDashboardMeta());
    !isTimezoneFetchedAlready && dispatch(fetchTimezone());
    isInitialRenderDone.current = true;
  }
}
