import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import ReactSelect, { components, OptionsType, ValueType } from 'react-select';
import Spinner from '../Spinner';
import {isMobileDevice} from '../../utils/appUtils'

import './Select.scss';

export interface IOption {
  label: string;
  value: string;
}

export type selectValueType = IOption | OptionsType<IOption>;
interface ISelect {
  options: IOption[];
  value?: IOption;
  onChange: (value: ValueType<IOption, boolean>) => void;
  className?: string;
  isMulti?: boolean;
  subject?: string;
  subjectPlural?: string;
  loading?: boolean;
  icon?: string;
  iconWidth?: number;
}

const SingleSelect = ({
  subject,
  subjectPlural,
  options,
  value,
  onChange,
  loading,
  icon,
  iconWidth,
  ...props
}: ISelect) => {

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const blockWindowClick: MutableRefObject<boolean> = useRef(false);

  const Control = useCallback((props: any) => {
    const handleClickEvent = {
      [isMobileDevice() ? "onTouchStart" : "onClick"]: () => {
        blockWindowClick.current = true;
        setMenuIsOpen(!menuIsOpen);
      }
    };
    return (
      <span
        className="cursor-pointer"
        {...handleClickEvent}
      >
        <components.Control {...props} />
      </span>
    );
  }, [ menuIsOpen ]);

  const DropdownIndicator = useCallback((
    props: any
  ) => {
    return (
      <div className="d-flex align-items-center p-2">
         {/* <components.DropdownIndicator {...props}> */}
          { loading ?
          <Spinner width={14} height={14} />
          : <i className="customIndicator fa fa-caret-down" /> }
        {/* </components.DropdownIndicator> */}
      </div>
    );
  }, [loading]);

  const ValueContainer = useCallback((
    props: any
  ) => {
    const value = props.getValue();
    return (
      <components.ValueContainer {...props}>
        <div className="d-flex align-items-center no-wrap">
          {icon ? <img src={icon} width={iconWidth || 16} height={16} alt="icon" className="pr-1 selectIcon" /> : null}
          {value[0].value}
        </div>
      </components.ValueContainer>
    );
  }, [icon, iconWidth]);

  useEffect(() => {
    const closeMenu = (e: Event) => {
      if (blockWindowClick.current) {
        blockWindowClick.current = false;
      } else {
        setMenuIsOpen(false);
      }
    }
    window.addEventListener('click', closeMenu);
    return () => window.removeEventListener('click', closeMenu);
  }, [])

  return (
    <ReactSelect
      {...props}
      menuIsOpen={!loading && menuIsOpen}
      onMenuOpen={loading ? undefined : () => setMenuIsOpen(true)}
      onMenuClose={loading ? undefined : () => setMenuIsOpen(true)}
      value={value}
      options={options}
      classNamePrefix="custom-select"
      isClearable={false}
      closeMenuOnSelect={false}
      onChange={onChange}
      hideSelectedOptions={false}
      components={{
        DropdownIndicator,
        Control,
        ValueContainer
      }}
    />
  );
}

export default SingleSelect;
