import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import ReportLayout from '../../components/ReportLayout';
import LineTimeTrend from '../../components/Chart/LineTimeTrend';
import {
  fetchQueryCountByShiftHours,
  fetchQueryCountByUsers,
  fetchQueryCountByWarehouseName,
  fetchQueryCountByWarehouseSize,
  fetchQueryCountVsUsers,
  fetchQueryTrendByDate,
  fetchQueryTrendByWeekday,
  queryCountByWarehouseTogglerList,
  queryTrendTogglerList,
  selectQueryCountByShiftHours,
  selectQueryCountByUsers,
  selectQueryCountByWarehouseName,
  selectQueryCountByWarehouseSize,
  selectQueryCountByWarehouseToggler,
  selectQueryCountVsUsers,
  selectQueryTrendByDate,
  selectQueryTrendByWeekday,
  selectQueryTrendToggler,
  setQueryCountByWarehouseToggler,
  setQueryTrendToggler
} from '../../slice/performanceMetrics';
import BarChart from '../../components/Chart/BarChart';
import BarTimeTrend from '../../components/Chart/BarTimeTrend';
import DonutChart from '../../components/Chart/DonutChart';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { PROTECTED_ROUTES } from '../../constants/route';
import { formatDateRangeURLParam, formatUserURLParam, formatWarehouseSizesURLParam, formatWarehouseURLParam } from '../../utils/appUtils';

interface IQueryTrendProps {
  startDate: number;
  endDate: number;
  // users?: string[];
  // warehouses?: string[];
  // selectedUsers?: string[];
  // selectedWarehouses?: string[];
  query: object;
}

const QueryTrend = ({ startDate, endDate, query, /* users, warehouses, selectedUsers, selectedWarehouses */ }: IQueryTrendProps) => {
  const startDateRef: MutableRefObject<number> = useRef(startDate);
  const endDateRef: MutableRefObject<number> = useRef(endDate);
  // const selectedUsersRef: MutableRefObject<string[] | undefined> = useRef(selectedUsers);
  // const selectedWarehousesRef: MutableRefObject<string[] | undefined> = useRef(selectedWarehouses);
  // const usersRef: MutableRefObject<string[] | undefined> = useRef(users);
  // const warehousesRef: MutableRefObject<string[] | undefined> = useRef(warehouses);
  const dispatch = useAppDispatch();
  const [dateToggler]: [string, string] = ['Date', 'Weekday'];
  const queryTrendToggler = useAppSelector(selectQueryTrendToggler);

  const handleQueryTrendTogglerChange = useCallback((nxtToggler) => {
    dispatch(setQueryTrendToggler(nxtToggler));
  }, [ dispatch ]);

  const [nameToggler]: [string, string] = ['Name', 'Size'];
  const queryCountByWarehouseToggler = useAppSelector(selectQueryCountByWarehouseToggler);

  const handleQueryCountByWarehouseTogglerChange = useCallback((nxtToggler) => {
    dispatch(setQueryCountByWarehouseToggler(nxtToggler));
  }, [ dispatch ]);

  useEffect(() => {
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    // usersRef.current = users;
    // selectedUsersRef.current = selectedUsers;
    // warehousesRef.current = warehouses;
    // selectedWarehousesRef.current = selectedWarehouses;
  }, [startDate, endDate, /* users, selectedUsers, warehouses, selectedWarehouses */]);

  const queryCountByShiftHoursMeta = useMemo(() => ({
    valKeys: ['morningShift', 'genShift', 'nightShift'],
    colors: ['#DE81FF', '#5C6CBC', '#DABC52'],
    datasetLabels: ['Morning shift', 'General shift', 'Night shift']
  }), []);

  const queryTrendByDateMeta = useMemo(() => ({
    valKeys: ['QUERY_COUNT'],
    datasetLabels: ['Number of queries']
  }), []);

  // Navigation handlers
  const warehousePageLinkFormatter = useCallback((warehouse: string) => ({
    pathname: PROTECTED_ROUTES.warehousePerformance,
    search: `?${formatDateRangeURLParam(startDate, endDate)}&${formatWarehouseURLParam([warehouse])}`,
    state: { from: PROTECTED_ROUTES.performance }
  }), [startDate, endDate]);

  const sizePageLinkFormatter = useCallback((warehouseSize: string) => ({
    pathname: PROTECTED_ROUTES.warehouseSizePerformance,
    search: `?${formatDateRangeURLParam(startDate, endDate)}&${formatWarehouseSizesURLParam([warehouseSize])}`,
    state: { from: PROTECTED_ROUTES.performance }
  }), [startDate, endDate]);

  const userLinkFormatter = useCallback((user: string) => ({
    pathname: PROTECTED_ROUTES.userPerformance,
    search: `?${formatDateRangeURLParam(startDate, endDate)}&${formatUserURLParam([user])}`,
    state: { from: PROTECTED_ROUTES.performance }
  }), [startDate, endDate]);

  return (
    <>
      <div className="col-lg-6 col-12 reportWrapper p-dot75">
        <ReportLayout
          title="Query trend by"
          toggler={queryTrendTogglerList as [string, string]}
          onToggle={handleQueryTrendTogglerChange}
          activeToggler={queryTrendToggler}
        >
          {queryTrendToggler === dateToggler ?
            <LineTimeTrend
              key="trendByDate"
              getDataAction={fetchQueryTrendByDate}
              dataSelector={selectQueryTrendByDate}
              startDate={startDate}
              endDate={endDate}
              query={query}
              dateKey="DATE"
              hourKey="HOURS"
              valKeys={queryTrendByDateMeta.valKeys}
              xLabel="Time Period"
              yLabel="Number of queries"
              datasetLabels={queryTrendByDateMeta.datasetLabels}
              isArrayOfObj={true}
              enableChartOption={true}
            /> :
            <BarChart
              key="trendByWeekday"
              getDataAction={fetchQueryTrendByWeekday}
              dataSelector={selectQueryTrendByWeekday}
              query={query}
              xKey="queryCount"
              yKey="weekDay"
              xLabel="Number of queries"
              isHorizantal={true}
            />}
        </ReportLayout>
      </div>
      <div className="col-lg-6 col-12 reportWrapper p-dot75">
        <ReportLayout title="Query count by shift hours (PST)">
          <LineTimeTrend
            getDataAction={fetchQueryCountByShiftHours}
            dataSelector={selectQueryCountByShiftHours}
            startDate={startDate}
            endDate={endDate}
            query={query}
            dateKey="date"
            hourKey="hours"
            valKeys={queryCountByShiftHoursMeta.valKeys}
            colors={queryCountByShiftHoursMeta.colors}
            datasetLabels={queryCountByShiftHoursMeta.datasetLabels}
            xLabel="Time Period"
            yLabel="Number of queries"
            isArrayOfObj={true}
            enableChartOption={true}
          />
        </ReportLayout>
      </div>
      <div className="col-lg-6 col-12 reportWrapper topUsersReport p-dot75">
        <ReportLayout
          title="Query count by users"
          className="donutChartLayout"
        >
          <DonutChart
            getDataAction={fetchQueryCountByUsers}
            dataSelector={selectQueryCountByUsers}
            linkFormatter={userLinkFormatter}
            valueKey="numberOfQueries"
            totalKey="totalQueries"
            totalLabel="Total Queries"
            labelKey="name"
            legendMeta={[
              { key: 'name', label: 'User', order: 0, className: 'username' },
              { key: 'numberOfQueries', label: 'No. of queries', order: 1, className: 'queriesCount' },
              { key: 'percent', label: 'Queries in %', order: 1, className: 'queriesPercentage', isColored: true },
            ]}
            query={query}
          />
        </ReportLayout>
      </div>
      <div className="col-lg-6 col-12 reportWrapper p-dot75">
        <ReportLayout
          title="Query count by warehouse"
          toggler={queryCountByWarehouseTogglerList as [string, string]}
          onToggle={handleQueryCountByWarehouseTogglerChange}
          activeToggler={queryCountByWarehouseToggler}
        >
          {queryCountByWarehouseToggler === nameToggler ?
            <BarChart
              key="warehouseName"
              getDataAction={fetchQueryCountByWarehouseName}
              dataSelector={selectQueryCountByWarehouseName}
              query={query}
              xKey="numberOfQueries"
              yKey="name"
              xLabel="Number of queries"
              isHorizantal={true}
              linkFormatter={warehousePageLinkFormatter}
              enableCateogoryLink={true}
              /> :
            <BarChart
              key="warehouseSize"
              getDataAction={fetchQueryCountByWarehouseSize}
              dataSelector={selectQueryCountByWarehouseSize}
              query={query}
              yKey="size"
              xKey="numberOfQueries"
              // yLabel="Warehouse size"
              xLabel="Number of queries"
              isHorizantal={true}
              linkFormatter={sizePageLinkFormatter}
              enableCateogoryLink={true}
            />
          }
        </ReportLayout>
      </div>
      <div className="col-lg-6 col-12 reportWrapper p-dot75">
        <ReportLayout title="Query numbers vs users">
          <BarTimeTrend
            getDataAction={fetchQueryCountVsUsers}
            dataSelector={selectQueryCountVsUsers}
            dateKey="DATE"
            hourKey="HOURS"
            valKey="QUERY_COUNT"
            lineKey="USER_COUNT"
            query={query}
            startDate={startDate}
            endDate={endDate}
            xLabel="Time period"
            barLabel="Number of queries"
            lineLabel="Number of users"
            isArrayOfObj={true}
            enableChartOption={true}
          />
        </ReportLayout>
      </div>
    </>
  );
}

export default QueryTrend;
