import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonQuery } from 'custom-types';
import moment from 'moment';

import { RootState } from '../../store/store';
import * as warehouseAPI from './drilldownAPI';


export interface IUserInfo {
  NAME?: string;
  TOTAL_CREDITS: number;
  TOTAL_QUERIES: number;
  TOTAL_UNIQUE_QUERIES: number;
  MAX_QUERY_SIZE_MB: number;
  // MIN_QUERY_SIZE_MB: number;
  AVG_QUERY_SIZE_MB: number;
}

export interface IQueryTimeTrendByWarehouse {
  dates: string[];
  queryCount: number[];
}

export interface ICostTimeTrendByWarehouse {
  cost: number[];
  dates: string[];
}

export interface IUsageCostByTopWarehouse {
  warehouseName: string[];
  cost: number[];
}

export interface IQuerySizeVsTimeByWarehouse {
  querySize: number[];
  executionTime: number[];
}

export interface IQueryQueueTimeTrend {
  DATE: string;
  HOURS: number;
  QUEUED_TIME_SECS: number;
}

export interface IQueries {
  USER_NAME: string;
  QUERY_ID: string;
  QUERY: string;
  WAREHOUSE_ID: string;
  NO_OF_RECORDS: number;
  EXECUTION_TIME_SECONDS: number;
}

export interface IWarehouseInfo {
  totalQueries?: number;
  totalUsers?: number;
  totalCredits?: number;
  avgExecTimeInSec?: number;
  name?: string;
  size?: number;
  createdBy?: string;
  createdOn?: string;
}

export interface IWarehouseSizeInfo {
  size?: string;
  TOTAL_QUERIES?: number;
  TOTAL_USERS?: number;
  TOTAL_CREDITS?: number;
  AVG_EXECUTION_TIME_SECONDS?: number;
}

export interface IWarehouse {
  warehouseInfo: {
    value?: IWarehouseInfo;
    loading: boolean;
    initialFetchDone: boolean;
  },
  warehouseSizeInfo: {
    value?: IWarehouseSizeInfo;
    loading: boolean;
    initialFetchDone: boolean;
  },
  queryTimeTrendByWarehouse: {
    value?: IQueryTimeTrendByWarehouse;
    valueByHours?: IQueryTimeTrendByWarehouse;
    loading: boolean;
    initialFetchDone: boolean;
    hoursInitialFetchDone: boolean;
  };
  costTimeTrendByWarehouse: {
    value?: ICostTimeTrendByWarehouse;
    valueByHours?: ICostTimeTrendByWarehouse;
    loading: boolean;
    initialFetchDone: boolean;
    hoursInitialFetchDone: boolean;
  };
  usageCostByTopWarehouse: {
    value?: IUsageCostByTopWarehouse;
    loading: boolean;
    initialFetchDone: boolean;
  };
  querySizeVsTimeByWarehouse: {
    value?: IQuerySizeVsTimeByWarehouse;
    loading: boolean;
    initialFetchDone: boolean;
  };
  queries: {
    value?: IQueries;
    loading: boolean;
    initialFetchDone: boolean;
  },
  queryQueueTimeTrend: {
    value?: IQueryQueueTimeTrend[];
    valueByHours?: IQueryQueueTimeTrend[];
    loading: boolean;
    initialFetchDone: boolean;
    hoursInitialFetchDone: boolean;
  },
  userInfo: {
    value?: IUserInfo;
    loading: boolean;
    initialFetchDone:boolean;
  },
  startDate: number;
  endDate: number;
  selectedUsers: string[];
  selectedWarehouse?: string;
}

const getInitialReportsData = () => ({
  warehouseInfo: {
    loading: false,
    initialFetchDone: false
  },
  warehouseSizeInfo: {
    loading: false,
    initialFetchDone: false
  },
  userInfo: {
    loading: false,
    initialFetchDone: false
  },
  queryTimeTrendByWarehouse: {
    loading: false,
    initialFetchDone: false,
    hoursInitialFetchDone: false
  },
  costTimeTrendByWarehouse: {
    loading: false,
    initialFetchDone: false,
    hoursInitialFetchDone: false
  },
  queryQueueTimeTrend: {
    loading: false,
    initialFetchDone: false,
    hoursInitialFetchDone: false
  },
  usageCostByTopWarehouse: {
    loading: false,
    initialFetchDone: false
  },
  querySizeVsTimeByWarehouse: {
    loading: false,
    initialFetchDone: false
  },
  queries: {
    loading: false,
    initialFetchDone: false
  }
});

const initialState: IWarehouse = {
  ...getInitialReportsData(),
  startDate: moment().subtract(6, 'days').startOf('day').valueOf(),
  endDate: moment().endOf('day').valueOf(),
  selectedUsers: []
};

export const fetchWarehouseInfo = createAsyncThunk(
  'warehouseDrilldown/fetchWarehouseInfo',
  async (queryObj: ICommonQuery & warehouseAPI.IWarehouseAPIQuery) => {
    const [ basicInfo, execInfo ] = await warehouseAPI.fetchWarehouseInfo(queryObj);
    return {
      totalQueries: execInfo.data[0]?.TOTAL_QUERIES || 0,
      totalUsers: execInfo.data[0]?.TOTAL_USERS || 0,
      totalCredits: execInfo.data[0]?.TOTAL_CREDITS || 0,
      avgExecTimeInSec: execInfo.data[0]?.AVG_EXECUTION_TIME_SECONDS || 0,
      name: basicInfo.data.length ? basicInfo.data[0]?.name : undefined,
      size: basicInfo.data.length ? basicInfo.data[0]?.size : 0,
      createdBy: basicInfo.data.length ? basicInfo.data[0]?.owner : undefined,
      createdOn: basicInfo.data.length ? basicInfo.data[0]?.created_on : undefined,
    };
  }
);

export const fetchWarehouseSizeInfo = createAsyncThunk(
  'warehouseDrilldown/fetchWarehouseSizeInfo',
  async (queryObj: ICommonQuery & warehouseAPI.IWarehouseAPIQuery) => {
    const response = await warehouseAPI.fetchWarehouseSizeInfo(queryObj);
    return response.data[0];
  }
);

export const fetchUserInfo = createAsyncThunk(
  'warehouseDrilldown/fetchUserInfo',
  async (queryObj: ICommonQuery) => {
    const response = await warehouseAPI.fetchUserInfo(queryObj);
    return response.data[0];
  }
);

export const fetchQueryTimeTrendByWarehouse = createAsyncThunk(
  'warehouseDrilldown/fetchQueryTimeTrendByWarehouse',
  async (queryObj: ICommonQuery) => {
    const response = await warehouseAPI.fetchQueryTimeTrendByWarehouse(queryObj);
    return response.data;
  }
);

export const fetchQueryQueueTimeTrend = createAsyncThunk(
  'warehouseDrilldown/fetchQueryQueueTimeTrend',
  async (queryObj: ICommonQuery) => {
    const response = await warehouseAPI.fetchQueryQueueTimeTrend(queryObj);
    return response.data;
  }
);

export const fetchCostTimeTrendByWarehouse = createAsyncThunk(
  'warehouseDrilldown/fetchCostTimeTrendByWarehouse',
  async (queryObj: ICommonQuery) => {
    const response = await warehouseAPI.fetchCostTimeTrendByWarehouse(queryObj);
    return response.data;
  }
);

export const fetchUsageCostByTopWarehouse = createAsyncThunk(
  'warehouseDrilldown/fetchUsageCostByTopWarehouse',
  async (queryObj: ICommonQuery) => {
    const response = await warehouseAPI.fetchUsageCostByTopWarehouse(queryObj);
    return response.data;
  }
);

export const fetchQuerySizeVsTimeByWarehouse = createAsyncThunk(
  'warehouseDrilldown/fetchQuerySizeVsTimeByWarehouse',
  async (queryObj: ICommonQuery) => {
    const response = await warehouseAPI.fetchQuerySizeVsTimeByWarehouse(queryObj);
    return response.data;
  }
);

export const fetchQueries = createAsyncThunk(
  'warehouseDrilldown/fetchQueries',
  async (queryObj: ICommonQuery) => {
    const response = await warehouseAPI.fetchQueries(queryObj);
    return response.data;
  }
);

export const warehouseDrilldownSlice = createSlice({
  name: 'warehouseDrilldownSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedUsers: (state, action: PayloadAction<string[]>) => {
      state.selectedUsers = action.payload || [];
    },
    setSelectedWahouse: (state, action: PayloadAction<string>) => {
      state.selectedWarehouse = action.payload;
    },
    setDateRange: (state, action: PayloadAction<number[]>) => {
      state.startDate = action.payload[0];
      state.endDate = action.payload[1];
    },
    clearReportsData: (state) => {
      const {
        queryTimeTrendByWarehouse,
        costTimeTrendByWarehouse,
        usageCostByTopWarehouse,
        querySizeVsTimeByWarehouse,
        queries,
        queryQueueTimeTrend,
        warehouseInfo
      } = getInitialReportsData();
      state.queryTimeTrendByWarehouse = queryTimeTrendByWarehouse;
      state.costTimeTrendByWarehouse = costTimeTrendByWarehouse;
      state.usageCostByTopWarehouse = usageCostByTopWarehouse;
      state.querySizeVsTimeByWarehouse = querySizeVsTimeByWarehouse;
      state.queries = queries;
      state.queryQueueTimeTrend = queryQueueTimeTrend;
      state.warehouseInfo = warehouseInfo;
    },
    resetWarehouseDrilldown: (state) => state = initialState
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchQueryTimeTrendByWarehouse.pending, (state, action) => {
        state.queryTimeTrendByWarehouse.loading = true;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryTimeTrendByWarehouse.hoursInitialFetchDone = true;
        } else  {
          state.queryTimeTrendByWarehouse.initialFetchDone = true;
        }
      })
      .addCase(fetchQueryTimeTrendByWarehouse.fulfilled, (state, action) => {
        state.queryTimeTrendByWarehouse.loading = false;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryTimeTrendByWarehouse.valueByHours = action.payload;
        } else  {
          state.queryTimeTrendByWarehouse.value = action.payload;
        }
      }).addCase(fetchQueryTimeTrendByWarehouse.rejected, (state, action) => {
        state.queryTimeTrendByWarehouse.loading = false;
      })

      .addCase(fetchCostTimeTrendByWarehouse.pending, (state, action) => {
        state.costTimeTrendByWarehouse.loading = true;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.costTimeTrendByWarehouse.hoursInitialFetchDone = true;
        } else  {
          state.costTimeTrendByWarehouse.initialFetchDone = true;
        }
      })
      .addCase(fetchCostTimeTrendByWarehouse.fulfilled, (state, action) => {
        state.costTimeTrendByWarehouse.loading = false;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.costTimeTrendByWarehouse.valueByHours = action.payload;
        } else  {
          state.costTimeTrendByWarehouse.value = action.payload;
        }
      }).addCase(fetchCostTimeTrendByWarehouse.rejected, (state, action) => {
        state.costTimeTrendByWarehouse.loading = false;
      })

      .addCase(fetchQueryQueueTimeTrend.pending, (state, action) => {
        state.queryQueueTimeTrend.loading = true;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryQueueTimeTrend.hoursInitialFetchDone = true;
        } else {
          state.queryQueueTimeTrend.initialFetchDone = true;
        }
      })
      .addCase(fetchQueryQueueTimeTrend.fulfilled, (state, action) => {
        state.queryQueueTimeTrend.loading = false;
        if (action.meta.arg.fetchBy === 'HOURS') {
          state.queryQueueTimeTrend.valueByHours = action.payload;
        } else  {
          state.queryQueueTimeTrend.value = action.payload;
        }
      }).addCase(fetchQueryQueueTimeTrend.rejected, (state, action) => {
        state.queryQueueTimeTrend.loading = false;
      })

      .addCase(fetchQuerySizeVsTimeByWarehouse.pending, (state) => {
        state.querySizeVsTimeByWarehouse.loading = true;
        state.querySizeVsTimeByWarehouse.initialFetchDone = true;
      })
      .addCase(fetchQuerySizeVsTimeByWarehouse.fulfilled, (state, action) => {
        state.querySizeVsTimeByWarehouse.loading = false;
        state.querySizeVsTimeByWarehouse.value = action.payload;
      }).addCase(fetchQuerySizeVsTimeByWarehouse.rejected, (state, action) => {
        state.querySizeVsTimeByWarehouse.loading = false;
      })

      .addCase(fetchUsageCostByTopWarehouse.pending, (state) => {
        state.usageCostByTopWarehouse.loading = true;
        state.usageCostByTopWarehouse.initialFetchDone = true;
      })
      .addCase(fetchUsageCostByTopWarehouse.fulfilled, (state, action) => {
        state.usageCostByTopWarehouse.loading = false;
       state.usageCostByTopWarehouse.value = action.payload;
      }).addCase(fetchUsageCostByTopWarehouse.rejected, (state, action) => {
        state.usageCostByTopWarehouse.loading = false;
      })

      .addCase(fetchQueries.pending, (state) => {
        state.queries.loading = true;
        state.queries.initialFetchDone = true;
      })
      .addCase(fetchQueries.fulfilled, (state, action) => {
        state.queries.loading = false;
        state.queries.value = action.payload;
      }).addCase(fetchQueries.rejected, (state, action) => {
        state.queries.loading = false;
      })

      .addCase(fetchWarehouseInfo.pending, (state) => {
        state.warehouseInfo.loading = true;
        state.warehouseInfo.initialFetchDone = true;
      })
      .addCase(fetchWarehouseInfo.fulfilled, (state, action) => {
        state.warehouseInfo.loading = false;
        state.warehouseInfo.value = {
          ...action.payload,
          name: action.meta.arg.includedWarehouses?.length ? action.meta.arg.includedWarehouses[0] : (action.payload.name || undefined),
        };
      }).addCase(fetchWarehouseInfo.rejected, (state, action) => {
        state.warehouseInfo.loading = false;
      })

      .addCase(fetchWarehouseSizeInfo.pending, (state) => {
        state.warehouseSizeInfo.loading = true;
        state.warehouseSizeInfo.initialFetchDone = true;
      })
      .addCase(fetchWarehouseSizeInfo.fulfilled, (state, action) => {
        state.warehouseSizeInfo.loading = false;
        state.warehouseSizeInfo.value = {...action.payload, SIZE: action.meta.arg.includedWarehouseSizes?.length ? action.meta.arg.includedWarehouseSizes[0] : undefined};
      }).addCase(fetchWarehouseSizeInfo.rejected, (state, action) => {
        state.warehouseSizeInfo.loading = false;
      })

      .addCase(fetchUserInfo.pending, (state) => {
        state.userInfo.loading = true;
        state.userInfo.initialFetchDone = true;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.userInfo.loading = false;
        state.userInfo.value = {...action.payload, NAME: action.meta.arg.includedUsers?.length ? action.meta.arg.includedUsers[0] : undefined};
      }).addCase(fetchUserInfo.rejected, (state, action) => {
        state.userInfo.loading = false;
      })
  },
});

export const {
  clearReportsData, setSelectedUsers, setSelectedWahouse, setDateRange,
  resetWarehouseDrilldown
} = warehouseDrilldownSlice.actions;

export const selectQueryTimeTrendByWarehouse = (state: RootState) => state.warehouseDrilldown.queryTimeTrendByWarehouse;
export const selectQueryQueueTimeTrendByWarehouse = (state: RootState) => state.warehouseDrilldown.queryQueueTimeTrend;
export const selectCostTimeTrendByWarehouse = (state: RootState) => state.warehouseDrilldown.costTimeTrendByWarehouse;
export const selectQuerySizeVsTimeByWarehouse = (state: RootState) => state.warehouseDrilldown.querySizeVsTimeByWarehouse;
export const selectUsageCostByTopWarehouse = (state: RootState) => state.warehouseDrilldown.usageCostByTopWarehouse;
export const selectQueries = (state: RootState) => state.warehouseDrilldown.queries;
export const selectWarehouseInfo = (state: RootState) => state.warehouseDrilldown.warehouseInfo;
export const selectWarehouseSizeInfo = (state: RootState) => state.warehouseDrilldown.warehouseSizeInfo;
export const selectUserInfo = (state: RootState) => state.warehouseDrilldown.userInfo;
export const selectStartDate = (state: RootState) => state.warehouseDrilldown.startDate;
export const selectEndDate = (state: RootState) => state.warehouseDrilldown.endDate;
export const selectSelectedUsers = (state: RootState) => state.warehouseDrilldown.selectedUsers;
export const selectSelectedWarehouse = (state: RootState) => state.warehouseDrilldown.selectedWarehouse;


export default warehouseDrilldownSlice.reducer;
 