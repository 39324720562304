import { Chart, registerables } from 'chart.js';
import React, { MutableRefObject, RefObject, useEffect, useMemo, useRef } from 'react';
import { ThunkAction } from 'redux-thunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import Spinner from '../Spinner';
import NoDataTemplate from '../NoDataTemplate';
import NoDataIcon from '../../assets/image/no-data-bar.svg';
import { Link } from 'react-router-dom';
import { formatCost } from '../../utils/appUtils';

Chart.register(...registerables);
Chart.defaults.font.family = 'Mulish';
Chart.defaults.font.size = 12;

interface IBarChartProps {
  xLabel?: string;
  yLabel?: string;
  query: any;
  getDataAction: (query: any) => ThunkAction<any, any, any, any>;
  dataSelector: (state: RootState)  => any;
  isHorizantal?: boolean;
  enableCateogoryLink?: boolean;
  xKey: string;
  yKey: string;
  dataType?: 'dollar' | 'number';
  isArrayOfObjects?: boolean;
  linkFormatter?: (label: string) => any;
}

const BarChart = ({
  xLabel = '',
  yLabel = '',
  query,
  isHorizantal,
  enableCateogoryLink,
  getDataAction,
  dataSelector,
  isArrayOfObjects,
  xKey,
  yKey,
  linkFormatter,
  dataType
}: IBarChartProps) => {
  const isInitialRenderDone: MutableRefObject<boolean> = useRef(false);
  const queryRef: MutableRefObject<any> = useRef(query);
  const dispatch = useAppDispatch();
  const canvasElement: RefObject<HTMLCanvasElement> = useRef(null);
  const linksContainer: RefObject<HTMLDivElement> = useRef(null);
  const { value, loading, initialFetchDone } = useAppSelector(dataSelector);
  let links = [];
  if (value && enableCateogoryLink) {
    links = isArrayOfObjects ? value.map((obj: any) => obj[yKey]) : value[yKey];
  }
  const memoizedData = useMemo(() => {
    if (!loading && value && typeof value === 'object') {
      let x, y;
      if (isArrayOfObjects) {
        x = value.map((obj: any) => obj[xKey]);
        y = value.map((obj: any) => obj[yKey]);
      } else {
        x = value[xKey];
        y = value[yKey];
      }
      return x.length && y.length ? {
        type: 'bar' as any,
        data: {
          labels: isHorizantal ? y : x,
          datasets: [
            {
              label: isHorizantal ? xLabel : yLabel,
              data: isHorizantal ? x : y,
              backgroundColor: "#FC7D58",
              maxBarThickness: 22
            },
          ]
        },
        plugins: [ { 
          id: 'linkRenderer',
          beforeBuildTicks: () => {
            if (linksContainer.current) {
              linksContainer.current.style.height = 'unset';
              linksContainer.current.style.width = 'unset';
              linksContainer.current.style.opacity = '0';
            }
          },
          afterBuildTicks: (chart: any) => {
            const height = chart?.scales?.y?.height;
            const width = chart?.scales?.y?.width;
            if (linksContainer.current && height && width) {
              linksContainer.current.style.height = height + 'px';
              linksContainer.current.style.width = width + 'px';
              linksContainer.current.style.opacity = '1';
            }
          }
        }],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          // animations: false,
          indexAxis: isHorizantal ? 'y' : 'x',
          elements: {
            bar: { borderRadius: 2 }
          },
          scales: {
            y: {
              grid: {
                display: false,
                drawBorder: false
              },
              title: {
                display: !!yLabel,
                text: yLabel,
                color: '#A8A9AD'
              },
              ticks: {
                ...(dataType === 'dollar' && !isHorizantal ?
                  { callback: ((value: number) => `$${value || 0}`) }
                  : {}
                )
              },
            },
            x: {
              grid: {
                display: false,
                borderDash: [5, 5],
                color: '#ECF0F4',
                drawBorder: false
              },
              ticks: {
                ...(dataType === 'dollar' && isHorizantal ?
                  { callback: ((value: number) => `$${value || 0}`) }
                  : {}
                )
              },
              title: {
                display: !!xLabel,
                text: xLabel,
                color: '#A8A9AD'
              }
            }
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                ...(dataType === 'dollar' ? 
                  {label: (data: any) => `${isHorizantal ? xLabel : yLabel}: ${formatCost(data?.raw)}`}
                  : {}
                )
              }
            }
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
            axis: isHorizantal ? 'y' : 'x'
          }
        }
      } : undefined
    }
  }, [
    loading,
    value,
    xLabel,
    yLabel,
    isHorizantal,
    isArrayOfObjects,
    xKey,
    yKey,
    linksContainer,
    dataType
  ]);
  useEffect(() => {
    if (!(isInitialRenderDone.current || initialFetchDone)) {
      isInitialRenderDone.current = true;
      dispatch(getDataAction(query));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (query !== queryRef.current) {
      queryRef.current = query;
      dispatch(getDataAction(query));
    }
  });
  useEffect(() => {
    if (memoizedData) {
      const chart = new Chart(canvasElement.current as HTMLCanvasElement, memoizedData);
      return () => {
        if (linksContainer.current) {
          linksContainer.current.style.height = 'unset';
          linksContainer.current.style.width = 'unset';
          // eslint-disable-next-line react-hooks/exhaustive-deps
          linksContainer.current.style.opacity = '0';
        }
        chart.destroy();
      }
    }
  }, [memoizedData]);

  useEffect(() => {
    isInitialRenderDone.current = true;
  }, []);
  
  return (
    <div className="position-relative w-100 h-100">
      { enableCateogoryLink && !loading ?
        <div className="position-absolute">
          <div className="position-relative" ref={linksContainer} style={{ opacity: 0 }}>
            { links.map((label: string, i: number) => (
              <div
                key={`${label}_${i}`}
                className="fs-dot75 position-absolute text-right w-100 bg-white left-0"
                style={{
                  top: `${(100 / (links.length * 2)) * ((i + 1) * 2 - 1) }%`,
                  paddingRight: `11px`,
                }}
              >
                { linkFormatter ? <Link to={linkFormatter(label)}>
                  {label}
                </Link> : label }
              </div>
            )) }
          </div>
        </div> : null }
      { !memoizedData && !loading ? <NoDataTemplate icon={NoDataIcon} /> : null }
      { loading ?
        <div className="w-100 h-100 bg-white position-absolute d-flex align-items-center justify-content-center">
          <Spinner />
        </div> : null }
      <canvas ref={canvasElement} />
    </div>
  );
}

export default BarChart;
