import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../slice/counter';
import costMetricsReducer from '../slice/costMetrics';
import queryMetricsReducer from '../slice/queryMetrics';
import performanceMetricsReducer from '../slice/performanceMetrics';
import dateDrilldownReducer from '../slice/drilldown/date';
import userDrilldownReducer from '../slice/drilldown/user';
import warehouseDrilldownReducer from '../slice/drilldown/warehouse';
import warehouseSizeDrilldownReducer from '../slice/drilldown/warehouseSize';
import demoReducer from '../slice/demo';
import userReducer from '../slice/user';
import appReducer from '../slice/app';

export const store = configureStore({
  reducer: {
    app: appReducer,
    counter: counterReducer,
    costMetrics: costMetricsReducer,
    queryMetrics: queryMetricsReducer,
    performanceMetrics: performanceMetricsReducer,
    dateDrilldown: dateDrilldownReducer,
    userDrilldown: userDrilldownReducer,
    warehouseDrilldown: warehouseDrilldownReducer,
    warehouseSizeDrilldown: warehouseSizeDrilldownReducer,
    user: userReducer,
    demo: demoReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
