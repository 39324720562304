import { unwrapResult } from '@reduxjs/toolkit';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import { PUBLIC_ROUTES } from '../../constants/route';
import Input from '../../components/Input';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSigningUp, signup } from '../../slice/user';
import logo from '../../assets/image/logo.svg';
import arrowLeft from '../../assets/image/arrow-left-o.svg';

import './Login.scss';

const Signup = () => {
  const history = useHistory();
  const [ username, setUsername ] = useState('');
  const [ accessLink, setAccessLink ] = useState('');
  const [ usernameError, setUsernameError ] = useState('');
  const [ accessLinkError, setAccessLinkError ] = useState('');
  const dispatch = useAppDispatch();
  const signingUp = useAppSelector(selectSigningUp);

  const valdateInput = () => {
    let usernameValid = false;
    let accessLinkValid = false;
    if (!username) {
      setUsernameError('Please enter your email address');
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(username)) {
      setUsernameError('Please enter a valid email address');
    } else {
      setUsernameError('');
      usernameValid = true;
    }
    if (!accessLink) {
      setAccessLinkError('Please enter your access link');
    // eslint-disable-next-line no-useless-escape
    } else if (!accessLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
      setAccessLinkError('Please enter a valid access link');
    } else {
      setAccessLinkError('');
      accessLinkValid = true;
    }
    return usernameValid && accessLinkValid;
  }

  const onSubmit = () => {
    if (valdateInput()) {
      dispatch(signup({ email: username, snowflakeAccessUrl: accessLink }))
        .then(unwrapResult)
        .then(() => history.push(PUBLIC_ROUTES.login));
    }
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  }

  return (
    <div className="loginContainer d-flex justify-content-center align-items-center bg-primary">
      <div className="loginForm">
        <div className="loginHeader d-flex align-items-end justify-content-center">
          <img height={58} src={logo} className="mr-dot75 p-dot125" alt="logo"/>
        </div>
        <div className="mb-3 fs-1dot25 text-white font-weight-bold lh-1dot625">Signup</div>
        <div className="login-field">
          <Input
            value={username}
            type="text"
            label="Email"
            error={usernameError}
            onBlur={valdateInput}
            onKeyUp={onKeyUp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
          />
        </div>
        <div className="login-field">
          <Input
            value={accessLink}
            type="text"
            label="Snowflake link to be accessed"
            error={accessLinkError}
            onBlur={valdateInput}
            onKeyUp={onKeyUp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccessLink(e.target.value)}
          />
        </div>
        <div className="mt-2 fs-dot75 lh-1 text-white text-left">
          Your password will be sent to your mail id soon after you signup.
        </div>
        <div className="loginFooter mt-3 d-flex justify-content-between align-items-center fs-dot875">
          <Link
            to={PUBLIC_ROUTES.login}
            className="d-flex align-items-center text-white cursor-pointer lh-1dot125"
          >
            <img src={arrowLeft} className="mr-dot375" alt="" width={12} height={12} />
            Back to login
          </Link>
          <div className="d-flex align-items-center">
            { signingUp ? <div className="loaderWrapper mr-2"><Spinner width={16} height={16} /></div> : null }
            <button
              onClick={onSubmit}
              className={`submitBtn bg-primary text-white px-1dot375 py-2 ${signingUp ? 'loading' : ''}`}
            >Signup</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
 