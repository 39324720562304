import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoginData, ISignupData, IChangePasswordData} from 'custom-types';
import { toast } from 'react-hot-toast';

import { RootState } from '../../store/store';
import * as userAPI from './userAPI';


export interface IUser {
  _id: string;
  email: string;
}

export interface IUserSlice {
  email?: string;
  _id?: string;
  token?: string | null;
  loggingIn: boolean;
  signingUp: boolean;
  requestingNewPassword: boolean;
  switchingAccount: boolean;
  requestingAddAccount: boolean;
  changingPassword: boolean;
}

const getInitialState = () => ({
  loggingIn: false,
  signingUp: false,
  requestingNewPassword: false,
  token: localStorage.getItem('token'),
  switchingAccount: false,
  requestingAddAccount: false,
  changingPassword: false
});

const initialState: IUserSlice = getInitialState();

export const login = createAsyncThunk(
  'user/login',
  async (data: ILoginData) => {
    try {
      const response = await userAPI.login(data);
      return response.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const signup = createAsyncThunk(
  'user/signup',
  async (data: ISignupData) => {
    try {
      const response = await userAPI.signup(data);
      return response.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const logout = createAsyncThunk(
  'user/logout',
  async () => {
    const response = await userAPI.logout();
    return response.data;
  }
);

export const requestNewPassword = createAsyncThunk(
  'user/requestNewPassword',
  async (email: string) => {
    try {
      const response = await userAPI.requestNewPassword(email);
      return response.data;
    } catch (e) {
      throw new Error(e.response?.data?.code === 404 ? 'Email is not registered.' : 'Something went wrong');
    }
  }
);

export const switchAccount = createAsyncThunk(
  'user/switchAccount',
  async (accountName: string) => {
    try {
      const response = await userAPI.switchAccount(accountName);
      return response.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const reqAddAccount = createAsyncThunk(
  'user/reqAddAccount',
  async (snowflakeAccessUrl: string) => {
    try {
      const response = await userAPI.reqAddAccount(snowflakeAccessUrl);
      return response.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (data: IChangePasswordData) => {
    try {
      const response = await userAPI.changePassword(data);
      return response.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    resetUser: (state) => {
      state = initialState;
      localStorage.removeItem('token');
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loggingIn = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.email = action.payload.email;
        state._id = action.payload._id;
        state.token = action.payload.token;
      }).addCase(login.rejected, (state, action) => {
        toast.error(action.error?.message || 'Something went wrong');
        state.loggingIn = false;
      })

      .addCase(logout.pending, (state) => {
      })
      .addCase(logout.fulfilled, (state, action) => {
        localStorage.removeItem('token');
        state.token = undefined;
        state._id = undefined;
        state.email = undefined;
      }).addCase(logout.rejected, (state, action) => {
        localStorage.removeItem('token');
        state.token = undefined;
        state._id = undefined;
        state.email = undefined;
      })

      .addCase(signup.pending, (state) => {
        state.signingUp = true;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.signingUp = false;
        toast.success(action.payload.message);
      }).addCase(signup.rejected, (state, action) => {
        state.signingUp = false;
        toast.error(action.error?.message || 'Something went wrong');
      })

      .addCase(requestNewPassword.pending, (state) => {
        state.requestingNewPassword = true;
      })
      .addCase(requestNewPassword.fulfilled, (state, action) => {
        state.requestingNewPassword = false;
        toast.success(action.payload.message);
      }).addCase(requestNewPassword.rejected, (state, action) => {
        state.requestingNewPassword = false;
        toast.error(action.error?.message || 'Something went wrong');
      })

      .addCase(switchAccount.pending, (state) => {
        state.switchingAccount = true;
      })
      .addCase(switchAccount.fulfilled, (state, action) => {
        state.switchingAccount = false;
        toast.success(action.payload.message);
      }).addCase(switchAccount.rejected, (state, action) => {
        state.switchingAccount = false;
        toast.error(action.error?.message || 'Something went wrong');
      })

      .addCase(reqAddAccount.pending, (state) => {
        state.requestingAddAccount = true;
      })
      .addCase(reqAddAccount.fulfilled, (state, action) => {
        state.requestingAddAccount = false;
        toast.success(action.payload.message);
      }).addCase(reqAddAccount.rejected, (state, action) => {
        state.requestingAddAccount = false;
        toast.error(action.error?.message || 'Something went wrong');
      })

      .addCase(changePassword.pending, (state) => {
        state.changingPassword = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changingPassword = false;
        toast.success(action.payload.message);
      }).addCase(changePassword.rejected, (state, action) => {
        state.changingPassword = false;
        toast.error(action.error?.message || 'Something went wrong');
      })
  },
});

export const { setToken, resetUser } = userSlice.actions;

export const selectUserEmail = (state: RootState) => state.user.email;
export const selectUserId = (state: RootState) => state.user._id;
export const selectIsUserLoggedIn = (state: RootState) => Boolean(state.user.token);
export const selectLoggingIn = (state: RootState) => state.user.loggingIn;
export const selectSigningUp = (state: RootState) => state.user.signingUp;
export const selectRequestingNewPassword = (state: RootState) => state.user.requestingNewPassword;
export const selectSwitchingAccount = (state: RootState) => state.user.switchingAccount;
export const selectRequestingAddAccount = (state: RootState) => state.user.requestingAddAccount;
export const selectChangingPassword = (state: RootState) => state.user.changingPassword;

export default userSlice.reducer;
 