import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonQuery } from 'custom-types';
import moment from 'moment';

import * as costMetricsAPI from './costMetricsAPI';
import { fetchDashboardMeta } from '../app';
import { RootState } from '../../store/store';

export interface IInsight {
  value?: string;
  desc: string;
}
export interface ITopDatesByUsageCost {
  dates: string[];
  cost: number[];
}

export interface ITopWarehouseByUsageCost {
  warehouseName: string[];
  cost: number[];
}

export interface ITopUsersByUsageCost {
  userName: string[];
  usageCost: number[];
  totalCostPercent: number[];
  totalUsageCost: number;
}

export interface IAverageDailyCost {
  cardsValue: {
    avgDailyCost: number;
    comparedPercent: number;
  };
  queryTrendData: {
    date: string[];
    avgCost: number[];
  };
}

export interface ITotalUsageCost {
  cardsValue: {
    totalUsageCost: number;
    comparedPercent: number;
  };
  queryTrendData: {
    date: string[];
    cost: number[];
  };
}

export interface IWarehouseSizeVsUsageCost {
  warehouseSize: string[];
  cost: number[];
}

type UsageCostByWarehouseTogglerType = 'Name' | 'Size';

export const usageCostByWarehouseTogglerList = [ 'Name', 'Size' ];
export const defaultCostPerCredit = 3.75;

export interface ICostMetrics {
  totalUsageCost: {
    value?: ITotalUsageCost;
    loading: boolean;
    initialFetchDone: boolean;
  };
  averagetDailyCost: {
    value?: IAverageDailyCost;
    loading: boolean;
    initialFetchDone: boolean;
  };
  topDatesByUsageCost: {
    value?: ITopDatesByUsageCost,
    loading: boolean;
    initialFetchDone: boolean;
  };
  topUsersByUsageCost: {
    value?: ITopUsersByUsageCost,
    loading: boolean;
    initialFetchDone: boolean;
  };
  topWarehouseByUsageCost: {
    value?: ITopWarehouseByUsageCost,
    loading: boolean;
    initialFetchDone: boolean;
  };
  warehouseSizeVsUsageCost: {
    value?: IWarehouseSizeVsUsageCost,
    loading: boolean;
    initialFetchDone: boolean;
  };
  complexQueriesInsight: {
    value?: IInsight;
    loading: boolean;
    initialFetchDone: boolean;
  };
  higherUsageUserInsights: {
    value?: IInsight;
    loading: boolean;
    initialFetchDone: boolean;
  };
  overloadWHInsights: {
    value?: IInsight;
    loading: boolean;
    initialFetchDone: boolean;
  };
  complexQueries: {
    value?: IQueries[];
    loading: boolean;
    initialFetchDone: boolean;
  };
  higherUsageUsers: {
    value?: IQueries[];
    loading: boolean;
    initialFetchDone: boolean;
  };
  overloadWHQueries: {
    value?: IQueries[];
    loading: boolean;
    initialFetchDone: boolean;
  };
  startDate: number;
  endDate: number;
  usageCostByWarehouseToggler: UsageCostByWarehouseTogglerType;
  costPerCredit: number;
  selectedUsers: string[];
  selectedWarehouses: string[];
  isInsightsDismissed: boolean;
  isInsightsCollapsed: boolean;
}

interface IQueries {
  USER_NAME: string;
  QUERY_ID: string;
  QUERY_TEXT?: string;
  WAREHOUSE_NAME: string;
  EXECUTION_TIME: number;
  START_TIME: string;
  END_TIME: string;
}

const getReportsData = () => ({
  totalUsageCost: {
    loading: false,
    initialFetchDone: false
  },
  averagetDailyCost: {
    loading: false,
    initialFetchDone: false
  },
  topDatesByUsageCost: {
    loading: false,
    initialFetchDone: false
  },
  topUsersByUsageCost: {
    loading: false,
    initialFetchDone: false
  },
  topWarehouseByUsageCost: {
    loading: false,
    initialFetchDone: false
  },
  warehouseSizeVsUsageCost: {
    loading: false,
    initialFetchDone: false
  },
  users: {
    loading: false,
    initialFetchDone: false
  },
  warehouses: {
    loading: false,
    initialFetchDone: false
  },
  warehouseSizes: {
    loading: false,
    initialFetchDone: false
  },
  complexQueriesInsight: {
    loading: false,
    initialFetchDone: false
  },
  higherUsageUserInsights: {
    loading: false,
    initialFetchDone: false
  },
  overloadWHInsights: {
    loading: false,
    initialFetchDone: false
  },
  complexQueries: {
    loading: false,
    initialFetchDone: false
  },
  higherUsageUsers: {
    loading: false,
    initialFetchDone: false
  },
  overloadWHQueries: {
    loading: false,
    initialFetchDone: false
  }
});

const initialState: ICostMetrics = {
  ...getReportsData(),
  startDate: moment().subtract(6, 'days').startOf('day').valueOf(),
  endDate: moment().endOf('day').valueOf(),
  usageCostByWarehouseToggler: 'Name',
  costPerCredit: defaultCostPerCredit,
  selectedUsers: [],
  selectedWarehouses: [],
  isInsightsDismissed: false,
  isInsightsCollapsed: false
};

export const fetchComparitiveCostInsights = createAsyncThunk(
  'costMetrics/fetchComparitiveCostInsights',
  async (queryObj: ICommonQuery) => {
    try {

      const { data: { cardsValue: [ cardsValue ], queryTrendData }} = await costMetricsAPI.fetchComparitiveCostInsights(queryObj);
      const totalUsageCost: ITotalUsageCost = {
        cardsValue: {
          totalUsageCost: cardsValue?.CURR_TOT_CREDITS_USED || 0,
          comparedPercent: cardsValue?.TOT_CREDITS_PERC_INCREASE || 0
        },
        queryTrendData: {
          date: [],
          cost: []
        }
      };
      const averagetDailyCost: IAverageDailyCost = {
        cardsValue: {
          avgDailyCost: cardsValue?.CURR_AVG_CREDITS_USED || 0,
          comparedPercent: cardsValue?.AVG_CREDITS_PERC_INCREASE || 0
        },
        queryTrendData: {
          date: [],
          avgCost: []
        }
      };
      (queryTrendData || []).forEach(({ DATE, CREDITS_USED, AVG_CREDITS_USED }: any) => {
        totalUsageCost.queryTrendData.date.push(DATE);
        totalUsageCost.queryTrendData.cost.push(CREDITS_USED);
        averagetDailyCost.queryTrendData.date.push(DATE);
        averagetDailyCost.queryTrendData.avgCost.push(AVG_CREDITS_USED);
      });
      return { totalUsageCost, averagetDailyCost };
    } catch (e) {
      throw e;
    }
  }
);

export const fetchComplexQueriesInsight = createAsyncThunk(
  'costMetrics/fetchComplexQueriesInsight',
  async () => {
    let response = await costMetricsAPI.fetchComplexQueriesInsight();
    const [ query1, query2 ] = response.data || [];
    let data;
    if (query1 && query2) {
      data = {
        value: `${Number((query1.QUERY_PERC || 0).toFixed(2))}% of queries can be optimized to reduce cost`,
        desc: `${query1.QUERY_ID}, ${query2.QUERY_ID} are queries \
        with highest difference between compilation and execution times.`
      }
    }
    return data;
  }
);

export const fetchHigherUsageuserInsight = createAsyncThunk(
  'costMetrics/fetchHigherUsageuserInsight',
  async () => {
    const response = await costMetricsAPI.fetchHigherUsageuserInsight();
    const [ query ] = response.data || [];
    let data;
    if (query) {
      data = {
        value: `${query.QUERY_COUNT} queries of the user "${query.USER_NAME}"`,
        desc: `running in ${query.WAREHOUSE_NAME}. can be moved to higher warehouse.`
      }
    }
    return data;
  }
);

export const fetchOverloadWhInsight = createAsyncThunk(
  'costMetrics/fetchOverloadWhInsight',
  async () => {
    let response = await costMetricsAPI.fetchOverloadWhInsight();
    const [ query ] = response.data || [];
    let data;
    if (query) {
      data = {
        value: `${query.QUERY_COUNT} out of ${query.TOTAL_COUNT} queries`,
        desc: `In ${query.WAREHOUSE_NAME} have queuing time. \
        Consider enabling auto-scaling to improve performance.`
      }
    }
    return data;
  }
);

export const fetchTopDatesByUsageCost = createAsyncThunk(
  'costMetrics/fetchTopDatesByUsageCost',
  async (queryObj: ICommonQuery) => {
    const { data } = await costMetricsAPI.fetchTopDatesByUsageCost(queryObj);
    data.dates = data.dates.map((dateStr: string) => (moment(dateStr).format('D MMM, yyyy') || ''))
    return data;
  }
);

export const fetchTopUsersByUsageCost = createAsyncThunk(
  'costMetrics/fetchTopUsersByUsageCost',
  async (queryObj: ICommonQuery) => {
    const response = await costMetricsAPI.fetchTopUsersByUsageCost(queryObj);
    return response.data;
  }
);

export const fetchTopWarehouseByUsageCost = createAsyncThunk(
  'costMetrics/fetchTopWarehouseByUsageCost',
  async (queryObj: ICommonQuery) => {
    const response = await costMetricsAPI.fetchTopWarehouseByUsageCost(queryObj);
    return response.data;
  }
);

export const fetchWarehouseSizeVsUsageCost = createAsyncThunk(
  'costMetrics/fetchWarehouseSizeVsUsageCost',
  async (queryObj: ICommonQuery) => {
    const response = await costMetricsAPI.fetchWarehouseSizeVsUsageCost(queryObj);
    return response.data;
  }
);

export const fetchComplexQueries = createAsyncThunk(
  'costMetrics/fetchComplexQueries',
  async () => {
    const response = await costMetricsAPI.fetchComplexQueries();
    return response.data;
  }
);

export const fetchHigherUsageusers = createAsyncThunk(
  'costMetrics/fetchHigherUsageusers',
  async () => {
    const response = await costMetricsAPI.fetchHigherUsageusers();
    return response.data;
  }
);

export const fetchOverloadWHQueries = createAsyncThunk(
  'costMetrics/fetchOverloadWHQueries',
  async () => {
    const response = await costMetricsAPI.fetchOverloadWHQueries();
    return response.data;
  }
);

export const costMetricsSlice = createSlice({
  name: 'costMetrics',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDateRange: (state, action: PayloadAction<{ startDate: number; endDate: number; }>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setUsageCostByWarehouseToggler: (state, action: PayloadAction<UsageCostByWarehouseTogglerType>) => {
      state.usageCostByWarehouseToggler = action.payload;
    },
    setCostPerCredit: (state, action: PayloadAction<number>) => {
      state.costPerCredit = action.payload;
    },
    setSelectedUsers: (state, action: PayloadAction<string[]>) => {
      state.selectedUsers = action.payload;
    },
    setSelectedWarehouses: (state, action: PayloadAction<string[]>) => {
      state.selectedWarehouses = action.payload;
    },
    dismissInsights: (state) => {
      state.isInsightsDismissed = true;
    },
    toggleInsightsCollapsedState: (state, action: PayloadAction<boolean>) => {
      state.isInsightsCollapsed = action.payload;
    },
    clearCostMetrics: (state) => {
      const initialData = getReportsData();
      state.totalUsageCost = initialData.totalUsageCost;
      state.averagetDailyCost = initialData.averagetDailyCost;
      state.topDatesByUsageCost = initialData.topDatesByUsageCost;
      state.topUsersByUsageCost = initialData.topUsersByUsageCost;
      state.topWarehouseByUsageCost = initialData.topWarehouseByUsageCost;
      state.warehouseSizeVsUsageCost = initialData.warehouseSizeVsUsageCost;
      state.startDate = moment().startOf('month').valueOf();
      state.endDate = moment().valueOf();
      state.usageCostByWarehouseToggler = 'Name';
      state.costPerCredit = defaultCostPerCredit;
      state.selectedUsers = [];
      state.selectedWarehouses = [];
    },
    clearCostMetricsReports: (state) => {
      state.totalUsageCost = initialState.totalUsageCost;
      state.averagetDailyCost = initialState.averagetDailyCost;
      state.topDatesByUsageCost = initialState.topDatesByUsageCost;
      state.topUsersByUsageCost = initialState.topUsersByUsageCost;
      state.topWarehouseByUsageCost = initialState.topWarehouseByUsageCost;
      state.warehouseSizeVsUsageCost = initialState.warehouseSizeVsUsageCost;
    },
    resetCostMetrics: (state) => state = initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchComparitiveCostInsights.pending, (state) => {
        state.totalUsageCost.loading = true;
        state.averagetDailyCost.loading = true;
        state.totalUsageCost.initialFetchDone = true;
        state.averagetDailyCost.initialFetchDone = true;
      })
      .addCase(fetchComparitiveCostInsights.fulfilled, (state, action) => {
        state.totalUsageCost.loading = false;
        state.totalUsageCost.value = action.payload.totalUsageCost;
        state.averagetDailyCost.loading = false;
        state.averagetDailyCost.value = action.payload.averagetDailyCost;
      }).addCase(fetchComparitiveCostInsights.rejected, (state, action) => {
        state.totalUsageCost.loading = false;
        state.averagetDailyCost.loading = false;
      })

      .addCase(fetchTopDatesByUsageCost.pending, (state) => {
        state.topDatesByUsageCost.loading = true;
        state.topDatesByUsageCost.initialFetchDone = true;
      })
      .addCase(fetchTopDatesByUsageCost.fulfilled, (state, action) => {
        state.topDatesByUsageCost.loading = false;
        state.topDatesByUsageCost.value = action.payload;
      })
      .addCase(fetchTopDatesByUsageCost.rejected, (state, action) => {
        state.topDatesByUsageCost.loading = false;
      })

      .addCase(fetchTopUsersByUsageCost.pending, (state) => {
        state.topUsersByUsageCost.loading = true;
        state.topUsersByUsageCost.initialFetchDone = true;
      })
      .addCase(fetchTopUsersByUsageCost.fulfilled, (state, action) => {
        state.topUsersByUsageCost.loading = false;
        state.topUsersByUsageCost.value = action.payload;
      })
      .addCase(fetchTopUsersByUsageCost.rejected, (state, action) => {
        state.topUsersByUsageCost.loading = false;
      })

      .addCase(fetchTopWarehouseByUsageCost.pending, (state) => {
        state.topWarehouseByUsageCost.loading = true;
        state.topWarehouseByUsageCost.initialFetchDone = true;
      })
      .addCase(fetchTopWarehouseByUsageCost.fulfilled, (state, action) => {
        state.topWarehouseByUsageCost.loading = false;
        state.topWarehouseByUsageCost.value = action.payload;
      })
      .addCase(fetchTopWarehouseByUsageCost.rejected, (state, action) => {
        state.topWarehouseByUsageCost.loading = false;
      })

      .addCase(fetchWarehouseSizeVsUsageCost.pending, (state) => {
        state.warehouseSizeVsUsageCost.loading = true;
        state.warehouseSizeVsUsageCost.initialFetchDone = true;
      })
      .addCase(fetchWarehouseSizeVsUsageCost.fulfilled, (state, action) => {
        state.warehouseSizeVsUsageCost.loading = false;
        state.warehouseSizeVsUsageCost.value = action.payload;
      })
      .addCase(fetchWarehouseSizeVsUsageCost.rejected, (state, action) => {
        state.warehouseSizeVsUsageCost.loading = false;
      })

      .addCase(fetchComplexQueriesInsight.pending, (state) => {
        state.complexQueriesInsight.loading = true;
        state.complexQueriesInsight.initialFetchDone = true;
      })
      .addCase(fetchComplexQueriesInsight.fulfilled, (state, action) => {
        state.complexQueriesInsight.loading = false;
        state.complexQueriesInsight.value = action.payload;
      }).addCase(fetchComplexQueriesInsight.rejected, (state, action) => {
        state.complexQueriesInsight.loading = false;
      })

      .addCase(fetchHigherUsageuserInsight.pending, (state) => {
        state.higherUsageUserInsights.loading = true;
        state.higherUsageUserInsights.initialFetchDone = true;
      })
      .addCase(fetchHigherUsageuserInsight.fulfilled, (state, action) => {
        state.higherUsageUserInsights.loading = false;
        state.higherUsageUserInsights.value = action.payload;
      }).addCase(fetchHigherUsageuserInsight.rejected, (state, action) => {
        state.higherUsageUserInsights.loading = false;
      })

      .addCase(fetchOverloadWhInsight.pending, (state) => {
        state.overloadWHInsights.loading = true;
        state.overloadWHInsights.initialFetchDone = true;
      })
      .addCase(fetchOverloadWhInsight.fulfilled, (state, action) => {
        state.overloadWHInsights.loading = false;
        state.overloadWHInsights.value = action.payload;
      }).addCase(fetchOverloadWhInsight.rejected, (state, action) => {
        state.overloadWHInsights.loading = false;
      })

      .addCase(fetchComplexQueries.pending, (state) => {
        state.complexQueries.loading = true;
        state.complexQueries.initialFetchDone = true;
      })
      .addCase(fetchComplexQueries.fulfilled, (state, action) => {
        state.complexQueries.loading = false;
        state.complexQueries.value = action.payload;
      }).addCase(fetchComplexQueries.rejected, (state, action) => {
        state.complexQueries.loading = false;
      })

      .addCase(fetchHigherUsageusers.pending, (state) => {
        state.higherUsageUsers.loading = true;
        state.higherUsageUsers.initialFetchDone = true;
      })
      .addCase(fetchHigherUsageusers.fulfilled, (state, action) => {
        state.higherUsageUsers.loading = false;
        state.higherUsageUsers.value = action.payload;
      }).addCase(fetchHigherUsageusers.rejected, (state, action) => {
        state.higherUsageUsers.loading = false;
      })

      .addCase(fetchOverloadWHQueries.pending, (state) => {
        state.overloadWHQueries.loading = true;
        state.overloadWHQueries.initialFetchDone = true;
      })
      .addCase(fetchOverloadWHQueries.fulfilled, (state, action) => {
        state.overloadWHQueries.loading = false;
        state.overloadWHQueries.value = action.payload;
      }).addCase(fetchOverloadWHQueries.rejected, (state, action) => {
        state.overloadWHQueries.loading = false;
      })

      // app slice effect
      .addCase(fetchDashboardMeta.fulfilled, (state, action) => {
        state.selectedUsers = action.payload.users;
        state.selectedWarehouses = action.payload.warehouses;
      })
  },
});

export const {
  setDateRange, setUsageCostByWarehouseToggler, setCostPerCredit,
  setSelectedUsers, setSelectedWarehouses, clearCostMetrics,
  clearCostMetricsReports, dismissInsights, toggleInsightsCollapsedState,
  resetCostMetrics
} = costMetricsSlice.actions;

export const selectTotalUsageCost = (state: RootState) => state.costMetrics.totalUsageCost;
export const selectAverageDailyCost = (state: RootState) => state.costMetrics.averagetDailyCost;
export const selectTopDatesByUsageCost = (state: RootState) => state.costMetrics.topDatesByUsageCost;
export const selectTopUsersByUsageCost = (state: RootState) => state.costMetrics.topUsersByUsageCost;
export const selectTopWarehouseByUsageCost = (state: RootState) => state.costMetrics.topWarehouseByUsageCost;
export const selectWarehouseSizeVsUsageCost = (state: RootState) => state.costMetrics.warehouseSizeVsUsageCost;
export const selectStartDate = (state: RootState) => state.costMetrics.startDate;
export const selectEndDate = (state: RootState) => state.costMetrics.endDate;
export const selectUsageCostByWarehouseToggler = (state: RootState) => state.costMetrics.usageCostByWarehouseToggler;
export const selectCostPerCredit = (state: RootState) => state.costMetrics.costPerCredit;
export const selectSelectedUsers = (state: RootState) => state.costMetrics.selectedUsers;
export const selectSelectedWarehouses = (state: RootState) => state.costMetrics.selectedWarehouses;
export const selectIsInsightsDismissed = (state: RootState) => state.costMetrics.isInsightsDismissed;
export const selectHigherUsageUserInsights = (state: RootState) => state.costMetrics.higherUsageUserInsights;
export const selectComplexQueriesInsight = (state: RootState) => state.costMetrics.complexQueriesInsight;
export const selectOverloadWHInsight = (state: RootState) => state.costMetrics.overloadWHInsights;
export const selectIsInsightsCollapsed = (state: RootState) => state.costMetrics.isInsightsCollapsed;
export const selectComplexQueries = (state: RootState) => state.costMetrics.complexQueries;
export const selectOverloadWHQueries = (state: RootState) => state.costMetrics.overloadWHQueries;
export const selectHigherUsageUsers = (state: RootState) => state.costMetrics.higherUsageUsers;
export const selectAllInsights = (state: RootState) => [
  state.costMetrics.complexQueriesInsight,
  state.costMetrics.higherUsageUserInsights,
  state.costMetrics.overloadWHInsights
];
export default costMetricsSlice.reducer;