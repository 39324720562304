import { Chart, registerables } from 'chart.js';
import React, { MutableRefObject, RefObject, useEffect, useMemo, useRef } from 'react';
import { ThunkAction } from 'redux-thunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import NoDataTemplate from '../NoDataTemplate';
import Spinner from '../Spinner';
import NoDataIcon from '../../assets/image/no-data-grid.svg';

Chart.register(...registerables);
Chart.defaults.font.family = 'Mulish';
Chart.defaults.font.size = 12;

interface IScatterPlotProps {
  xLabel?: string;
  yLabel?: string;
  query: any;
  skipInitialRender?: boolean;
  getDataAction: (query: any) => ThunkAction<any, any, any, any>;
  dataSelector: (state: RootState) => any;
  xKey: string;
  yKey: string;
  isArrayOfObjects?: boolean;
}

const ScatterPlot = ({
  xLabel = '', yLabel = '', query,
  getDataAction, dataSelector,
  isArrayOfObjects,
  xKey,
  yKey
}: IScatterPlotProps) => {
  const isInitialRenderDone: MutableRefObject<boolean> = useRef(false);
  const queryRef: MutableRefObject<any> = useRef(query);
  const dispatch = useAppDispatch();
  const canvasElement: RefObject<HTMLCanvasElement> = useRef(null);
  const { value, loading, initialFetchDone } = useAppSelector(dataSelector);
  const memoizedData = useMemo(() => {
    if (!loading && value) {
      let x, y;
      if (isArrayOfObjects) {
        x = value.map((obj: any) => obj[xKey]);
        y = value.map((obj: any) => obj[yKey]);
      } else {
        x = value[xKey];
        y = value[yKey];
      }
      return x.length && y.length ? {
        type: 'scatter' as any,
        data: {
          labels: x,
          datasets: [
            {
              label: 'Dataset 1',
              data: y
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            point: {
              backgroundColor: '#151EF9',
              radius: 3
            }
          },
          scales: {
            y: {
              grid: {
                display: true,
                borderDash: [5, 5],
                color: '#ECF0F4',
                drawBorder: false
              },
              title: {
                display: true,
                text: yLabel,
                color: '#A8A9AD'
              },
              ticks: {
                maxTicksLimit: 5
              }
            },
            x: {
              grid: {
                display: true,
                borderDash: [5, 5],
                color: '#ECF0F4',
                drawBorder: false
              },
              ticks: {
                // callback: (value: string) => `$${value}`
              },
              title: {
                display: true,
                text: xLabel,
                color: '#A8A9AD'
              },
              grace: '5%'
            }
          },
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          }
        },
      } : undefined
    }
  }, [loading, value, xLabel, yLabel, isArrayOfObjects, xKey, yKey]);
  useEffect(() => {
    if (!(isInitialRenderDone.current || initialFetchDone)) {
      isInitialRenderDone.current = true;
      dispatch(getDataAction(query));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (query !== queryRef.current) {
      queryRef.current = query;
      dispatch(getDataAction(query));
    }
  });
  useEffect(() => {
    if (memoizedData) {
      const chart = new Chart(canvasElement.current as HTMLCanvasElement, memoizedData);
      return () => chart.destroy();
    }
  }, [memoizedData]);

  useEffect(() => {
    isInitialRenderDone.current = true;
  }, []);
  return (
    <div className="position-relative w-100 h-100">
      { !(memoizedData || loading) ? <NoDataTemplate icon={NoDataIcon} /> : null }
      { loading ?
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <Spinner />
        </div> : null}
      { memoizedData ? <canvas ref={canvasElement} /> : null }
    </div>
  );
}

export default ScatterPlot;
