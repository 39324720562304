import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import BarChart from '../../components/Chart/BarChart';
import ScatterPlot from '../../components/Chart/ScatterPlot';
import ReportLayout from '../../components/ReportLayout';
import Scrollbar from '../../components/Scrollbar';
import TableReport from '../../components/TableReport';
import { infoType } from '../../constants/appConstants';
import { PROTECTED_ROUTES } from '../../constants/route';
import {
  fetchQueriesBiggerThanNxtWarehouse,
  selectQueriesBiggerThanNextWarehouse,
  selectQuerySizeVsExecutionTime,
  fetchQuerySizeVsExecutionTime,
  selectAverageExecutionTimeByWarehouseName,
  selectAverageExecutionTimeByWarehouseSize,
  fetchAverageExecutionTimeByWarehouseName,
  fetchAverageExecutionTimeByWarehouseSize,
  selectAverageExecutionTimeByWarehouseToggler,
  setaverageExecutionTimeByWarehouseToggler,
  fetchBiggerQueriesForExeTime,
  selectBiggerQueriesForExeTime,
  fetchBiggerQueriesForByteScanned,
  fetchBiggerQueriesForExeTimeAndOnByteScanned,
  selectBiggerQueriesForByteScanned,
  selectBiggerQueriesForExeTimeAndByteScanned,
  clearBiggerQuerieForExeTime,
  clearBiggerQuerieForByteScanned,
  clearBiggerQuerieForExeTimeAndByteScanned
} from '../../slice/performanceMetrics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { formatByType, formatDateRangeURLParam, formatPercentage, formatWarehouseSizesURLParam, formatWarehouseURLParam } from '../../utils/appUtils';

interface IQueryExplorerProps {
  startDate: number;
  endDate: number;
  query: object;
  // users?: string[];
  // warehouses?: string[];
  // selectedUsers?: string[];
  // selectedWarehouses?: string[];
}

const QueryExplorer = ({ startDate, endDate, query /*  users, warehouses, selectedUsers, selectedWarehouses */}: IQueryExplorerProps) => {
  const initialRenderDone: MutableRefObject<boolean> = useRef(false);
  const startDateRef: MutableRefObject<number> = useRef(startDate);
  const endDateRef: MutableRefObject<number> = useRef(endDate);
  // const selectedUsersRef: MutableRefObject<string[] | undefined> = useRef(selectedUsers);
  // const selectedWarehousesRef: MutableRefObject<string[] | undefined> = useRef(selectedWarehouses);
  // const usersRef: MutableRefObject<string[] | undefined> = useRef(users);
  // const warehousesRef: MutableRefObject<string[] | undefined> = useRef(warehouses);
  const dispatch = useAppDispatch();

  const {
    value: queriesBiggerThanNextWarehouse,
    loading: queriesBiggerThanNextWarehouseLoading,
    initialFetchDone: queriesBiggerThanNextWarehouseFetchedOnce
  } = useAppSelector(selectQueriesBiggerThanNextWarehouse);

  const {
    value: averageExecutionTimeByWarehouseName,
    loading: averageExecutionTimeByWarehouseNameLoading
  } = useAppSelector(selectAverageExecutionTimeByWarehouseName);
  const {
    value: averageExecutionTimeByWarehouseSize,
    loading: averageExecutionTimeByWarehouseSizeLoading
  } = useAppSelector(selectAverageExecutionTimeByWarehouseSize);
  const [nameToggler, sizeToggler]: [string, string] = ['Name', 'Size'];
  const averageExecutionTimeByWarehouseToggler = useAppSelector(selectAverageExecutionTimeByWarehouseToggler);
  const handleAvgExecTimeByWarehouseTogglerChange = useCallback((nxtToggler) => {
    dispatch(setaverageExecutionTimeByWarehouseToggler(nxtToggler));
  }, [dispatch]);

  // For initial fetch
  useEffect(() => {
    if (!initialRenderDone.current) {
      initialRenderDone.current = true;
      const queryObj = {
        startDate,
        endDate,
        // users: users?.filter((user: string) => !selectedUsers?.includes(user)),
        // warehouses: warehouses?.filter((warehouse: string) => !selectedWarehouses?.includes(warehouse))
      };
      if (!queriesBiggerThanNextWarehouseFetchedOnce) {
        dispatch(fetchQueriesBiggerThanNxtWarehouse(queryObj));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryObj = {
      startDate,
      endDate,
      // users: users?.filter((user: string) => !selectedUsers?.includes(user)),
      // warehouses: warehouses?.filter((warehouse: string) => !selectedWarehouses?.includes(warehouse))
    };
    if (
      startDateRef.current !== startDate ||
      endDateRef.current !== endDate
      // usersRef.current !== users ||
      // warehousesRef.current !== warehouses ||
      // selectedWarehousesRef.current !== selectedWarehouses ||
      // selectedUsersRef.current !== selectedUsers
    ) {
      dispatch(fetchQueriesBiggerThanNxtWarehouse(queryObj));
      if (averageExecutionTimeByWarehouseToggler === nameToggler) {
        dispatch(fetchAverageExecutionTimeByWarehouseName(queryObj));
      } else if (averageExecutionTimeByWarehouseToggler === sizeToggler) {
        dispatch(fetchAverageExecutionTimeByWarehouseSize(queryObj));
      }
    }
  }, [
    dispatch,
    startDate,
    endDate,
    startDateRef,
    endDateRef,
    nameToggler,
    sizeToggler,
    // users,
    // selectedUsers,
    // warehouses,
    // selectedWarehouses,
    averageExecutionTimeByWarehouseToggler
  ]);

  useEffect(() => {
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    // usersRef.current = users;
    // selectedUsersRef.current = selectedUsers;
    // warehousesRef.current = warehouses;
    // selectedWarehousesRef.current = selectedWarehouses;
  }, [startDate, endDate, /* users, selectedUsers, warehouses, selectedWarehouses */]);

  // Navigation handlers
  const warehousePageLinkFormatter = useCallback((warehouse: string) => ({
    pathname: PROTECTED_ROUTES.warehousePerformance,
    search: `?${formatDateRangeURLParam(startDate, endDate)}&${formatWarehouseURLParam([warehouse])}`,
    state: { from: PROTECTED_ROUTES.performance }
  }), [startDate, endDate]);

  const sizePageLinkFormatter = useCallback((warehouseSize: string) => ({
    pathname: PROTECTED_ROUTES.warehouseSizePerformance,
    search: `?${formatDateRangeURLParam(startDate, endDate)}&${formatWarehouseSizesURLParam([warehouseSize])}`,
    state: { from: PROTECTED_ROUTES.performance }
  }), [startDate, endDate]);

  const allColumns = [
    { field: 'QUERY_ID', headerName: 'Query ID', resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'USER_NAME', headerName: 'User', resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'WAREHOUSE_NAME', headerName: 'Warehouse', resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'EXECUTION_TIME', headerName: 'Execution Time (Sec)', resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'QUERY_TEXT', headerName: 'Query', resizable: false, filter: true, floatingFilter: false, isVisible: true },
    { field: 'START_TIME', headerName: 'Query Started At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true, valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) },
    { field: 'END_TIME', headerName: 'Query Ended At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true , valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) }
  ];
  const queriesDataParser = useCallback((queries: any) => {
    return queries;
  }, []);
  const [ showModal, setShowModal ] = useState(false);
  type modalKey = 'exeTime' | 'byteScanned' | 'exeTimeAndByteScanned';
  const modalKeyOrder: modalKey[] = ['exeTime', 'byteScanned', 'exeTimeAndByteScanned'];
  const selectedModalKey: MutableRefObject<modalKey> = useRef('exeTime');
  const selectedModalWhSize: MutableRefObject<string | undefined> = useRef();
  const cacheDataInfo: MutableRefObject<{[key: string]: { clearAction: ActionCreatorWithoutPayload<string>, whSize: string; }}> = useRef({
    exeTime: { clearAction: clearBiggerQuerieForExeTime, whSize: '' },
    byteScanned: { clearAction: clearBiggerQuerieForByteScanned, whSize: '' },
    exeTimeAndByteScanned: { clearAction: clearBiggerQuerieForExeTimeAndByteScanned, whSize: '' }
  });
  const modalPropsMap = {
    exeTime: {
      getDataAction: fetchBiggerQueriesForExeTime,
      dataSelector: selectBiggerQueriesForExeTime
    },
    byteScanned: {
      getDataAction: fetchBiggerQueriesForByteScanned,
      dataSelector: selectBiggerQueriesForByteScanned
    },
    exeTimeAndByteScanned: {
      getDataAction: fetchBiggerQueriesForExeTimeAndOnByteScanned,
      dataSelector: selectBiggerQueriesForExeTimeAndByteScanned
    }
  };
  const openModalByKey = useCallback((whSize: string, key: 'exeTime' | 'byteScanned' | 'exeTimeAndByteScanned') => {
    selectedModalKey.current = key;
    if (cacheDataInfo.current[key].whSize !== whSize) {
      cacheDataInfo.current[key].whSize = whSize;
      dispatch(cacheDataInfo.current[key].clearAction());
    }
    selectedModalWhSize.current = whSize;
    setShowModal(true);
  }, [dispatch]);
  
  return (
    <>
      <div className="col-lg-6 col-12 reportWrapper queriesBiggerReport p-dot75">
        <ReportLayout
          title="Queries bigger than next warehouse"
          loading={queriesBiggerThanNextWarehouseLoading}
          noData={false && !(queriesBiggerThanNextWarehouseLoading || queriesBiggerThanNextWarehouse)}
        >
          <Scrollbar>
            <table className="fs-dot75 text-left">
              <thead className="font-weight-bold align-top">
                <tr>
                  <th className="pl-4 pr-3 pt-2 pb-dot75">Warehouse size</th>
                  <th className="px-3 pt-2 pb-dot75">On execution time</th>
                  <th className="px-3 pt-2 pb-dot75">On byte scanned</th>
                  <th className="px-3 pr-1dot25 pt-2 pb-dot75">On both execution time and byte scanned</th>
                </tr>
              </thead>
              <tbody>
                {queriesBiggerThanNextWarehouse?.map(({ warehouseSize, execTime, byteScanned, execTimeAndByteScanned }) => (
                  <tr key={warehouseSize}>
                    <td className="pl-4 pr-3">{warehouseSize}</td>
                    {[execTime, byteScanned, execTimeAndByteScanned].map(({ totalQueries, percent, queryCount }, i) => (
                      <td className="px-3" key={`{warehouseSize}_${i}`}>
                        { totalQueries || percent || queryCount ?
                          <span className="link-custom" onClick={() => openModalByKey(warehouseSize, modalKeyOrder[i])}>
                            <span className="font-weight-bold">
                              {formatPercentage(percent, 0)}
                            </span> - {queryCount} out of {totalQueries} Queries
                          </span>
                          : '-'
                        }
                      </td>
                    ))}
                  </tr>
                ))
                }
              </tbody>
            </table>
          </Scrollbar>
        </ReportLayout>
      </div>
      <div className="col-lg-6 col-12 reportWrapper p-dot75">
        <ReportLayout title="Query size vs execution time">
          <ScatterPlot
            getDataAction={fetchQuerySizeVsExecutionTime}
            dataSelector={selectQuerySizeVsExecutionTime}
            xKey="querySize"
            yKey="executionTime"
            query={query}
            xLabel="Query size in kb"
            yLabel="Execution time (sec)"
          />
        </ReportLayout>
      </div>
      <div className="col-lg-6 col-12 reportWrapper p-dot75">
        <ReportLayout
          title="Average execution time by warehouse"
          loading={averageExecutionTimeByWarehouseNameLoading || averageExecutionTimeByWarehouseSizeLoading}
          noData={(
            averageExecutionTimeByWarehouseToggler === nameToggler && !(averageExecutionTimeByWarehouseNameLoading || averageExecutionTimeByWarehouseName)
          ) || (
              averageExecutionTimeByWarehouseToggler === sizeToggler && !(averageExecutionTimeByWarehouseSizeLoading || averageExecutionTimeByWarehouseSize)
            )}
          toggler={[nameToggler, sizeToggler]}
          onToggle={handleAvgExecTimeByWarehouseTogglerChange}
          activeToggler={averageExecutionTimeByWarehouseToggler}
        >
          { averageExecutionTimeByWarehouseToggler === nameToggler ?
          <BarChart
            key="warehouseName"
            isHorizantal={true}
            getDataAction={fetchAverageExecutionTimeByWarehouseName}
            dataSelector={selectAverageExecutionTimeByWarehouseName}
            xKey="avgExecutionTime"
            yKey="name"
            xLabel="Execution time (sec)"
            query={query}
            enableCateogoryLink={true}
            linkFormatter={warehousePageLinkFormatter}
          /> :
          <BarChart
            key="warehouseSize"
            getDataAction={fetchAverageExecutionTimeByWarehouseSize}
            dataSelector={selectAverageExecutionTimeByWarehouseSize}
            isHorizantal={true}
            yKey="size"
            xKey="avgExecutionTime"
            // yLabel="Warehouse size"
            xLabel="Execution time (sec)"
            query={query}
            enableCateogoryLink={true}
            linkFormatter={sizePageLinkFormatter}
          />}
        </ReportLayout>
      </div>
      { showModal &&
        <div id="modal-background" className="modalBackground px-5 d-flex align-items-center justify-content-center"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => (e.target as any)?.id === 'modal-background' && setShowModal(false)}
        >
          <div className="col-8" style={{ height: '75vh'}}>
            <TableReport
              query={{
                startDate, endDate,
                includedWarehouseSizes: selectedModalWhSize.current ?
                  [selectedModalWhSize.current] : undefined  
              }}
              { ...modalPropsMap[selectedModalKey.current] }
              dataFormatter={queriesDataParser}
              // getDataAction={fetchQueries}
              // dataSelector={selectQueries}
              columns={allColumns}
              closeBtn={true}
              onClose={() => setShowModal(false)}
              exportable={true}
            />
          </div>
        </div>
      }
    </>
  );
}

export default QueryExplorer;
