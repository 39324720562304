import axios from 'axios';
import { ICommonQuery } from 'custom-types';
import { constructCommonQuery } from '../../utils/appUtils';

export const fetchTopDatesByUsageCost = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/datesWithHighestUsageCost?${constructCommonQuery(queryObj)}`
});

export const fetchTopUsersByUsageCost = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/usersWithHighestUsageCost?${constructCommonQuery(queryObj)}`
});

export const fetchTopWarehouseByUsageCost = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/warehouseByusageName?${constructCommonQuery(queryObj)}`
});

export const fetchWarehouseSizeVsUsageCost = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/warehouseByusageSize?${constructCommonQuery(queryObj)}`
});

export const fetchComplexQueriesInsight = () => axios({
  method: 'get',
  url: 'insight/complexQueries'
});

export const fetchHigherUsageuserInsight = () => axios({
  method: 'get',
  url: '/insight/higherUsageuser'
});

export const fetchOverloadWhInsight = () => axios({
  method: 'get',
  url: '/insight/over-load-WH'
});

export const fetchComplexQueries = () => axios({
  method: 'get',
  url: '/insight/complexQueries-details?limit=null'
});

export const fetchOverloadWHQueries = () => axios({
  method: 'get',
  url: '/insight/over-load-WH-details?limit=null'
});

export const fetchHigherUsageusers = () => axios({
  method: 'get',
  url: '/insight/higherUsageuser-details?limit=null'
});

export const fetchComparitiveCostInsights = (queryObj: ICommonQuery) => axios({
  method: 'get',
  url: `/home/cost-card-details?${constructCommonQuery(queryObj)}`
});
