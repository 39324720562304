import React from 'react';
import Logo from '../../assets/image/logo-blue.svg';
import ClockRedImg from '../../assets/image/clock-red.svg';
import TimesUpImg from '../../assets/image/times-up.svg';

import './Demo.scss';

const Timeup = () => {
  return (
    <div className="text-center bg-white fs-dot875 lh-1dot25 timeupIndication">
      <div className="mb-4"><img src={Logo} alt="logo" /></div>
      <div className="d-flex justify-content-center flex-column align-items-center mb-4">
        <img src={ClockRedImg} className="clockIcon" alt="Time up" />
        <img src={TimesUpImg} className="timeupIcon" alt="" />
      </div>
      <div className="mb-3 font-weight-bold lh-1dot5">
        You've run out of time to explore the product for today.<br />
        Check back tomorrow with your registered id to access.
      </div>
    </div>
  );
};

export default Timeup;
