import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { DEFAULT_PROTECTED_ROUTE, DEFAULT_PUBLIC_ROUTE, PROTECTED_ROUTES, PUBLIC_ROUTES } from './constants/route';
import Date from './pages/Date';
import Drilldown from './pages/Drilldown';
import Login from './pages/Login';
import ForgotPassword from './pages/Login/ForgotPassword';
import Overview from './pages/Overview';
import Performance from './pages/Performance';
import Signup from './pages/Login/Signup';
import User from './pages/User';
import Warehouse from './pages/Warehouse';
import WarehouseSize from './pages/WarehouseSize';

const Routes = () => {
  const isUserLoggedIn = localStorage.getItem('token');
  const location = useLocation();
  const isDemoURL = location.pathname.startsWith('/demo') ;
  return (
    (isUserLoggedIn || isDemoURL) ?
      <Switch>
        <Route exact={true} path={PROTECTED_ROUTES.overview}>
          <Overview />
        </Route>
        <Route exact={true} path={PROTECTED_ROUTES.performance}>
          <Performance />
        </Route>
        <Route path={PROTECTED_ROUTES.warehouse}>
          <Warehouse />
        </Route>
        <Route path={PROTECTED_ROUTES.warehouseSize}>
          <WarehouseSize />
        </Route>
        <Route path={PROTECTED_ROUTES.user}>
          <User />
        </Route>
        <Route path={PROTECTED_ROUTES.date}>
          <Date />
        </Route>
        <Route path={PROTECTED_ROUTES.warehousePerformance}>
          <Warehouse />
        </Route>
        <Route path={PROTECTED_ROUTES.warehouseSizePerformance}>
          <WarehouseSize />
        </Route>
        <Route path={PROTECTED_ROUTES.userPerformance}>
          <User />
        </Route>
        <Route path={PROTECTED_ROUTES.datePerformance}>
        true <Date />
        </Route>
        <Route path={PROTECTED_ROUTES.drilldown}>
          <Drilldown />
        </Route>
        <Redirect to={DEFAULT_PROTECTED_ROUTE} />
      </Switch> :
      <Switch>
        <Route exact={true} path={PUBLIC_ROUTES.login}>
          <Login />
        </Route>
        <Route path={PUBLIC_ROUTES.signup}>
          <Signup />
        </Route>
        <Route path={PUBLIC_ROUTES.forgotPassword}>
          <ForgotPassword />
        </Route>
        <Redirect to={DEFAULT_PUBLIC_ROUTE} />
      </Switch>
  );
};

export default Routes;
