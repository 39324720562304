import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Chart, registerables } from 'chart.js';
import React, { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ThunkAction } from 'redux-thunk';

import ReportLayout from '../ReportLayout';
import Spinner from '../Spinner';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import CloseIcon from '../../assets/image/cross.svg';
import {isMobileDevice} from '../../utils/appUtils'

import './TableReport.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

Chart.register(...registerables);
Chart.defaults.font.family = 'Mulish';
Chart.defaults.font.size = 12;

interface ITableReportProps {
  query: any;
  getDataAction: (query: any) => ThunkAction<any, any, any, any>;
  dataSelector: (state: RootState) => any;
  dataFormatter: (data: any) => any;
  columns: object[];
  closeBtn?: boolean;
  onClose?: () => void;
  title?: string;
  exportable?: boolean;
}

const TableReport = ({
  query, getDataAction, dataSelector, dataFormatter, columns: allColumns, closeBtn, onClose, title, exportable
}: ITableReportProps) => {
  const isInitialRenderDone: MutableRefObject<boolean> = useRef(false);
  const queryRef: MutableRefObject<any> = useRef(query);
  const dispatch = useAppDispatch();
  const { value, loading, initialFetchDone } = useAppSelector(dataSelector);
  useEffect(() => {
    if (!(isInitialRenderDone.current || initialFetchDone)) {
      isInitialRenderDone.current = true;
      dispatch(getDataAction(query));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (query !== queryRef.current) {
      queryRef.current = query;
      dispatch(getDataAction(query));
    }
  });

  const colMenu: RefObject<HTMLDivElement> = useRef(null);
  const [colMenuVisibility, setColMenuVisibility] = useState(false);
  const [columns, setColumns] = useState(allColumns);
  const [ gridApi, setGridApi] = useState(null);
  const [ /* gridColumnApi */, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if (!isMobileDevice()) {
      params.api.sizeColumnsToFit();
    }
  }
  const windowClickHandler = useCallback((e: Event) => {
    if (e.target !== colMenu.current && colMenuVisibility) {
      setColMenuVisibility(!colMenuVisibility);
    }
  }, [colMenuVisibility]);
  useEffect(() => {
    window.addEventListener('click', windowClickHandler);
    return () => window.removeEventListener('click', windowClickHandler);
  }, [colMenuVisibility, windowClickHandler])
  const onColVisibilityChange = (e: React.MouseEvent<any, any>, field: string, isVisible: boolean) => {
    e.stopPropagation();
    setColumns(columns.map((col: any) => (
      { ...col, isVisible: col.field === field ? isVisible : col.isVisible }
    )));
  }
  const exportAsCSV = () => {
    (gridApi as any)?.exportDataAsCsv();
  }
  return (
    <ReportLayout
      title={title || 'Queries'}
      loading={loading}
      noData={false}
      className="tableReportContainer"
      headerElement={() => (
        <>
          { exportable ? <button className="ml-auto exportBtn bg-primary text-white px-1dot375 py-2" onClick={exportAsCSV}>Export</button> : null}
          <div className={`${exportable ? 'ml-4' : 'ml-auto'} colFilter`}>
            <span onClick={() => setColMenuVisibility(!colMenuVisibility)}>Column Filter</span>
            <div ref={colMenu} className={`colsMenu py-1 ${colMenuVisibility ? 'd-block' : 'd-none'}`}>
              {columns.map(({ field, headerName, isVisible }: any, i: number) => (
                <div key={`${field}_${i}`} className="colName" onClick={(e: React.MouseEvent<any, any>) => onColVisibilityChange(e, field, !isVisible)}>
                  {headerName}
                  <i className={`fa fa-check ${isVisible ? 'visible' : ''}`} />
                </div>
              ))}
            </div>
          </div>
          { closeBtn ? <img src={CloseIcon} width={12} height={12} alt="close" className="ml-4 cursor-pointer" onClick={onClose} /> : null }
        </>
      )}
    >
      <div className="position-relative w-100 h-100">
        {loading ?
          <div className="w-100 h-100 bg-white position-absolute d-flex align-items-center justify-content-center">
            <Spinner />
          </div> : null}
        <div className="ag-theme-alpine w-100 h-100">
          <AgGridReact
            onGridReady={onGridReady}
            // onColumnVisible={console.log}
            rowData={dataFormatter(value)}
            pagination={true}
            enableCellTextSelection={true}
            paginationAutoPageSize={true}
          >
            {columns.map((column: any) => (
              column.isVisible ? <AgGridColumn {...column} key={column.field} /> : null))}
          </AgGridReact>
        </div>
      </div>
    </ReportLayout>
  );
}

export default TableReport;