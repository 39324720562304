import moment from 'moment';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import backIcon from '../../assets/image/back.svg';
import BarTimeTrend from '../../components/Chart/BarTimeTrend';
import LineTimeTrend from '../../components/Chart/LineTimeTrend';
import ScatterPlot from '../../components/Chart/ScatterPlot';
import DateRangePicker from '../../components/DateRangePicker';
import ReportLayout from '../../components/ReportLayout';
import MultiSelect, { IOption, selectValueType } from '../../components/Select/MultiSelect';
import SingleSelect from '../../components/Select/SingleSelect';
import Spinner from '../../components/Spinner';
import TableReport from '../../components/TableReport';
import { infoType } from '../../constants/appConstants';
import { PROTECTED_ROUTES } from '../../constants/route';
import filterIcon from '../../assets/image/filter.svg';

import { selectDashboardMeta } from '../../slice/app';
import {
  fetchCostTimeTrendByWarehouse,
  fetchQueryTimeTrendByWarehouse,
  selectCostTimeTrendByWarehouse,
  selectQueryTimeTrendByWarehouse,
  fetchQuerySizeVsTimeByWarehouse,
  selectQuerySizeVsTimeByWarehouse,
  selectWarehouseInfo,
  fetchWarehouseInfo,
  fetchQueries,
  selectQueries,
  fetchQueryQueueTimeTrend,
  selectQueryQueueTimeTrendByWarehouse,
  clearReportsData,
  selectEndDate,
  selectSelectedUsers,
  selectSelectedWarehouse,
  selectStartDate,
  setDateRange,
  setSelectedUsers,
  setSelectedWahouse
} from '../../slice/drilldown/warehouse';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { formatByType } from '../../utils/appUtils';
import userIcon from '../../assets/image/user-blue.svg';
import warehouseIcon from '../../assets/image/warehouse-blue.svg';

import './Warehouse.scss';

const Warehouse = () => {
  const initialRenderDone: MutableRefObject<boolean> = useRef(false);
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  let startDate = useAppSelector(selectStartDate);
  let endDate = useAppSelector(selectEndDate);
  const selectedUsers = useAppSelector(selectSelectedUsers);
  const selectedWarehouse = useAppSelector(selectSelectedWarehouse);
  const { users, warehouses, loading: loadingDropdownData } = useAppSelector(selectDashboardMeta);
  // init effect
  if (!initialRenderDone.current) {
    let {
      // startDate: nxtStartDate = startDate,
      // endDate: nxtEndDate = endDate,
      selectedUsers: nxtSelectedUsers = selectedUsers,
      selectedWarehouse: nxtSelectedWarehouse = selectedWarehouse || (warehouses || [])[0]
    } = location.state || {};
    const nxtStartDate = location.state?.startDate || startDate;
    const nxtEndDate = location.state?.endDate || endDate;
    const isStartDateChanged = startDate !== nxtStartDate;
    const isEndDateChanged = endDate !== nxtEndDate;
    const isSelectedUsersChanged = [...(selectedUsers || [])].sort().join(',') !== [...(nxtSelectedUsers || [])].sort().join(',');
    const isSelectedWarehousesChanged = selectedWarehouse !== nxtSelectedWarehouse;
    if (
      isStartDateChanged ||
      isEndDateChanged ||
      isSelectedUsersChanged ||
      isSelectedWarehousesChanged
    ) {
      dispatch(clearReportsData());
    }
    if (isStartDateChanged || isEndDateChanged) {
      dispatch(setDateRange([
        nxtStartDate || moment().subtract(6, 'days').startOf('day').valueOf(),
        nxtEndDate || moment().endOf('day').valueOf(),
      ]));
      startDate = nxtStartDate;
      endDate = nxtEndDate;
    }
    if (isSelectedUsersChanged) {
      dispatch(setSelectedUsers(nxtSelectedUsers));
    }
    if (isSelectedWarehousesChanged) {
      dispatch(setSelectedWahouse(nxtSelectedWarehouse));
    }
  }

  const handleUserChange = useCallback((userOptions: selectValueType = []) => {
    dispatch(setSelectedUsers((userOptions as IOption[]).map(({ value }) => value)));
  }, [dispatch]);

  const handleDateRangeChange = useCallback(([startDate, endDate]: [number, number]) => {
    dispatch(setDateRange([startDate, endDate]));
  }, [dispatch]);

  const warehouseInfoDisplay = [
    { key: 'name', label: 'Warehouse Name', type: infoType.text },
    { key: 'size', label: 'Warehouse Size', type: infoType.text },
    { key: 'totalQueries', label: 'Total Queries', type: infoType.number },
    { key: 'totalUsers', label: 'Total Users Using', type: infoType.number },
    { key: 'totalCredits', label: 'Total Cost', type: infoType.dollar },
    { key: 'avgExecTimeInSec', label: 'Average Execution Time', type: infoType.time },
  ];

  const { value: warehouseInfo, loading: loadingWarehouseInfo, initialFetchDone: warehouseInfoFetchedAlready } = useAppSelector(selectWarehouseInfo);
  useEffect(() => {
    if (initialRenderDone.current || !warehouseInfoFetchedAlready) {
      dispatch(fetchWarehouseInfo({
        startDate, endDate,
        includedWarehouses: selectedWarehouse ? [selectedWarehouse] : undefined
      }));
    }
  }, [ dispatch, startDate, endDate, selectedWarehouse, warehouseInfoFetchedAlready ]);

  // Dropdown data control
  const userOptions = useMemo(() => {
    return (users || []).map((user: string) => ({ value: user, label: user }))
  }, [users]);
  const selectedUserOptions = useMemo(() => {
    return ((selectedUsers?.length ? selectedUsers : users) || []).map((user: string) => ({ label: user, value: user }));
  }, [selectedUsers, users]);

  const warehouseOptions = useMemo(() => (
    (warehouses || []).map((warehouse: string) => ({ value: warehouse, label: warehouse }))
  ), [warehouses]);
  const selectedWarehouseOption = useMemo(() => {
    return { label: selectedWarehouse, value: selectedWarehouse };
  }, [selectedWarehouse]);
  const handleWarehouseChange = useCallback((nxtOption: selectValueType) => {
    dispatch(setSelectedWahouse((nxtOption as IOption).value));
  }, [dispatch]);

  const selectedUsersStr = [...(selectedUsers || [])].sort().join(',');

  // Chart states
  const memoizedQueryObj = useMemo(() => {
    const selectedUsers = selectedUsersStr.split(',').filter(val => val);
    return {
      startDate, endDate,
      includedWarehouses: selectedWarehouse ? [selectedWarehouse] : undefined,
      includedUsers: selectedUsers.length ? selectedUsers : undefined
    };
  }, [startDate, endDate, selectedWarehouse, selectedUsersStr]);

  const costOverTimePeriodMeta = useMemo(() => ({
    valKeys: ['ESTIMATED_CREDITS'],
    datasetLabels: ['Cost']
  }), []);

  const allColumns = [
    { field: 'QUERY_ID', headerName: 'Query ID', resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'USER_NAME', headerName: 'User', resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'WAREHOUSE_ID', headerName: 'Warehouse', resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'EXECUTION_TIME_SECONDS', headerName: 'Execution Time (Sec)', resizable: true, filter: true, floatingFilter: false, isVisible: true },
    { field: 'QUERY', headerName: 'Query', resizable: false, filter: true, floatingFilter: false, isVisible: true },
    { field: 'START_TIME', headerName: 'Query Started At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true, valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) },
    { field: 'END_TIME', headerName: 'Query Ended At', sortable: true, resizable: true, filter: true, floatingFilter: false, isVisible: true , valueFormatter: ({ value }: any) => formatByType(value, infoType.datetime) }
  ];
  const queriesDataParser = useCallback((queries: any) => {
    return queries;
  }, []);

  const isDrilldownView = location.pathname.includes(PROTECTED_ROUTES.drilldown);
  // Should be the last effect  
  useEffect(() => {
    initialRenderDone.current = true;
  }, []);
  return (
    <div className="container-fluid warehouse">
      {!isDrilldownView && <div className="row header px-lg-9 px-md-5 px-4 py-dot75 fs-dot875 mb-0">
        <div className="col-auto px-0 cursor-pointer">
          <Link to={location?.state?.from || PROTECTED_ROUTES.overview}>
            <img src={backIcon} className="backButton" alt="back" />
          </Link>
        </div>
        <div className="col-auto ml-auto mr-auto pl-0 pr-3 text-white">Warehouse details</div>
      </div>}
      <div className="row filters mb-0 px-lg-9 px-md-5 px-4 py-dot75 fs-dot875">
        <div className="col-md-auto mr-md-auto px-0 text-primary d-flex align-items-center font-weight-bold lh-1dot25">
          <img src={filterIcon} alt="filter" className="mr-1 w-1 h-1" />
          Filters
        </div>
        <div className="filterMob col-auto px-0 pl-dot75">
          <SingleSelect
            className="warehouseSelect"
            value={selectedWarehouseOption as any}
            onChange={handleWarehouseChange as any}
            options={warehouseOptions}
            loading={loadingDropdownData}
            icon={warehouseIcon}
            iconWidth={20}
          />
        </div>
        <div className="filterMob col-auto pl-dot75 pr-0">
          <MultiSelect
            className="userSelect"
            value={selectedUserOptions as any}
            onChange={handleUserChange as any}
            options={userOptions}
            isMulti={true}
            subjectPlural="users"
            subject="user"
            loading={loadingDropdownData}
            icon={userIcon}
          />
        </div>
        <div className="filterMob col-auto d-flex align-items-center pl-dot-75">
          <DateRangePicker
            showTime={true}
            onSelect={handleDateRangeChange}
            className="py-dot375 px-2 lh-1dot125"
            startDate={startDate}
            endDate={endDate}
            enablePresets={true}
            maxDate={moment().endOf('day').valueOf()}
          />
        </div>
      </div>
      <div className="row mb-0 border-bottom-grey px-lg-9 px-md-5 px-4 py-dot75 justify-content-start">
        {warehouseInfo && !loadingWarehouseInfo ? warehouseInfoDisplay.map(({ key, label, type }) => (
          <div key={key} className="infoField text-left col-lg-3 col-md-4 col-sm-6 col-12 px-1dot375 flex-column mb-2 my-dot75">
            <div className="fs-dot75 lh-1">{label}</div>
            <div className="lh-1dot25">
              { formatByType((warehouseInfo as any)[key], type) || '-' }
            </div>
          </div>
        )) : null}
        {loadingWarehouseInfo ?
          <div className="col-12 d-flex align-items-center justify-content-center">
            <Spinner />
          </div> : null}
      </div>
      <div className="mild-background row px-lg-9 px-md-5 px-4">
          <div className="col-lg-6 col-12 reportWrapper p-dot75">
            <ReportLayout
              title="Query size vs execution time"
              loading={false}
              noData={false}
            >
              <ScatterPlot
                xLabel="Query size (Mb)"
                yLabel="Execution time (sec)"
                query={memoizedQueryObj}
                xKey="querySize"
                yKey="executionTime"
                getDataAction={fetchQuerySizeVsTimeByWarehouse}
                dataSelector={selectQuerySizeVsTimeByWarehouse}
              />
            </ReportLayout>
          </div>
          <div className="col-lg-6 col-12 reportWrapper p-dot75">
            <ReportLayout
              title="Cost over time period"
              loading={false}
              noData={false}
            >
              <LineTimeTrend
                xLabel="Time period"
                yLabel="Cost"
                datasetLabels={costOverTimePeriodMeta.datasetLabels}
                query={memoizedQueryObj}
                getDataAction={fetchCostTimeTrendByWarehouse}
                dataSelector={selectCostTimeTrendByWarehouse}
                dateKey="DATE"
                hourKey="HOURS"
                valKeys={costOverTimePeriodMeta.valKeys}
                isArrayOfObj={true}
                enableChartOption={true}
                startDate={startDate}
                endDate={endDate}
                dataType="dollar"
              />
            </ReportLayout>
          </div>
          <div className="col-lg-6 col-12 reportWrapper p-dot75">
            <ReportLayout
              title="Number of queries"
              loading={false}
              noData={false}
            >
              <BarTimeTrend
                xLabel="Time Period"
                barLabel="Number of queries"
                query={memoizedQueryObj}
                dateKey="DATE"
                hourKey="HOURS"
                valKey="QUERY_COUNT"
                isArrayOfObj={true}
                getDataAction={fetchQueryTimeTrendByWarehouse}
                dataSelector={selectQueryTimeTrendByWarehouse}
                enableChartOption={true}
                startDate={startDate}
                endDate={endDate}
              />
            </ReportLayout>
          </div>
          <div className="col-lg-6 col-12 reportWrapper p-dot75">
            <ReportLayout
              title="Query queue time trend"
              loading={false}
              noData={false}
            >
              <BarTimeTrend
                xLabel="Time Period"
                barLabel="Queue time (sec)"
                query={memoizedQueryObj}
                dateKey="DATE"
                hourKey="HOURS"
                valKey="QUEUED_TIME_SECS"
                isArrayOfObj={true}
                getDataAction={fetchQueryQueueTimeTrend}
                dataSelector={selectQueryQueueTimeTrendByWarehouse}
                enableChartOption={true}
                startDate={startDate}
                endDate={endDate}
              />
            </ReportLayout>
          </div>
          <div className="col-12 reportWrapper tableWrapper p-dot75">
            {/* <ReportLayout
            title="Queries"
            loading={loadingQueries}
            noData={false}
          > */}
            <TableReport
              query={memoizedQueryObj}
              dataFormatter={queriesDataParser}
              getDataAction={fetchQueries}
              dataSelector={selectQueries}
              columns={allColumns}
            />
            {/* </ReportLayout> */}
          </div>
      </div>
    </div>
  );
}

export default Warehouse;
