import React from 'react';

import './TextArea.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
  label: string;
}

const TextArea = ({ error, type, label, ...inputAttr }: InputProps) => {
  return (
    <div className="textAreaContainer">
      <div className="textAreaLabel text-left fs-dot875 mb-1 text-white lh-1dot125">{label}</div>
      <textarea
        { ...inputAttr }
        className="textArea py-2 px-dot75 fs-dot875 lh-1dot125"
      />
      { error && <small className="textAreaError">{error}</small> }
    </div>
  );
}

export default TextArea;