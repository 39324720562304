export const PUBLIC_ROUTES = {
  login: '/',
  signup: '/signup',
  forgotPassword: '/forgotpassword'
};

export const PROTECTED_ROUTES = (function() {
  const preffix = window.location.pathname.startsWith('/demo') ? '/demo' : '';
  return {
    overview: preffix + "/overview",
    performance: preffix + "/performance",
    drilldown: preffix + "/drilldown",
    warehouse: preffix + "/overview/warehouse",
    warehouseSize: preffix + "/overview/warehousesize",
    user: preffix + "/overview/user",
    date: preffix + "/overview/date",
    warehousePerformance: preffix + "/performance/warehouse",
    warehouseSizePerformance: preffix + "/performance/warehousesize",
    userPerformance: preffix + "/performance/user",
    datePerformance: preffix + "/performance/date",
    warehouseDrilldown: preffix + "/drilldown/warehouse",
    warehouseSizeDrilldown: preffix + "/drilldown/warehousesize",
    userDrilldown: preffix + "/drilldown/user",
    dateDrilldown: preffix + "/drilldown/date"
  }
})();

export const DEFAULT_PROTECTED_ROUTE = PROTECTED_ROUTES.overview;
export const DEFAULT_PUBLIC_ROUTE = PUBLIC_ROUTES.login;
