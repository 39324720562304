import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import React, { MutableRefObject, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { ThunkAction } from 'redux-thunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { getNestedValue } from '../../utils/appUtils';
import DatePicker from '../DatePicker';
import DateRangePicker from '../DateRangePicker';
import NoDataTemplate from '../NoDataTemplate';
import Spinner from '../Spinner';
import NoDataIcon from '../../assets/image/no-data-bar.svg';
import LineChartIcon from '../../assets/image/line-chart-symbol.svg';

import './TimeTrend.scss';

Chart.register(...registerables);
Chart.defaults.font.family = 'Mulish';
Chart.defaults.font.size = 12;

interface IBarTimeTrendProps {
  xLabel?: string;
  barLabel?: string;
  query: any;
  skipInitialRender?: boolean;
  getDataAction: (query: any) => ThunkAction<any, any, any, any>;
  dataSelector: (state: RootState) => any;
  dateKey: string;
  hourKey?: string;
  valKey: string;
  isArrayOfObj?: boolean;
  enableChartOption?: boolean;
  startDate: number;
  endDate: number;
  lineKey?: string;
  lineLabel?: string;
  assignLeftAxisForLine?: boolean;
}

const BarTimeTrend = ({
  dateKey,
  valKey,
  xLabel = '',
  barLabel = '',
  query,
  enableChartOption,
  isArrayOfObj,
  getDataAction,
  dataSelector,
  startDate,
  endDate,
  lineKey,
  lineLabel,
  hourKey,
  assignLeftAxisForLine
}: IBarTimeTrendProps) => {
  const maxBars = 42;
  const isInitialRenderDone: MutableRefObject<boolean> = useRef(false);
  const queryRef: MutableRefObject<any> = useRef(query);
  const dispatch = useAppDispatch();
  const canvasElement: RefObject<HTMLCanvasElement> = useRef(null);
  const yLabelRef: RefObject<HTMLDivElement> = useRef(null);
  const lineLabelRef: RefObject<HTMLDivElement> = useRef(null);
  const xLabelRef: RefObject<HTMLDivElement> = useRef(null);
  const { value, valueByHours, loading, initialFetchDone, hoursInitialFetchDone } = useAppSelector(dataSelector);
  const week = 'Week';
  const day = 'Day';
  const hour = 'Hour';
  const noOfDays = moment(endDate).diff(moment(startDate), 'days') + 1;
  const [selectedDate, setSelectedDate ] = useState(startDate);
  const scaleTogglers: string[] = useMemo(() => {
    const temp = [];
    if (noOfDays > maxBars && enableChartOption) { temp.push(week); }
    if (noOfDays > 1 || !enableChartOption) { temp.push(day); }
    if ((temp.length || noOfDays === 1) && enableChartOption) { temp.push(hour); }
    return temp;
  }, [week, hour, day, noOfDays, enableChartOption]); // needed query in dependency
  const [scaleToggler, setScaleToggler] = useState(scaleTogglers[0]);
  useEffect(() => setScaleToggler(scaleTogglers[0]), [scaleTogglers])
  const handleScaleToggle = (toggler: string) => {
    if (toggler === hour) {
      setSelectedDate(startDate);
      if (!hoursInitialFetchDone) {
        dispatch(getDataAction({ ...query, fetchBy: 'HOURS' }));
      }
    } else if (scaleToggler === hour && !initialFetchDone) {
      dispatch(getDataAction({ ...query }));
    }
    setScaleToggler(toggler);
  }
  const choosenVal = scaleToggler === hour ? valueByHours : value;

  // date range for day toggler
  const [dateRange, setDateRange] = useState([startDate, moment(endDate).diff(startDate, 'days') + 1 > maxBars ? moment(startDate).add(maxBars - 1, 'days').valueOf() : endDate])
  useEffect(() => {
    setDateRange([startDate, moment(endDate).diff(startDate, 'days') + 1 > maxBars ? moment(startDate).add(maxBars - 1, 'days').valueOf() : endDate])
  }, [startDate, endDate]);

  // const noOfdays = moment(endDate).diff(moment(startDate), 'days') + 1;
  const disableDateRange = (noOfDays <= 42 && scaleToggler === day) ||
    (noOfDays <= 7 * 42 && scaleToggler === week);
  const disableDatePicker = noOfDays === 1;

  const enableToggler = enableChartOption && Boolean(isArrayOfObj ? value?.length : ((value || {})[dateKey] || []).length) && scaleTogglers.length > 1;

  const dateScaleFormatterGetter = (dates: string[]) => {
    return (value: string = '', index: number) => {
      value = dates[index];
      const date = moment(value);
      if (!date.isValid()) {
        return value;
      }
      const prevTickvalue = moment(dates[index - 1]);
      let returnVal: number | [number, string];
      const prevDate = moment(prevTickvalue);
      if (index && prevTickvalue && prevDate.isValid() && prevDate.month() === date.month()) {
        returnVal = date.date();
      } else {
        returnVal = [date.date(), date.format('MMM')];
      }
      return returnVal;
    };
  }

  const weekScaleFormatter = (value: string = '', index: number) =>
    index === 0 ? [1, 'week'] : index + 1

  const hourScaleFormatterGetter = (hours: number[]) => (value: string, index: number) => Number(hours[index])

  const memoizedData = useMemo(() => {
    if (!loading && choosenVal) {
      let dates: string[] = []; 
      let values: number[] = [];
      let hours: string[] = [];
      let lineValues: number[] = [];
      if (isArrayOfObj) {
        dates = choosenVal.map((obj: object) => getNestedValue(obj, dateKey));
        if (hourKey) {
          hours = choosenVal.map((obj: object) => ((Number(getNestedValue(obj, hourKey)) || 0) + 1));
        }
        values = choosenVal.map((obj: object) => getNestedValue(obj, valKey));
        lineValues = lineKey ? choosenVal.map((obj: object) => getNestedValue(obj, lineKey)) : undefined;
      } else {
        dates = getNestedValue(choosenVal, dateKey);
        if (hourKey) {
          hours = (getNestedValue(choosenVal, hourKey) || []).map((hour: number) => ((Number(hour) || 0) + 1));
        }
        values = getNestedValue(choosenVal, valKey);
        lineValues = lineKey ? (getNestedValue(choosenVal, lineKey) || []) : undefined;
      }
      if (scaleToggler === hour) {
        const tempValues = values;
        values = [];
        const tempLineValues = lineValues;
        lineValues = lineValues && [];
        const tempHours = hours;
        hours = [];
        dates = dates.filter((date: string, i: number) => {
          if (moment(date).isSame(moment(selectedDate), 'day')) {
            values.push(tempValues[i]);
            hours.push(tempHours[i]);
            if (tempLineValues) {
              lineValues.push(tempLineValues[i]);
            }
            return true;
          }
          return false;
        });
      }
      if (scaleToggler === day && (moment(dateRange[0]).isAfter(moment(startDate)) || moment(dateRange[1]).isBefore(moment(endDate)))) {
        const newValues: number[] = []
        dates = dates.filter((date: string, i: number) => {
          const isIncluded = moment(date).isSameOrAfter(moment(dateRange[0])) && moment(date).isSameOrBefore(moment(dateRange[1]));
          if (isIncluded) {
            newValues.push(values[i]);
          }
          return isIncluded;
        });
        values = newValues;
      }
      const formatterMap = {
        [week]: weekScaleFormatter,
        [day]: dateScaleFormatterGetter(dates),
        [hour]: hourScaleFormatterGetter((hourKey ? hours : dates) as unknown as number[])
      };
      if (scaleToggler === week) {
        const weekVal = [values[0]];
        let prevDate = dates[0];
        for (let i = 1; i < dates.length; i++) {
          if (moment(dates[i]).isSame(prevDate, 'week')) {
            const lastEntry = weekVal[weekVal.length - 1];
            weekVal[weekVal.length - 1] = lastEntry + values[i];
          } else {
            weekVal.push(values[i]);
          }
          prevDate = dates[i];
        }
        values = weekVal;
      }
      return dates?.length && (values?.length && values.filter(value => value).length) ? {
        type: 'bar' as any,
        data: {
          labels: scaleToggler === day ? dates : (scaleToggler === hour ? hours : values as unknown as string[]),
          datasets: [
            {
              label: barLabel,
              data: values,
              backgroundColor: "#FC7D58",
              maxBarThickness: 22,
              order: 1
            },
            ...(lineValues ? [{
              label: lineLabel,
              data: lineValues,
              type: 'line' as any,
              order: 0,
              yAxisID: 'y1',
            }] : [])
          ]
        },
        plugins: [{ 
          id: 'yLabelPositioner',
          beforeBuildTicks: () => {
            if (yLabelRef.current) {
              yLabelRef.current.style.paddingLeft = 'unset';
            }
            if (lineLabelRef.current) {
              lineLabelRef.current.style.paddingRight = 'unset';
            }
            if (xLabelRef.current) {
              xLabelRef.current.style.paddingLeft = 'unset';
              xLabelRef.current.style.paddingRight = 'unset';
            }
          },
          afterBuildTicks: (chart: any) => {
            const height = chart?.scales?.x?.height;
            const yWidth = chart?.scales?.y?.width;
            const y1Width = chart?.scales?.y1?.width;
            if (yLabelRef.current && height) {
              yLabelRef.current.style.paddingLeft = `calc(${height}px + 1.5rem)`;
            }
            if (lineLabelRef.current && height) {
              lineLabelRef.current.style.paddingLeft = `calc(${height}px + 1.5rem)`;
            }
            if (xLabelRef.current && yWidth) {
              xLabelRef.current.style.paddingLeft = `calc(${yWidth}px + 1.5rem)`;
            }
            if (xLabelRef.current && y1Width) {
              xLabelRef.current.style.paddingRight = `calc(${y1Width}px + 1.5rem)`;
            }
          }
        }],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "x",
          elements: {
            bar: {
              borderRadius: 2
            },
            point: {
              borderWidth: 1,
              borderColor: '#fff',
              backgroundColor: '#151EF9',
              radius: 4
            },
            line: {
              borderColor: '#151EF9',
              borderWidth: 2
            }
          },
          scales: {
            y: {
              grid: {
                display: false,
                drawBorder: false
              },
              ticks: {
                maxTicksLimit: 5
              }
            },
            x: {
              grid: {
                display: false,
                borderDash: [5, 5],
                color: '#ECF0F4',
                drawBorder: false
              },
              ticks: {
                autoSkip: true,
                maxRotation: 0,
                callback: (formatterMap as any)[scaleToggler]
              }
            },
            ...(lineValues ? {
              y1: {
                grid: {
                  display: false,
                  drawBorder: false
                },
                position: "right",
                ticks: {
                  maxTicksLimit: 5
                }
              }
            } : {}),
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              // enabled: false
            }
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
            axis: 'x'
          }
        }
      } : undefined
    }
  }, [
    loading,
    barLabel,
    lineLabel,
    scaleToggler,
    dateKey,
    valKey,
    lineKey,
    choosenVal,
    dateRange,
    startDate,
    endDate,
    isArrayOfObj,
    selectedDate,
    hourKey
  ]);
  useEffect(() => {
    if (!(isInitialRenderDone.current || initialFetchDone)) {
      isInitialRenderDone.current = true;
      scaleToggler === hour && dispatch(getDataAction({ ...query, fetchBy: 'DATE' }));
      dispatch(getDataAction({ ...query, fetchBy: scaleToggler === hour ? 'HOURS' : 'DATE' }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isInitialRenderDone.current && query !== queryRef.current) {
      queryRef.current = query;
      scaleToggler === hour && dispatch(getDataAction({ ...query, fetchBy: 'DATE' }));
      dispatch(getDataAction({ ...query, fetchBy: scaleToggler === hour ? 'HOURS' : 'DATE' }));
    }
  });

  useEffect(() => {
    if (memoizedData) {
      const chart = new Chart(canvasElement.current as HTMLCanvasElement, memoizedData);
      return () => chart.destroy();
    }
  }, [memoizedData]);
  useEffect(() => {
    isInitialRenderDone.current = true;
  }, []);
  const noValues = !((isArrayOfObj ? (choosenVal || []).map((obj: object) => (obj as any || {})[valKey]) : (choosenVal || {})[valKey]) || [])
    .filter((val: number) => val).length;
  return (
    <div className="barTimeTrend position-relative w-100 h-100">
      { noValues && !memoizedData && !loading ? <NoDataTemplate icon={NoDataIcon} /> : null }
      { !noValues && enableToggler ?
        <div className="chartOptions d-flex justify-content-between">
          <div className="scaleToggler d-flex fs-dot75 font-weight-semibold lh-1">
            {scaleTogglers.map((key) => {
              const isActive = scaleToggler === key;
              return (
                <div
                  key={key}
                  className={`toggleBtn cursor-pointer d-flex align-items-center justify-content-center ${isActive ? 'active' : ''}`}
                  onClick={() => handleScaleToggle(key)}
                >{isActive ? key : key.charAt(0)}</div>
              )
            })}
          </div>
          {scaleToggler === hour ?
            (!disableDatePicker && <DatePicker
              onSelect={(date: number) => {
                setSelectedDate(date);
                if (!hoursInitialFetchDone) {
                  dispatch(getDataAction({ ...query, startDate: moment(date).startOf('days').valueOf(), endDate: moment(date).add(1, 'days').valueOf(), fetchBy: 'HOURS' }));
                }
              }}
              enableToggler={true}
              minDate={startDate}
              maxDate={endDate}
            />) :
            (!disableDateRange &&
              <DateRangePicker
                onSelect={setDateRange}
                className="fs-dot75 px-2 py-1"
                minDate={startDate}
                maxDate={endDate}
                maxSpan={maxBars}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                enableToggler={true}
              />)}
        </div> : null}
      <div
        className={`position-relative chartContainer w-100 ${
          enableToggler ? 'chartOptionEnabled' : ''} ${
            barLabel ? (assignLeftAxisForLine ? 'pr-4' : 'pl-4') : ''} ${
            xLabel ? 'pb-4' : ''} ${
            lineLabel ? (assignLeftAxisForLine ? 'pl-4' : 'pr-4') : ''
          }`
        }
      >
        { value && !memoizedData && !loading ? <NoDataTemplate icon={NoDataIcon} /> : null }
        {loading ?
          <div className="w-100 h-100 bg-white position-absolute z-index-2 top-0 left-0 d-flex align-items-center justify-content-center">
            <Spinner />
          </div> : null}
        <canvas ref={canvasElement} />
        { memoizedData && barLabel ?
          <div
            ref={yLabelRef}
            className={`${assignLeftAxisForLine ? 'customY1Label' : 'customYLabel'} d-flex align-items-center font-weight-bold text-center position-absolute`}
          >
            {lineLabel ? <div className="colorIndicator" /> : null}
            {barLabel}
          </div> : null }
        { memoizedData && lineLabel ?
          <div
            ref={lineLabelRef}
            className={`${assignLeftAxisForLine ? 'customYLabel' : 'customY1Label'} d-flex align-items-center font-weight-bold text-center position-absolute`}
          >
            <img className="lineIndicator" src={LineChartIcon} alt="" />
            {lineLabel}
          </div> : null }
        { memoizedData && xLabel ?
          <div
            ref={xLabelRef}
            className="customXLabel d-flex align-items-center font-weight-bold text-center position-absolute"
          >
            {xLabel}
          </div> : null }
      </div>
    </div>
  );
}

export default BarTimeTrend;
