import React, { useState } from 'react';

import './Input.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label: string;
}

const Input = ({ error, type, label, ...inputAttr }: InputProps) => {
  const [ showPassword, setShowPassword ] = useState(false);
  return (
    <div className="inputContainer">
      <div className="inputLabel text-left fs-dot875 mb-1 text-white lh-1dot125">{label}</div>
      <input
        { ...inputAttr }
        type={type === "password" && showPassword ? "text" : type}
        className={`inputField py-2 px-dot75 fs-dot875 lh-1dot125 ${type === "password" ? "password" : ''}`}
      />
      { error && <small className="input-error">{error}</small> }
      { type === "password" ?
        <i
          className={`passwordIcon fs-dot875 fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
          onClick={() => setShowPassword(!showPassword)}
        /> : null
      }
    </div>
  );
}

export default Input;