import axios from "axios";

export const fetchDashboardMeta = () => axios({
  method: 'get',
  url: '/home/initialAppData'
});

export const fetchTimezone = () => axios({
  method: 'get',
  url: '/home/timeZone'
});
