import { Chart, registerables } from 'chart.js';
import { compactInteger } from 'humanize-plus';
import React, { MutableRefObject, RefObject, useEffect, useMemo, useRef } from 'react';
import { ThunkAction } from 'redux-thunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { formatCost, getNestedValue } from '../../utils/appUtils';
import NoDataTemplate from '../NoDataTemplate';
import NoDataIcon from '../../assets/image/no-data-pie.svg';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner';

Chart.register(...registerables);
Chart.defaults.font.family = 'Mulish';
Chart.defaults.font.size = 12;

export interface ILegend {
  key: string; 
  label: string;
  order?: number;
  className?: string;
  isColored?: boolean
  formatter?: (value: string | number) => string | number; 
}

interface IDonutChartProps {
  query: any;
  getDataAction: (query: any) => ThunkAction<any, any, any, any>;
  dataSelector: (state: RootState) => any;
  labelKey: string;
  valueKey: string;
  totalKey?: string;
  totalLabel?: string;
  legendMeta: ILegend[];
  isArrayOfObjects?: boolean;
  linkFormatter?: (label: string) => any;
  dataType?: 'dollar' | 'number';
}

const DonutChart = ({
  query,
  getDataAction,
  dataSelector,
  isArrayOfObjects,
  labelKey,
  valueKey,
  totalKey,
  totalLabel,
  legendMeta,
  linkFormatter,
  dataType
}: IDonutChartProps) => {
  const colors = useMemo(() => [
    '#FC7D58',
    '#52C9DA',
    '#5CBC71',
    '#58ADFC',
    '#5C6CBC',
    '#DABC52'
  ], []);
  const isInitialRenderDone: MutableRefObject<boolean> = useRef(false);
  const dispatch = useAppDispatch();
  const queryRef: MutableRefObject<any> = useRef(query);
  const canvasElement: RefObject<HTMLCanvasElement> = useRef(null);
  const { value, loading, initialFetchDone } = useAppSelector(dataSelector);
  const memoziedValues = useMemo(() => {
    let values: number[];
    if (isArrayOfObjects) {
      values = value.map((obj: object) => getNestedValue(obj, valueKey));
    } else {
      values = getNestedValue(value, valueKey);
    }
    return values;
  }, [isArrayOfObjects, value, valueKey]);

  const memoziedLabels = useMemo(() => {
    let values: number[];
    if (isArrayOfObjects) {
      values = value.map((obj: object) => getNestedValue(obj, labelKey));
    } else {
      values = getNestedValue(value, labelKey);
    }
    return values;
  }, [isArrayOfObjects, value, labelKey]);

  const memoizedLegends = useMemo(() => {
    const legends: { [key: string]: Array<string | number> } = {};
    legendMeta.sort(({ order: orderA }: ILegend, { order: orderB }: ILegend) => (orderA || 0) - (orderB || 0))
      .forEach(({ key: legendKey }: ILegend) => {
        const legendValue = getNestedValue(value, legendKey);
        if (legendValue) {
          legends[legendKey] = legendValue;
        }
      });
    return legends;
  }, [value, legendMeta]);
  const memoizedData = useMemo(() => (
    !loading && value && memoziedLabels?.length && memoziedValues?.length ? {
      type: 'doughnut' as any,
      data: {
        labels: memoziedLabels,
        datasets: [
          {
            label: 'Users',
            data: memoziedValues.map((val: number = 0) => Number(val.toFixed(2))),
            backgroundColor: colors,
          }
        ]
      },
      options: {
        cutout: '65%',
        responsive: true,
        preserveAspectRatio: false,
        elements: {
          arc: {
            angle: 120,
            borderWidth: 2
          }
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false
          },
          tooltip: {
            callbacks: {
              ...(dataType === 'dollar' ? 
                {
                  label: (data: any) => `${data?.label ? `${data.label}: ` : ''}${formatCost(data?.raw)}`
                }
                : {}
              )
            }
          }
        }
      },
    } : undefined
  ), [loading, value, memoziedLabels, memoziedValues, colors, dataType]);
  useEffect(() => {
    if (!(isInitialRenderDone.current || initialFetchDone)) {
      isInitialRenderDone.current = true;
      dispatch(getDataAction(query));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (query !== queryRef.current) {
      queryRef.current = query;
      dispatch(getDataAction(query));
    }
  });
  useEffect(() => {
    if (memoizedData) {
      const chart = new Chart(canvasElement.current as HTMLCanvasElement, memoizedData);
      return () => chart.destroy();
    }
  }, [memoizedData]);

  useEffect(() => {
    isInitialRenderDone.current = true;
  }, []);
  return (
    <>
      { loading ?
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <Spinner />
        </div> : null}
      { !memoizedData && !loading ? <NoDataTemplate icon={NoDataIcon} /> : null}
      { memoziedValues ?
        <>
          <div className="donutOuterContainer d-flex flex-column flex-md-row justify-content-md-around">
            <div className="donutChartWrapper d-flex align-items-center">
              <div className="canvasWrapper w-100 h-100 align-items-center position-relative">
                <canvas id="topUsageCostUsersReport" ref={canvasElement} />
                {totalKey && !loading && value ?
                  <div className="donutInfo text-center position-absolute d-inline-flex flex-column align-items-center justify-content-center">
                    <div className="text lh-1dot125 font-weight-semibold">{totalLabel || 'Total queries'}</div>
                    <div className="value lh-1dot875">{compactInteger(value[totalKey] || 0, 2)}</div>
                  </div> : null
                }
              </div>
            </div>
            {value && !loading && Object.keys(memoizedLegends).length ?
              <div className="donutChartLegends h-100 d-flex align-items-center pt-md-0 pt-3">
                {legendMeta.map(({ key, label, className = '', isColored, formatter }, colIndex) => {
                  if (memoizedLegends[key]) {
                    return (
                      <div key={`${key}_${colIndex}`} className={`tColumn userName text-left ${className}`}>
                        <div className="tHead">{label}</div>
                        {memoizedLegends[key].map((text: string | number, i: number) =>
                          <div
                            key={`col_${colIndex}_${i}`}
                            className="tCell userNameCell"
                            title={String(text)}
                            style={{ color: isColored ? colors[i] : 'initial' }}
                          >
                            {key === labelKey && linkFormatter ?
                              <Link to={linkFormatter(text as string)}>{formatter ? formatter(text) : text}</Link>
                              : formatter ? formatter(text) : text}
                          </div>
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
              </div> : null}
          </div>
        </> : null}
    </>
  );
}

export default DonutChart;
