import React from 'react';

import LoaderIcon from '../../assets/image/loader.svg';

interface ISpinnerProps {
  width?: number;
  height?: number;
}

const Spinner = ({ width = 35, height = 35 }: ISpinnerProps) => {
  return (
    <img src={LoaderIcon} alt="Loading..." width={width} height={height} />
  );
}

export default Spinner;
