export const infoType = {
  number: 'number',
  dollar: 'dollar',
  text: 'text',
  time: 'time',
  date: 'date',
  datetime: 'datetime',
  dataSize: 'dataSize'
};

export const loginAPIPath = '/login';

export const freeSessionAPI = '/login/add-free-session';
export const saveAnonymousUserMailAPI = '/users/save-anonymous-user';
export const extendSessionAPI = 'users/extend-session';
export const createDemoUserAPI ='/users/addDemouser';

export const demoParam = 'demo';

export const demoParamValue = 'true';

export const demoTokenName = 'demo_token';
