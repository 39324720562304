import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import React, { MutableRefObject, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { ThunkAction } from 'redux-thunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { formatCost, getNestedValue } from '../../utils/appUtils';
import DatePicker from '../DatePicker';
import DateRangePicker from '../DateRangePicker';
import NoDataTemplate from '../NoDataTemplate';
import Spinner from '../Spinner';
import NoDataIcon from '../../assets/image/no-data-line.svg';

import './TimeTrend.scss';

Chart.register(...registerables);
Chart.defaults.font.family = 'Mulish';
Chart.defaults.font.size = 12;

interface IBarTimeTrendProps {
  xLabel?: string;
  yLabel?: string;
  query: any;
  skipInitialRender?: boolean;
  getDataAction: (query: any) => ThunkAction<any, any, any, any>;
  dataSelector: (state: RootState) => any;
  dateKey: string;
  hourKey?: string;
  valKeys: string[];
  isArrayOfObj?: boolean;
  enableChartOption?: boolean;
  startDate: number;
  endDate: number;
  colors?: string[];
  datasetLabels: string[];
  dataType?: 'dollar' | 'number';
}

const BarTimeTrend = ({
  dateKey,
  hourKey,
  valKeys,
  xLabel = '',
  yLabel = '',
  query,
  enableChartOption,
  isArrayOfObj,
  getDataAction,
  dataSelector,
  startDate,
  endDate,
  colors,
  datasetLabels,
  dataType
}: IBarTimeTrendProps) => {
  const colorList = useMemo(() => (
    colors || ['#151EF9', '#DE81FF', '#5C6CBC', '#DABC52', '#52C9DA', '#5CBC71', '#FC7D58']
  ), [colors]);
  const maxBars = 42;
  const isInitialRenderDone: MutableRefObject<boolean> = useRef(false);
  const queryRef: MutableRefObject<any> = useRef(query);
  const dispatch = useAppDispatch();
  const canvasElement: RefObject<HTMLCanvasElement> = useRef(null);
  const { value, valueByHours, loading, initialFetchDone, hoursInitialFetchDone } = useAppSelector(dataSelector);
  const week = 'Week';
  const day = 'Day';
  const hour = 'Hour';
  const noOfDays = moment(endDate).diff(moment(startDate), 'days') + 1;
  const [selectedDate, setSelectedDate ] = useState(startDate);
  const scaleTogglers: string[] = useMemo(() => {
    const temp = [];
    if (noOfDays > maxBars && enableChartOption) { temp.push(week); }
    if (noOfDays > 1 || !enableChartOption) { temp.push(day); }
    if ((temp.length || noOfDays === 1) && enableChartOption) { temp.push(hour); }
    return temp;
  }, [week, hour, day, noOfDays, enableChartOption]); // needed query in dependency
  const [scaleToggler, setScaleToggler] = useState(scaleTogglers[0]);
  useEffect(() => setScaleToggler(scaleTogglers[0]), [scaleTogglers])
  const handleScaleToggle = (toggler: string) => {
    if (toggler === hour) {
      setSelectedDate(startDate);
      if (!hoursInitialFetchDone) {
        dispatch(getDataAction({ ...query, fetchBy: 'HOURS' }));
      }
    } else if (scaleToggler === hour && !initialFetchDone) {
      dispatch(getDataAction({ ...query }));
    }
    setScaleToggler(toggler);
  }
  const choosenVal = scaleToggler === hour && enableChartOption ? valueByHours : value;

  // date range for day toggler
  const [dateRange, setDateRange] = useState([startDate, moment(endDate).diff(startDate, 'days') + 1 > maxBars ? moment(startDate).add(maxBars - 1, 'days').valueOf() : endDate])
  useEffect(() => {
    setDateRange([startDate, moment(endDate).diff(startDate, 'days') + 1 > maxBars ? moment(startDate).add(maxBars - 1, 'days').valueOf() : endDate])
  }, [startDate, endDate]);

  // const noOfdays = moment(endDate).diff(moment(startDate), 'days') + 1;
  const disableDateRange = (noOfDays <= 42 && scaleToggler === day) ||
    (noOfDays <= 7 * 42 && scaleToggler === week);
  const disableDatePicker = noOfDays === 1;

  const enableToggler = enableChartOption && Boolean(isArrayOfObj ? value?.length : ((value || {})[dateKey] || []).length) && scaleTogglers.length > 1;
  const dateScaleFormatterGetter = (dates: string[]) => {
    return (value: string = '', index: number) => {
      value = dates[index];
      const date = moment(value);
      if (!date.isValid()) {
        return value;
      }
      const prevTickvalue = moment(dates[index - 1]);
      let returnVal: number | [number, string];
      const prevDate = moment(prevTickvalue);
      if (index && prevTickvalue && prevDate.isValid() && prevDate.month() === date.month()) {
        returnVal = date.date();
      } else {
        returnVal = [date.date(), date.format('MMM')];
      }
      return returnVal;
    };
  }

  const weekScaleFormatter = (value: string = '', index: number) =>
    index === 0 ? [1, 'week'] : index + 1

  const hourScaleFormatterGetter = (hours: number[]) => (value: string, index: number) => (Number(hours[index]))

  const memoizedData = useMemo(() => {
    if (!loading && choosenVal) {
      let dates: string[] = []; 
      let hours: string[] = [];
      let values: { [key: string]: number[]; } =  {};
      if (isArrayOfObj) {
        dates = choosenVal.map((obj: object) => getNestedValue(obj, dateKey));
        if (hourKey) {
          hours = choosenVal.map((obj: object) => (Number(getNestedValue(obj, hourKey) || 0) + 1));
        }
        valKeys.forEach((key: string) => {
          values[key] = choosenVal.map((obj: object) => getNestedValue(obj, key));
        });
      } else {
        dates = getNestedValue(choosenVal, dateKey);
        // values = choosenVal[valKey];
        if (hourKey) {
          hours = (getNestedValue(choosenVal, hourKey) || []).map((hour: number) => (Number(hour) || 0) + 1);
        }
        valKeys.forEach((key: string) => {
          values[key] = getNestedValue(choosenVal, key);
        });
      }
      if (enableChartOption && scaleToggler === hour) {
        const tempValues = values;
        const tempHours = hours;
        hours = [];
        values = {};
        dates = dates.filter((date: string, i: number) => {
          if (moment(date).isSame(moment(selectedDate), 'day')) {
            hours.push(tempHours[i]);
            Object.keys(tempValues).forEach((key: string) => {
              if (!values[key]) { values[key] = []; }
              values[key].push(tempValues[key][i])
            });
            return true;
          }
          return false;
        });
      }
      if (scaleToggler === day && (moment(dateRange[0]).isAfter(moment(startDate)) || moment(dateRange[1]).isBefore(moment(endDate)))) {
        const newValues: { [key: string]: number[]; } = {};
        dates = dates.filter((date: string, i: number) => {
          const isIncluded = moment(date).isSameOrAfter(moment(dateRange[0])) && moment(date).isSameOrBefore(moment(dateRange[1]));
          if (isIncluded) {
            Object.keys(values).forEach((key) => {
              if (!newValues[key]) {
                newValues[key] = [];
              }
              newValues[key].push(values[key][i]);
            });
          }
          return isIncluded;
        });
        values = newValues;
      }
      const formatterMap = {
        [week]: weekScaleFormatter,
        [day]: dateScaleFormatterGetter(dates),
        [hour]: hourScaleFormatterGetter( (hourKey ? hours : dates) as unknown as number[])
      };
      if (scaleToggler === week) {
        const weekVal: { [key: string]: number[]; } = {};
        let lastEntry: { [key: string]: number; } = {};
        Object.keys(values).forEach((key: string) => {
          weekVal[key] = [values[key][0]]; 
          lastEntry[key] = values[key][0];
        });
        let prevDate = dates[0];
        for (let i = 1; i < dates.length; i++) {
          if (moment(dates[i]).isSame(prevDate, 'week')) {
            // const lastEntry = weekVal[weekVal.length - 1];
            // eslint-disable-next-line no-loop-func
            Object.keys(weekVal).forEach((key: string) => {
              weekVal[key][weekVal[key].length - 1] = lastEntry[key] + values[key][i];
              lastEntry[key] = weekVal[key][weekVal[key].length - 1];
            });
          } else {
            // eslint-disable-next-line no-loop-func
            Object.keys(weekVal).forEach((key: string) => {
              weekVal[key].push(values[key][i]);
              lastEntry[key] = weekVal[key][weekVal[key].length - 1];
            });
          }
          prevDate = dates[i];
        }
        values = weekVal;
      }
      return dates?.length && Object.keys(values).length ? {
        type: 'line' as any,
        data: {
          labels: scaleToggler === day || !enableChartOption ? dates : (scaleToggler === hour && hours.length ? hours : values[valKeys[0]]) as unknown as string[],
          datasets: Object.keys(values).map((key: string, i: number) => ({
            label: datasetLabels[i],
            data: values[key],
            order: i,
            borderColor: colorList[i],
            backgroundColor: colorList[i],
            pointBorderColor: '#ffffff',
            pointBorderWidth: 1
          }))
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "x",
          elements: {
            bar: {
              borderRadius: 2
            },
            point: {
              borderWidth: 1,
              borderColor: '#fff',
              backgroundColor: '#151EF9',
              radius: 4
            },
            line: {
              borderColor: '#151EF9',
              borderWidth: 2
            }
          },
          scales: {
            y: {
              grid: {
                display: false,
                drawBorder: false
              },
              title: {
                display: !!yLabel,
                text: yLabel,
                color: '#A8A9AD'
              },
              ticks: {
                maxTicksLimit: 5,
                ...(dataType === 'dollar' ?
                  { callback: ((value: number) => `$${value || 0}`) }
                  : {}
                )
              }
            },
            x: {
              grid: {
                display: false,
                borderDash: [5, 5],
                color: '#ECF0F4',
                drawBorder: false
              },
              ticks: {
                autoSkip: true,
                maxRotation: 0,
                callback: (formatterMap as any)[scaleToggler]
              },
              title: {
                display: !!xLabel,
                text: xLabel,
                color: '#A8A9AD'
              }
            }
          },
          plugins: {
            legend: {
              display: datasetLabels?.length > 1,
              align: 'end',
              position: 'top',
              labels: {
                boxWidth: 12,
                boxHeight: 12
              }
            },
            tooltip: {
              callbacks: {
                ...(dataType === 'dollar' ? 
                  {label: (data: any) => `${yLabel}: ${formatCost(data?.raw)}`}
                  : {}
                )
              }
            }
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
            axis: 'x'
          }
        }
      } : undefined
    }
  }, [
    loading,
    xLabel,
    yLabel,
    scaleToggler,
    dateKey,
    valKeys,
    choosenVal,
    dateRange,
    startDate,
    endDate,
    colorList,
    datasetLabels,
    isArrayOfObj,
    selectedDate,
    hourKey,
    enableChartOption,
    dataType
  ]);
  useEffect(() => {
    if (!(isInitialRenderDone.current || initialFetchDone)) {
      isInitialRenderDone.current = true;
      scaleToggler === hour && dispatch(getDataAction({ ...query, fetchBy: 'DATE' }));
      dispatch(getDataAction({ ...query, fetchBy: scaleToggler === hour ? 'HOURS' : 'DATE' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isInitialRenderDone.current && query !== queryRef.current) {
      queryRef.current = query;
      scaleToggler === hour && dispatch(getDataAction({ ...query, fetchBy: 'DATE' }));
      dispatch(getDataAction({ ...query, fetchBy: scaleToggler === hour ? 'HOURS' : 'DATE' }));
    }
  });
  useEffect(() => {
    if (memoizedData) {
      const chart = new Chart(canvasElement.current as HTMLCanvasElement, memoizedData);
      return () => chart.destroy();
    }
  }, [memoizedData]);
  useEffect(() => {
    isInitialRenderDone.current = true;
  }, []);
  return (
    <div className="barTimeTrend position-relative w-100 h-100">
      { !value && !memoizedData && !loading  ? <NoDataTemplate icon={NoDataIcon} /> : null }
      { enableToggler ?
        <div className="chartOptions d-flex justify-content-between">
          <div className="scaleToggler d-flex fs-dot75 lh-1">
            {scaleTogglers.map((key) => {
              const isActive = scaleToggler === key;
              return (
                <div
                  key={key}
                  className={`toggleBtn cursor-pointer d-flex align-items-center justify-content-center ${isActive ? 'active font-weight-semibold' : ''}`}
                  onClick={() => handleScaleToggle(key)}
                >{isActive ? key : key.charAt(0)}</div>
              )
            })}
          </div>
          {scaleToggler === hour ?
            (!disableDatePicker && <DatePicker
              onSelect={(date: number) => {
                setSelectedDate(date);
                if (!hoursInitialFetchDone) {
                  dispatch(getDataAction({ ...query, startDate: moment(date).startOf('days').valueOf(), endDate: moment(date).add(1, 'days').valueOf(), fetchBy: 'HOURS' }))
                }
              }}
              enableToggler={true}
              minDate={startDate}
              maxDate={endDate}
            />) :
            (!disableDateRange &&
              <DateRangePicker
                onSelect={setDateRange}
                className="fs-dot75 px-2 py-1"
                minDate={startDate}
                maxDate={endDate}
                maxSpan={maxBars}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
              />)}
        </div> : null}
      <div className={`position-relative chartContainer w-100 ${enableToggler ? 'chartOptionEnabled' : ''}`}>
        { value && !memoizedData && !loading  ? <NoDataTemplate icon={NoDataIcon} /> : null }
        {loading ?
          <div className="w-100 h-100 bg-white position-absolute z-index-2 top-0 left-0 d-flex align-items-center justify-content-center">
            <Spinner />
          </div> : null}
        <canvas ref={canvasElement} />
      </div>
    </div>
  );
}

export default BarTimeTrend;
